import Breadcrumbs from "components/Common/Breadcrumb";
import React, { useRef } from "react";
import { Button, Card, CardBody, Col, Container, Form, Row, ButtonToggle, Modal } from "reactstrap";
import lock from "../../../assets/images/NewImage/verrouiller-alt.png";
import edit from "../../../assets/images/NewImage/editer.png";
import { useState } from "react";
import userEditProfile from "../../../assets/images/NewImage/user-avatar.png";
import { generateotpForTheMerchantSecreatPin, ResetMerchantPin, updateAndChangeThePassword, updateTheUserProfile } from "Context/ContextApi";
import { useEffect } from "react";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import 'react-toggle/style.css';
import ReactToPrint from "react-to-print";
import wheelLogo from "../../../assets/images/NewImage/Vault_Wheel.png"
import { ComponentToPrint } from "../PrintCode";
import cancelImg from "../../../assets/images/NewImage/x-button.png"
import { DeliveryComponentToPrint } from "../deliveryqrprint";

const API_BASE_URL = process.env.REACT_APP_API_URL;
function UserprofileHeader({ mData , fetchData}) {
    const [userData, setUserData] = useState({});
    const [isPassword, setIsPassword] = useState(false);
    const [personalSelfieDoc, setPersonalSelfieDoc] = useState([])
    const [isChangePin, setIsChangePin] = useState(false)
    const [isButtonVisible, setButtonVisibility] = useState(true)
    const [secondsRemaining, setSecondsRemaining] = useState(60);
    const [actionData, setActionData] = useState({})
    const fileInputRef = useRef(null);
    const [changePasswordForm, setChangePasswordForm] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        applicationPin: "",
    })
    const [changeResetPinForm, setChangeResetPinForm] = useState({
        newPin: "",
        confirmPin: "",
        changePinOTP: ""
    })

    const [formErrors, setFormErrors] = useState({});
    const [formErrorsPin, setFormErrorsPin] = useState({})

    const handleImageClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };
    const handleFileChange = (e) => {
        const selectedFile = e.target.files[0];
        setPersonalSelfieDoc(selectedFile)
        if (selectedFile) {
            handleChange(selectedFile);
        }
    };

    useEffect(() => {
        setUserData(mData?.result);
        let jsonparse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
        setActionData(jsonparse)
    }, [mData?.subResult?.Permissions])


    // update the user profile
    const handleChange = async (item) => {
        try {
            const formData = new FormData()
            formData.append("ProfileImage", item)
            const response = await updateTheUserProfile(formData)
            toastr.success(response.message, "Success", {
                timeOut: 7000,
                progressBar: true,
                closeDuration: 700,
                positionClass: "toast-top-right"
            });
            fetchData();
        } catch (error) {
            console.log(error);
        }
    }
    const componentRef = React.useRef();
    const componentRef2 = React.useRef();


    // validation for change password model 
    const handleChangeForPassword = e => {
        const { name, value, } = e.target;
        setChangePasswordForm({ ...changePasswordForm, [name]: value });
    }
    const validateForChangePassword = () => {
        let errors = {};
        if (!changePasswordForm.oldPassword) {
            errors.oldPassword = "Please enter old password";
        }
        if (!changePasswordForm.newPassword) {
            errors.newPassword = "Please enter a password";
        } else if (changePasswordForm.newPassword.length < 8) {
            errors.newPassword = "Password must be at least 8 characters long";
        } else if (
            !/^(?=.*[a-zA-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(
                changePasswordForm.newPassword
            )
        ) {
            errors.newPassword =
                "Password should be a combination of alphabets, numbers, special characters, minimum 8 characters";
        }
        if (!changePasswordForm.confirmPassword) {
            errors.confirmPassword = "Please confirm your password";
        } else if (changePasswordForm.newPassword !== changePasswordForm.confirmPassword) {
            errors.confirmPassword = "Passwords do not match";
        }
        if (!changePasswordForm.applicationPin) {
            errors.applicationPin = "Enter application pin"
        }
        setFormErrors(errors);

        return Object.keys(errors).length === 0;
    };
    const handleChangePassword = async () => {
        try {
            if (!validateForChangePassword()) {
                return;
            }
            const formData = {
                oldPassword: changePasswordForm.oldPassword,
                newPassword: changePasswordForm.newPassword,
                applicationPin: changePasswordForm.applicationPin
            }
            const response = await updateAndChangeThePassword(formData)
            toastr.success(response.message, "Success", {
                timeOut: 7000,
                progressBar: true,
                closeDuration: 700,
                positionClass: "toast-top-right"
            });
            setIsPassword(false)
            setChangePasswordForm({
                oldPassword: "",
                newPassword: "",
                confirmPassword: "",
                applicationPin: ""
            })
        } catch (error) {
            console.log(error);
            toastr.warning(error.message)
        }
    }

    // generate otp
    const handleGenerateOTP = async () => {
        try {
            const response = await generateotpForTheMerchantSecreatPin()
            toastr.success(response.message, "Success", {
                timeOut: 7000,
                progressBar: true,
                closeDuration: 700,
                positionClass: "toast-top-right"
            });
            setButtonVisibility(false);
            setSecondsRemaining(60);
            startCountdown();
        } catch (error) {
            console.log(error);
        }
    };

    const startCountdown = () => {
        const countdownInterval = setInterval(() => {
            setSecondsRemaining((prevSeconds) => prevSeconds - 1);
        }, 1000);

        setTimeout(() => {
            setButtonVisibility(true);
            clearInterval(countdownInterval);
        }, 60000);
    };

    useEffect(() => {
        return () => {
            clearInterval(startCountdown);
        };
    }, []);


    const handleChangeForResetPin = e => {
        const { name, value, } = e.target;
        setChangeResetPinForm({ ...changeResetPinForm, [name]: value });
    }
    const validateForResetPin = () => {
        let errors = {};
        if (!changeResetPinForm.newPin) {
            errors.newPin = "Please enter a pin";
        } else if (changeResetPinForm.newPin.length < 4) {
            errors.newPin = "Pin must be at least 4 characters long";
        } else if (!/^\d{4}$/.test(changeResetPinForm.newPin)) {
            errors.newPin = "Pin should be numeric and exactly 4 characters long";
        }

        if (!changeResetPinForm.confirmPin) {
            errors.confirmPin = "Please confirm your pin";
        } else if (changeResetPinForm.newPin !== changeResetPinForm.confirmPin) {
            errors.confirmPin = "Pins do not match";
        }
        if (!changeResetPinForm.changePinOTP) {
            errors.changePinOTP = "Enter application otp"
        }
        setFormErrorsPin(errors);

        return Object.keys(errors).length === 0;
    };

    const handleChangeResetPin = async () => {
        try {
            if (!validateForResetPin()) {
                return;
            }
            const formData = {
                pin: changeResetPinForm.newPin,
                otp: changeResetPinForm.changePinOTP
            }
            const response = await ResetMerchantPin(formData)
            toastr.success(response.message, "Success", {
                timeOut: 7000,
                progressBar: true,
                closeDuration: 700,
                positionClass: "toast-top-right"
            });
            setIsChangePin(false)
            setChangeResetPinForm({
                newPin: "",
                confirmPin: "",
                changePinOTP: ""
            })
        } catch (error) {
            console.log(error);
            toastr.warning(error.message)
        }
    }

    return (
        <>

            <Modal
                isOpen={isPassword}
                role="dialog"
                autoFocus={true}
                centered
            >
                <div className="modal-content">
                    <div className="isDEfault_popup_content ">
                        <div className="is_default_heading">
                            <h3 className="title-color-common">Change passsword</h3>
                            <img
                                src={cancelImg}
                                alt="cancel"
                                onClick={() => setIsPassword(false)}
                            />
                        </div>
                        <div className="idefault_popup_form_group mt-3">
                            <label>Old password</label>
                            <input type="password" placeholder="Old password"
                                name="oldPassword"
                                value={changePasswordForm.oldPassword}
                                onChange={e => {
                                    handleChangeForPassword(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, oldPassword: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrors.oldPassword}
                            </span>
                        </div>
                        <div className="idefault_popup_form_group mt-2">
                            <label>New passsword</label>
                            <input type="password" placeholder="New password"
                                name="newPassword"
                                value={changePasswordForm.newPassword}
                                onChange={e => {
                                    handleChangeForPassword(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, newPassword: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrors.newPassword}
                            </span>
                        </div>
                        <div className="idefault_popup_form_group mt-2">
                            <label>Confirm passsword</label>
                            <input type="password" placeholder="Confirm password"
                                name="confirmPassword"
                                value={changePasswordForm.confirmPassword}
                                onChange={e => {
                                    handleChangeForPassword(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, confirmPassword: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrors.confirmPassword}
                            </span>
                        </div>
                        <div className="idefault_popup_form_group mt-2">
                            <label>Application Pin</label>
                            <input type="password" placeholder="Enter application pin"
                                name="applicationPin"
                                value={changePasswordForm.applicationPin}
                                onChange={e => {
                                    handleChangeForPassword(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, applicationPin: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrors.applicationPin}
                            </span>
                            <Button className="procces_Button mt-3" onClick={handleChangePassword}>Save change</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            {/* change pin model */}
            <Modal
                isOpen={isChangePin}
                role="dialog"
                autoFocus={true}
                centered
            >
                <div className="modal-content">
                    <div className="isDEfault_popup_content ">
                        <div className="is_default_heading">
                            <h3 className="title-color-common">Reset Pin</h3>

                            <img
                                src={cancelImg}
                                alt="cancel"
                                onClick={() => setIsChangePin(false)}
                            />
                        </div>

                        <div className="idefault_popup_form_group mt-2">
                            <label>New Pin</label>
                            <input type="number" placeholder="New Pin"
                                name="newPin"
                                value={changeResetPinForm.newPin}
                                maxLength={4}
                                onChange={e => {
                                    handleChangeForResetPin(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, newPin: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrorsPin.newPin}
                            </span>
                        </div>
                        <div className="idefault_popup_form_group mt-2">
                            <label>Confirm pin</label>
                            <input type="number" placeholder="Confirm Pin"
                                name="confirmPin"
                                maxLength={4}
                                value={changeResetPinForm.confirmPin}
                                onChange={e => {
                                    handleChangeForResetPin(e);
                                    if (e.target.value.trim() !== "") {
                                        setFormErrors({ ...formErrors, confirmPin: "" });
                                    }
                                }} />
                            <span className="warning_message">
                                {formErrorsPin.confirmPin}
                            </span>

                        </div>
                        <div className="idefault_popup_form_group mt-3">
                            <label>Request OTP</label>
                            <div className="request_OTP_input">
                                <input type="number" placeholder="Enter OTP"
                                    name="changePinOTP"
                                    maxLength={6}
                                    value={changeResetPinForm.changePinOTP}
                                    onChange={e => {
                                        handleChangeForResetPin(e);
                                        if (e.target.value.trim() !== "") {
                                            setFormErrors({ ...formErrors, changePinOTP: "" });
                                        }
                                    }} />

                                <Button className={`procces_Button ${isButtonVisible ? "disable_button" : ""}`} onClick={handleGenerateOTP}>Request OTP</Button>
                            </div>
                            <span className="warning_message">
                                {formErrorsPin.changePinOTP}
                            </span>
                            {!isButtonVisible && (
                                <small className="generate_pin_timer">{secondsRemaining > 0 ? `${secondsRemaining} sec` : ''}</small>
                            )}
                        </div>
                        <div className="idefault_popup_form_group">
                            <Button className="procces_Button mt-3" onClick={handleChangeResetPin}>Save change</Button>

                        </div>
                    </div>
                </div>
            </Modal>
            <React.Fragment>

                <Row>
                    <Col lg="6" md="6" sm="12" xs="12">
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center flex-1">
                                        <div
                                            className="text-muted "
                                            style={{
                                                display: "flex",
                                                flexFlow: "column",
                                                gap: "10px",
                                            }}
                                        >
                                            <h3 className="userprofile_left_card_heading">
                                                {userData && userData.FullName}
                                            </h3>
                                            <h6 className="mb-1 userProfile_left_after_heading_text">
                                                Please set your profile image,
                                            </h6>

                                            <p className="mb-0 userProfile_left_after_heading_text">
                                                supported formate jepg, png, bmp, gif, or svg
                                            </p>
                                        </div>
                                    </div>
                                    <div
                                        className=""
                                        style={{
                                            width: "200px",
                                            height: "200px",
                                            position: "relative",
                                        }}
                                    >
                                        <img
                                            src={userData?.ProfileImage ? `${API_BASE_URL}/${userData?.id}/profile/${userData?.ProfileImage}` : wheelLogo}
                                            alt="profile"
                                            className="avatar-md rounded-circle img-thumbnail fullwidth"
                                            style={{
                                                width: "100%",
                                                height: "100%",
                                                objectFit: "fit",
                                            }}
                                        />
                                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                                            <div>
                                                <img
                                                    src={userEditProfile}
                                                    alt="edit"
                                                    className="Editproflile_user"
                                                    onClick={handleImageClick}
                                                />
                                                <input
                                                    type="file"
                                                    accept="image/*"
                                                    style={{ display: 'none' }}
                                                    ref={fileInputRef}
                                                    onChange={handleFileChange}
                                                />
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                        <Card>
                            <CardBody>
                                <div
                                    className="d-flex justify-content-between mt-3 "
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <span className="pt-0" style={{ fontSize: "18px" }}>
                                            Wallet balance
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            gap: "10px",
                                            margina: "auto"
                                        }}
                                    >
                                        <span style={{
                                            color: "purple",
                                            fontSize: "18px",
                                            fontFamily: "Gilroy",
                                        }}>
                                            {/* {currenncyRaates.currency || "USD"} {currenncyRaates.currency === "USD" ? "$" : currenncyRaates.currency === "ZWD" ? "Z$" : "$"}{currenncyRaates.convertedAmount || userData.Balance} */}
                                            {userData?.Currency} {userData?.Balance}
                                        </span>
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg="6" md="6" sm="12" xs="12">
                        <Card>
                            <CardBody>
                                <div className="d-flex justify-content-between">
                                    <div className="align-self-center flex-1">
                                        <div
                                            className="text-muted "
                                            style={{
                                                display: "flex",
                                                flexFlow: "column",
                                                gap: "15px",
                                            }}
                                        >
                                            <h3 className="userprofile_left_card_heading">
                                                {userData && userData.VPA}
                                            </h3>
                                            <h6 className="mb-1 userProfile_left_after_heading_text">
                                                Here&rsquo;s your unique QR Code
                                            </h6>

                                            <p className="mb-0 userProfile_left_after_heading_text me-3">
                                                Use this for sending and receving payments, You can
                                                print this also
                                            </p>
                                            <div className="d-flex gap-3">
                                                <ReactToPrint
                                                    trigger={() => (
                                                        <Button type="button" style={{ width: '50%' }} className="procces_Button">
                                                            Print code
                                                        </Button>
                                                    )}
                                                    content={() => componentRef.current}
                                                />
                                                <ReactToPrint
                                                    trigger={() => (
                                                        <Button type="button" style={{ width: '50%' }} className="procces_Button">
                                                            Delivery print code
                                                        </Button>
                                                    )}
                                                    content={() => componentRef2.current}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className=""
                                        style={{ width: "200px", height: "200px" }}
                                        id="printContent"
                                    >
                                        <ReactToPrint content={() => componentRef.current} />
                                        <ComponentToPrint ref={componentRef} userData={userData} />
                                        <div style={{ display: 'none' }}>
                                            <ReactToPrint content={() => componentRef2.current} />
                                            <DeliveryComponentToPrint ref={componentRef2} userData={userData} />
                                        </div>
                                    </div>
                                </div>

                                <div
                                    className="d-flex justify-content-between mt-3"
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <img src={lock} alt="lock" />
                                        <span className="pt-2 userProfile_left_after_heading_text">
                                            Change password
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            gap: "10px",
                                            fontFamily: "Gilroy",
                                        }}
                                    >
                                        <span className="pt-2 userProfile_left_after_heading_text">
                                            **********
                                        </span>
                                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                                            <img
                                                src={edit}
                                                alt="edit"
                                                onClick={() => setIsPassword(true)}
                                                style={{ width: "25px" }}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                                <div
                                    className="d-flex justify-content-between mt-3 mb-3 "
                                    style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        alignContent: "center",
                                        alignItems: "center",
                                    }}
                                >
                                    <div
                                        style={{
                                            display: "flex",
                                            gap: "10px",
                                        }}
                                    >
                                        <img src={lock} alt=" lock" />
                                        <span className="pt-2 userProfile_left_after_heading_text">
                                            Reset pin
                                        </span>
                                    </div>
                                    <div
                                        style={{
                                            display: "flex",
                                            justifyContent: "center",
                                            alignContent: "center",
                                            alignItems: "center",
                                            gap: "10px",
                                        }}
                                    >
                                        <span className="pt-2 userProfile_left_after_heading_text">
                                            **********
                                        </span>
                                        {actionData === null || (actionData?.MProfile?.action === true) ? (
                                            <img
                                                src={edit}
                                                alt="edit"
                                                onClick={() => setIsChangePin(true)}
                                                style={{ width: "25px" }}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </React.Fragment>
        </>
    );
}
export default UserprofileHeader;

