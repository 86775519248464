import React from "react";
import PropTypes from "prop-types";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { connect } from "react-redux";
// Import Routes
import { authProtectedRoutes, publicRoutes } from "./routes/";
import AppRoute from "./routes/route";
// layouts
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";


// Import fackbackend Configuration file
import fakeBackend from "./helpers/AuthType/fakeBackend";
import CookiesContent from "pages/Cookies/Cookies";
import Fingerprint2 from 'fingerprintjs2';


// Activating fake backend
fakeBackend();
const App = ({ layout }) => {


  let ddviceId;
  const fetchDeviceId = async () => {
    if (window.electronAPI && window.electronAPI.getDeviceId) {
      try {
        const storedDeviceId = await window.electronAPI.getDeviceId();
        //   console.log('Electron Device ID::::', storedDeviceId);
        // Set the device ID in the state
        //   setEDeviceId(storedDeviceId);
        // Save the device ID to localStorage
        localStorage.setItem('DeviceId', storedDeviceId);
        ddviceId = localStorage.getItem('DeviceId');
        console.log('Electron::ddviceId::', ddviceId);
        return storedDeviceId;

      } catch (error) {
        console.error('Failed to fetch device ID:', error);
      }
    } else {
      return new Promise((resolve) => {
        //       const storedDeviceId = localStorage.getItem('deviceId');
        Fingerprint2.get((components) => {
          const values = components.map((component) => component.value);
          const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
          localStorage.setItem('DeviceId', deviceId);
          //setCurrentdeviceId(deviceId);
          ddviceId = localStorage.getItem('DeviceId');
          console.log('Browser::ddviceId::', ddviceId);


          resolve(deviceId);

        });
      })

    }
  };

  console.log('::ddviceId::', ddviceId);
  const checkGetDeviceID = async () => {
    const deviceId = await fetchDeviceId();
    console.log('Extracted-Device-ID:', deviceId);

  };

  checkGetDeviceID();
  /**
   * Returns the layout
   */
  const getLayout = () => {

    let layoutCls = VerticalLayout;

    switch (layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  };
  const Layout = getLayout();
  return (
    <React.Fragment>
      <Router>
        <Switch>
          {publicRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
            />
          ))}

          {authProtectedRoutes.map((route, idx) => (
            <AppRoute
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              isLocked={true}
              exact
            />
          ))}
        </Switch>
      </Router>
      <CookiesContent />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};



export default connect(mapStateToProps, null)(App);
