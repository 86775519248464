import React, { createContext, useContext, useState, useEffect } from "react";
import { fetchMerchantProfile, fetchUserProfile, fetchmerchantPayment, gNoOfTransaction, gTotalDashBoard2, gTotalInsights, gTotalTranscationPerDay } from "./ContextApi";
import { io } from "socket.io-client";
import axios from "axios";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import ScanPayPopup from "components/Common/ScanPay.Request";
import { getDeviceId } from "components/Common/generateDeviceId";
import { handleLogout } from "pages/Authentication/logout/logout444";
import LogoutModal from "components/Logout";


const DataContext = createContext();
const API_BASE_URL = process.env.REACT_APP_API_URL;
export const useData = () => { return useContext(DataContext); };
const token = JSON.parse(localStorage.getItem("authUsers"));

export const DataProvider = React.memo(({ children }) => {
  const [data, setData] = useState(null);
  const [mData, setMData] = useState({});
  const [userDataa, setUserDatas] = useState([]);
  const [socket, setSocket] = useState(null);
  const [tokenSocket, setTokenSocket] = useState(null);
  const [notifications, setNotification] = useState([]);
  const [profileData, setProfileData] = useState({});
  const [totalInsights, setTotalInsights] = useState([]);
  const [noOfTranscation, setNoOfTransaction] = useState([]);
  const [balance, setBalance] = useState({});
  const [dashboardWidgetData, setDashboardWidgetData] = useState({});
  const [iPAddress, setIPAddress] = useState('');
  const [transactionPerDay, settransactionPerDay] = useState([]);
  const [socketTransaction, setSocketTransaction] = useState();
  const [socketScanPay, setSocketScanPay] = useState({});
  const [isApiRunning, setIsApiRunning] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [orderData, setOrderData] = useState({});
  const [deviceId, setDeviceId] = useState('');
  const [logout, setLogout] = useState({});
  const [isLogout, setIslogout] = useState(false);

  const [userInfo, setUserInfo] = useState({
    browser: { name: '', version: '' },
    ip: '',
    location: { city: '', country: '', lat: '', lon: '' },
  });

  useEffect(() => {
    if (mData && Object.keys(mData).length > 0) { setUserDatas({ ...mData.result }); setData({ ...mData.subResult }); }
  }, [mData]);



  const fetchData = async (fetchFunction, setter) => {
    try {
      const response = await fetchFunction();
      const result = response.result;
      setter(result);
    } catch (error) {
      setTimeout(async () => {
        if (error?.response?.status == 444) {
          await handleLogout()
        }
      }, 5000);

      console.log(error.response);
    }
  };

  useEffect(() => {
    fHandleDevice();
  }, [!deviceId]);

  const fHandleDevice = async () => {
    const deviceId = await getDeviceId();
    setDeviceId(deviceId);
  };

  useEffect(() => {
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        setIsApiRunning(true);
        return config;
      },
      (error) => {
        setIsApiRunning(false);
        return Promise.reject(error);
      }
    );
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // console.log(response);

        setIsApiRunning(false);
        return response;
      },
      (error) => {
        setIsApiRunning(false);
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, []);



  useEffect(() => {
    if (token && token.length > 0) {
      if (!mData || Object.keys(mData).length === 0) {
        fetchData(fetchUserProfile, setMData);
      }
      fetchData(fetchMerchantProfile, setProfileData);
    }
    handleFetch();
  }, [token, mData]);



  const initializeSocket = (userId, role, subAdminId = null) => {
    const newSocket = io(API_BASE_URL, { query: { userId, role, subAdminId } });

    newSocket.on("connect", () => {
      // console.log("Socket connected!");
      setSocket(newSocket);
    });

    newSocket.on("disconnect", () => {
      setSocket(null);
      // console.log("Socket disconnected!");
    });

    newSocket.on("running", (data) => {
      setNotification(data);
    });

    newSocket.on("MBalance", (data) => {
      setBalance(data);
    });

    newSocket.on("DashboardWidget", (data) => {
      setDashboardWidgetData(data);
    });

    newSocket.on("vaultTransaction", (data) => {
      setSocketTransaction(data);
    });

    newSocket.on("scanpay", (data) => {
      setSocketScanPay(data);
      if (data?.Type === 'REQUEST') {
        toastr.info(data?.message);
        if (!isApiRunning) {
          setShowPopup(true);
        }
      }
    });
    newSocket.on("subadminlogout", (data) => {
      setLogout(data);
      setIslogout(true);
    });

    newSocket.on("ordertable", (data) => {
      setOrderData(data);
    });

    return newSocket;
  };


  let role = mData?.subResult?.id ? "subAdmin" : "admin";
  let subAdminId = mData?.subResult?.id


  useEffect(() => {
    if (userDataa?.id && role) {
      const newSocket = initializeSocket(userDataa?.id, role, subAdminId);
      return () => {
        newSocket.disconnect();
        // console.log("Socket disconnected!");
      };
    }
  }, [userDataa?.id, role, mData?.subResult?.id]);




  const initializeTokenSocket = (token) => {
    const newSocket = io(API_BASE_URL, { query: { token: token } });
    newSocket.on("connect", () => {
      setTokenSocket(newSocket);
    });

    newSocket.on("tokenlogout", async (data) => {
      if (data == 'logout') {
        await handleLogout()
      }
    });


    newSocket.on("disconnect", () => {
      setTokenSocket(null);
    });

    return newSocket;
  };


  useEffect(() => {
    if (token) {
      const newSocket = initializeTokenSocket(token);
      return () => {
        newSocket.disconnect();
        console.log("Socket disconnected!");
      };
    }
  }, [token]);


  function handleFetch() {
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        setIPAddress(data.ip)
      })
      .catch(error => {
        console.error('Error fetching IP address:', error);
      });
  }

  useEffect(() => {
    const fetchUserInfo = async () => {
      const userAgent = navigator.userAgent;
      const browser = {
        name: '',
        version: '',
      };

      if (userAgent.indexOf('Chrome') > -1) {
        browser.name = 'Chrome';
      } else if (userAgent.indexOf('Firefox') > -1) {
        browser.name = 'Firefox';
      } else if (userAgent.indexOf('Safari') > -1) {
        browser.name = 'Safari';
      } else if (userAgent.indexOf('Opera') > -1 || userAgent.indexOf('OPR') > -1) {
        browser.name = 'Opera';
      } else if (userAgent.indexOf('MSIE') > -1 || !!document.documentMode === true) {
        browser.name = 'Internet Explorer';
      } else {
        browser.name = 'Unknown';
      }

      const versionMatch = userAgent.match(/(Chrome|Firefox|Safari|Opera|MSIE|Trident)\/?\s*(\d+)/);
      browser.version = versionMatch ? versionMatch[2] : 'Unknown';

      try {
        const ipResponse = await axios.get('https://ipinfo.io/json');
        const ipData = ipResponse.data;
        const ip = ipData.ip;

        const location = {
          city: ipData.city || 'Not Available',
          country: ipData.country || 'Not Available',
          lat: ipData.loc ? ipData.loc.split(',')[0] : 'Not Available',
          lon: ipData.loc ? ipData.loc.split(',')[1] : 'Not Available',
        };

        setUserInfo(prevState => ({
          ...prevState,
          browser,
          ip,
          location,
        }));
      } catch (error) {
        console.error('Error fetching IP or location data:', error);
      }
      const successCallback = (position) => {
        setUserInfo(prevState => ({
          ...prevState,
          location: {
            ...prevState.location,
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          }
        }));
      };

      const errorCallback = (error) => {
        console.error('Error fetching geolocation:', error);
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(successCallback, errorCallback);
      }
    };
    fetchUserInfo();
  }, []);

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const handleCancelLogout = () => {
    setIslogout(false);
  };

  return (
    <DataContext.Provider
      value={{
        userDataa,
        mData,
        socket,
        notifications,
        profileData,
        balance,
        dashboardWidgetData,
        data,
        iPAddress,
        deviceId,
        socketTransaction,
        fetchUserProfile: async () => fetchData(fetchUserProfile, setMData),
        transactionPerDay,
        gTotalTranscationPerDay: async () => fetchData(gTotalTranscationPerDay, settransactionPerDay),
        noOfTranscation,
        gNoOfTransaction: async () => fetchData(gNoOfTransaction, setNoOfTransaction),
        totalInsights,
        gTotalInsights: async () => fetchData(gTotalInsights, setTotalInsights),
        userInfo,
        socketScanPay,
        setSocketScanPay,
        setIsApiRunning,
        isApiRunning,
        orderData,
      }}
    >
      {children}

      <ScanPayPopup
        isOpen={showPopup}
        setShowPopup={setShowPopup}
        socketScanPay={socketScanPay}
        onClose={handleClosePopup}
      />

      <LogoutModal
        isOpen={isLogout}
        data={logout}
        userInfo={userInfo}
        onClose={handleCancelLogout}
        deviceId={deviceId}

      />

    </DataContext.Provider>
  );
});


