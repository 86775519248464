import { handleLogout } from 'Context/ContextApi';
import React from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';

const LogoutModal = ({ isOpen, data, userInfo, onClose, deviceId }) => {
    const history = useHistory();

    const handleGoToPage = async (id) => {
        console.log(id);
        try {
            const formData = {
                id: id,
                Status: "INACTIVE",
                LastInactiveDateTime: new Date(),
                DeviceId: deviceId,
                ...userInfo,
            }
            const response = await handleLogout(formData)
            localStorage.removeItem("paths");
            localStorage.removeItem("PublicKey");
            localStorage.removeItem("authUsers")
            window.location.reload();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Modal isOpen={isOpen} toggle={onClose} centered>
            <ModalHeader toggle={onClose}>{data.Title}</ModalHeader>
            <ModalBody>
                <p>{data?.Message}</p>
            </ModalBody>
            <ModalFooter>
                <Button color="primary"
                    onClick={() => handleGoToPage(data.id)}
                >
                    Logout
                </Button>
                <Button color="secondary" onClick={onClose}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default LogoutModal;

