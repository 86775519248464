// import { updateSessionPaymentReuest } from "Context/ContextApi";
// import React, { useEffect, useState } from "react";
// import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
// import toastr from "toastr";
// import "toastr/build/toastr.min.css";

// export const TimeInterval = ({ socketScanPay, setSocketScanPay, handleCancel }) => {
//     const initialTime = 480; 
//     const [timeLeft, setTimeLeft] = useState(initialTime);
//     const [showTimeoutModal, setShowTimeoutModal] = useState(false);
//     console.log(socketScanPay, "socketScanPay");
    

//     useEffect(() => {
//         let timer;

//         if (socketScanPay && socketScanPay?.Data?.TimeStamp) {
//             const startTime = new Date(socketScanPay?.Data?.TimeStamp).getTime();
//             const currentTime = Date.now();
//             const elapsedTime = Math.floor((currentTime - startTime) / 1000);
//             const remainingTime = Math.max(initialTime - elapsedTime, 0);

//             setTimeLeft(remainingTime);

//             if (remainingTime > 0) {
//                 timer = setInterval(() => {
//                     setTimeLeft((prevTime) => {
//                         if (prevTime <= 1) {
//                             clearInterval(timer);
//                             setShowTimeoutModal(true);
//                             return 0;
//                         }
//                         return prevTime - 1;
//                     });
//                 }, 1000);
//             } else {
//                 setShowTimeoutModal(true);
//             }
//         }

//         return () => {
//             clearInterval(timer);
//         };
//     }, [socketScanPay]);

//     const formatTime = (seconds) => {
//         const minutes = Math.floor(seconds / 60);
//         const remainingSeconds = seconds % 60;
//         return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
//     };

//     const handleContinueSession = async () => {
//         try {
//             const formData ={
//                 id:socketScanPay?.Data?.id,
//                 UserId: socketScanPay?.Data?.UserId,
//             }
//             const response = await updateSessionPaymentReuest(formData)
//             setSocketScanPay(response.result);
//             setTimeLeft(initialTime);
//             setShowTimeoutModal(false);
//             toastr.success(response.message);
//         } catch (error) {
//             toastr.error(error?.response?.data?.message || error?.message)
//         }
       
//     };

//     const handleCancelSession = () => {
//         handleCancel();
//         setShowTimeoutModal(false);
//         setSocketScanPay({});
//         toastr.info("Session canceled.");
//     };

//     return (
//         <React.Fragment>
//             <Row className="justify-content-center scan-pay-user-details">
//                 <Col xl="4">
//                     <h4 className="card-title">Scan Pay</h4>
//                 </Col>
//                 <Col xl="6"></Col>
//                 <Col xl="2">
//                     {socketScanPay && timeLeft > 0 && (
//                         <div className="timer-container">
//                             <p>Time Left: {formatTime(timeLeft)}</p>
//                         </div>
//                     )}
//                 </Col>
//             </Row>

//             <Modal center isOpen={showTimeoutModal} toggle={handleCancelSession}>
//                 <ModalHeader toggle={handleCancelSession}>Session Timed Out</ModalHeader>
//                 <ModalBody>
//                     Your session has timed out. Would you like to continue or cancel the session?
//                 </ModalBody>
//                 <ModalFooter>
//                     <Button color="primary" onClick={handleContinueSession}>
//                         Continue Session
//                     </Button>
//                     <Button color="secondary" onClick={handleCancelSession}>
//                         Cancel
//                     </Button>
//                 </ModalFooter>
//             </Modal>
//         </React.Fragment>
//     );
// };



import { updateSessionPaymentReuest } from "Context/ContextApi";
import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";

export const TimeInterval = ({ socketScanPay, setSocketScanPay, handleCancel }) => {
    const [timeLeft, setTimeLeft] = useState(0); 
    const [showTimeoutModal, setShowTimeoutModal] = useState(false);
    
    useEffect(() => {
        let timer;

        if (socketScanPay && socketScanPay?.Data?.ExpiryDate) {
            const expiryDate = new Date(socketScanPay?.Data?.ExpiryDate).getTime();
            const currentTime = Date.now();
            const remainingTime = Math.floor((expiryDate - currentTime) / 1000); 
            
            setTimeLeft(Math.max(remainingTime, 0));

            if (remainingTime > 0) {
                timer = setInterval(() => {
                    setTimeLeft((prevTime) => {
                        if (prevTime <= 1) {
                            clearInterval(timer);
                            setShowTimeoutModal(true);
                            return 0;
                        }
                        return prevTime - 1;
                    });
                }, 1000);
            } else {
                setShowTimeoutModal(true);
            }
        }

        return () => {
            clearInterval(timer);
        };
    }, [socketScanPay]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    };

    const handleContinueSession = async () => {
        try {
            const formData = {
                id: socketScanPay?.Data?.id,
                UserId: socketScanPay?.Data?.UserId,
            };
            const response = await updateSessionPaymentReuest(formData);
            setSocketScanPay(response.result);
            const newExpiryDate = new Date(response.result?.Data?.ExpiryDate).getTime();
            const newCurrentTime = Date.now();
            const newRemainingTime = Math.floor((newExpiryDate - newCurrentTime) / 1000);
            setTimeLeft(newRemainingTime);

            setShowTimeoutModal(false);
            toastr.success(response.message);
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message);
        }
    };

    const handleCancelSession = () => {
        handleCancel();
        setShowTimeoutModal(false);
        setSocketScanPay({});
        toastr.info("Session canceled.");
    };

    return (
        <React.Fragment>
            <Row className="justify-content-center scan-pay-user-details">
                <Col xl="4">
                    <h4 className="card-title">Scan Pay</h4>
                </Col>
                <Col xl="6"></Col>
                <Col xl="2">
                    {socketScanPay && timeLeft > 0 && (
                        <div className="timer-container">
                            <p>Time Left: {formatTime(timeLeft)}</p>
                        </div>
                    )}
                </Col>
            </Row>

            <Modal center isOpen={showTimeoutModal} toggle={handleCancelSession}>
                <ModalHeader toggle={handleCancelSession}>Session Timed Out</ModalHeader>
                <ModalBody>
                    Your session has timed out. Would you like to continue or cancel the session?
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleContinueSession}>
                        Continue Session
                    </Button>
                    <Button color="secondary" onClick={handleCancelSession}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};



