import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { updateprofileData } from "Context/ContextApi";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

const libraries = ["places"];

const containerStyle = {
    width: "100%",
    height: "400px",
};

const markerIcon = {
    path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "blue",
    fillOpacity: 0.9,
    scale: 1.5,
    strokeColor: "white",
    strokeWeight: 1,
};

function GoogleLocation({ data }) {
    const { Latitude, Longitude } = data || {};
    const center = {
        lat: Latitude,
        lng: Longitude
    };


    useEffect(() => {
        if (data) {
            const newLat = Latitude || center.lat;
            const newLng = Longitude || center.lng;
            setMarkerPosition({ lat: newLat, lng: newLng });
            setLatitude(newLat);
            setLongitude(newLng);
        } else {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(
                    (position) => {
                        const { latitude: userLat, longitude: userLng } = position.coords;
                        setMarkerPosition({ lat: userLat, lng: userLng });
                        setLatitude(userLat);
                        setLongitude(userLng);
                    },
                    (error) => {
                        console.error("Error getting user location: ", error);
                    }
                );
            }
        }
    }, [data]);

    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyA4vPbZfXV95dWDKAellaSOc_2NT1FY7lg",
        libraries,
    });

    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(center);
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState(center.lat);
    const [longitude, setLongitude] = useState(center.lng);


    useEffect(() => {
        if (isLoaded && inputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    const newLat = place.geometry.location.lat();
                    const newLng = place.geometry.location.lng();

                    setMarkerPosition({ lat: newLat, lng: newLng });
                    setLatitude(newLat);
                    setLongitude(newLng);
                    setAddress(place.formatted_address || "");
                }
            });
        }
    }, [isLoaded]);

    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        setLatitude(newLat);
        setLongitude(newLng);

        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat: newLat, lng: newLng } }, (results, status) => {
            if (status === "OK" && results[0]) {
                setAddress(results[0].formatted_address);
            }
        });
    };

    const handleUpdateTheData = async (type) => {
        try {
            const formData = {
                type: type,
                Latitude: latitude,
                Longitude: longitude,
            };
            const response = await updateprofileData(formData);
            toastr.success(response.message, "Success", {
                timeOut: 7000,
                progressBar: true,
                closeDuration: 700,
                positionClass: "toast-top-right"
            });
        } catch (error) {
            console.log(error);
        }
    };

    const inputRef = useRef(null);

    return isLoaded ? (
        <React.Fragment>
            <Card className="p-3">
                <h4 className="personal_info_use_profile mb-2 mt-3 ms-4 title-color-common">
                    Location
                </h4>
                <CardBody>
                    <Row>
                        <Col lg={12} md={12} sm={12} xs={12}>
                            <div className="">
                                <div className="Registration_form_complete">
                                    <input
                                        type="text"
                                        placeholder="Search location"
                                        ref={inputRef}
                                        style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
                                    />
                                </div>

                                <GoogleMap
                                    mapContainerStyle={containerStyle}
                                    center={markerPosition}
                                    zoom={15}
                                    onLoad={map => setMap(map)}
                                >
                                    <Marker
                                        position={markerPosition}
                                        draggable={true}
                                        onDragEnd={handleMarkerDragEnd}
                                        icon={markerIcon}
                                    />
                                </GoogleMap>

                                <div className="google-location-address">
                                    <p>Address: {address}</p>
                                    <p>Latitude: {latitude}</p>
                                    <p>Longitude: {longitude}</p>
                                </div>
                                <div className="d-flex gap-4">
                                    <Button onClick={() => setMarkerPosition(center)}>Clear</Button>
                                    <Button
                                        style={{ fontSize: "18px" }}
                                        className="procces_Button"
                                        onClick={() => handleUpdateTheData('Location')}
                                    >
                                        Update
                                    </Button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </React.Fragment>
    ) : (
        <></>
    );
}

export default GoogleLocation;


