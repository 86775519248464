import { adminLoginOtp, merchanInformation } from 'Auth/ApiFunction';
import Cookies from 'js-cookie';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Form, FormGroup } from 'reactstrap';
import { io } from 'socket.io-client';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const API_BASE_URL = process.env.API_BASE_URL;
const ModuleLoginOtp = ({ isOpen, onClose, setShowPopup , userInfo, iPAddress,}) => {
    const history = useHistory();
    const [otp, setOtp] = useState(new Array(6).fill(""));

    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value === "" || /^[a-zA-Z0-9]$/.test(value)) {
            const newOtp = otp.slice();
            newOtp[index] = value;
            setOtp(newOtp);
            if (e.target.nextSibling && value !== "") {
                e.target.nextSibling.focus();
            }
            if (newOtp.every(char => char !== "")) {
                handleOtpLogin(newOtp);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            const newOtp = otp.slice();
            newOtp[index] = "";
            setOtp(newOtp);
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text").slice(0, 6);
        if (/^[a-zA-Z0-9]{1,6}$/.test(pasteData)) {
            const newOtp = [...pasteData.split("")];
            setOtp(newOtp);
            if (newOtp.every(char => char !== "")) {
                handleOtpLogin(newOtp);
            }
        }
        e.preventDefault();
    };

    const handleOtpLogin = async (otpValueArray) =>{
        try {
            const otpValue = otpValueArray.join("");
            if (otpValue.length < 6) {
                toastr.error("Please enter the full OTP.");
                return;
            }
            const formData = {
                otp: otpValue,
                IPAddress: iPAddress,
                Latitude: userInfo?.location?.lat,
                Longitude: userInfo?.location?.lon,
            };
           const response = await adminLoginOtp(formData)
           toastr.success(response.message)
           const socket = io(API_BASE_URL);
           if (response && response.token) {
             const expirationDate = new Date(new Date().getTime() + 12 * 60 * 60 * 1000);
             const authUserData = {
               token: response.token,
               expires: expirationDate.toISOString(),
               IPAddress: iPAddress,
             };
             Cookies.set("authUsers", JSON.stringify(authUserData), {expires: expirationDate,});
             localStorage.setItem("authUsers", JSON.stringify(response.token));
             localStorage.setItem("PublicKey", response?.PublicKey);
             localStorage.setItem("MerchantApiKey",  response?.MerchantApiKey)
             let data = {
               MerchantId: response.result.id,
               Status: "ACTIVE",
               LastInactiveDateTime: '',
               DateTime: new Date(),
               ...userInfo
             }
             await merchanInformation(data);
             await socket.emit("merchantInfo", JSON.stringify(data));
             if (response.result?.Register_Status === "COMPLETED") {
               if (response.result?.kycVerified === "ReVerify") {
                 history.push("/re-verify");
               } else {
                 history.push("/get-dashboard");
               }
             } else {
               history.push("/registration-form2");
             }
           }
           setOtp(new Array(6).fill(""));
        } catch (error) {
            setOtp(new Array(6).fill(""));
           toastr.error(error?.response?.data?.message || error?.message) 
        }
    }
    return (
        <Modal isOpen={isOpen} toggle={onClose} centered>
            <ModalHeader toggle={onClose}>Login OTP</ModalHeader>
            <ModalBody>
                <div className="login-otp-box m-2 ">
                    <Form className="login-otp-container">
                        <FormGroup className="d-flex justify-content-center login-otp-input" onPaste={handlePaste}>
                            {otp.map((data, index) => (
                                <Input
                                    key={index}
                                    type="text"
                                    maxLength="1"
                                    value={data}
                                    onChange={(e) => handleChange(e, index)}
                                    onFocus={(e) => e.target.select()}
                                    onKeyDown={(e) => handleKeyDown(e, index)}
                                    className="login-otp-field text-center"
                                />
                            ))}
                        </FormGroup>
                    </Form>
                </div>
            </ModalBody>
            <ModalFooter>
                <Button color="primary"
                    onClick={() => handleOtpLogin()}
                >
                   Submit
                </Button>
                <Button color="secondary" onClick={onClose}>
                    Close
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default ModuleLoginOtp;

