import React from "react";
import { Redirect } from "react-router-dom";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";

//Pages
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";

// new pages
import Transaction from "pages/Transactions/Transaction";
import WithdrawalsList from "pages/Withdrawals/WithdrawalsList";
import withdrawalsSetting from "pages/Withdrawals/withdrawalsSetting";
import Payments from "pages/Payments/Payments";
import Disputes from "pages/Disputes/Disputes";
import setting from "pages/Settings/setting";
import SendMoney from "pages/SendMoney/SendMoney";
import RequestMoney from "pages/RequestMoney/RequestMoney";
import WithdrawMoney from "pages/WithdrawMoney/WithdrawMoney";
import campaigns from "pages/Campaigns/campaigns";
import campaignsCoupon from "pages/Campaigns/campaignsCoupon";
import getdashboard from "pages/Get-Dashboard/getDashboard";
import MerchantRegistration from "pages/Get-Register/MerchantRegistration";
import Userprofile from "pages/UserProfile/UserProfile";
import PrintOut from "pages/DepositMoney/PrintOut";
import ManageUserGroup from "pages/UserGroups/ManageUserGroups";
import AddUserGroup from "pages/UserGroups/AddUserGroup";
import ManageRoles from "pages/ManageRoles/ManageRoles";
import AddRole from "pages/ManageRoles/AddRole";
import DepositHistory from "pages/DepositMoney/DepositHistory";
import SendHistory from "pages/SendMoney/SendMoneyHistory";
import RequestHistory from "pages/RequestMoney/RequestHistory";
import General from "pages/UserGroups/general";
import Notifications from "pages/notifications/notification";
import CompleteRegistration from "pages/Authentication/CompleteRegistration";
import GetRegistration from "pages/Authentication/Registration";
import CookiePolicyContnent from "pages/Cookies/CookiesContent";
import RefundPayments from "pages/Payments/RefundPayments";
import CampaignRedeemed from "pages/Campaigns/campaignsRedeemed";
import TermsAndCondition from "pages/Policy/TermsAndCondition";
import PrivacyPlicy from "pages/Policy/PrivacyPolicy";
import SubAdminUserprofile from "pages/UserProfile/subAdminProfile";
import ActiveLog from "pages/Activity/ActiveLog";
import Statement from "pages/statement";
import GetWaysDetails from "pages/GetWays";
import DepositMoneyIndex from "pages/DepositMoney/Deposit/DepositMoney";
import ReVerifyForm from "pages/Authentication/Re-verifyTheDocument";
import NewLogin from "pages/Authentication/newLogin";
import NationalIdVerification from "pages/Authentication/Verification";
import ThemesList from "pages/Themes";
import ThemesCreate from "pages/Themes/create";
import ThemesUpdate from "pages/Themes/edit";
import CreateOffer from "pages/Campaigns/createOffer";
import InitiateScanPay from "pages/ScanPay";
import OrderTable from "pages/ScanPay/OrderTable";
import EditScanPay from "pages/ScanPay/edit";
import CaptchOTP from "pages/Authentication/captchOtp";
import ListOfQRCode from "pages/QRCode/List";
import CreateQrcode from "pages/QRCode/Create";
import ExternalPayment from "pages/ScanPay/externalPayment";
import WhiteList from "pages/Authentication/Whitelist";
import ListOfDevices from "pages/Devices/List";
import CompleteRegistrations from "pages/Authentication/Complete-registrations/index"
import RefundTable from "pages/ScanPay/Refunds";
import RefundsPaymentDetails from "pages/ScanPay/Refunds/ReturnAndRefunds";
import { components } from "react-select/dist/react-select.cjs.prod";
import Createquick from "pages/create-quick/create";
import UploadCsvStep2 from "pages/create-quick/upload-csv-step-2";
import QuickList from "pages/create-quick/list";
import ZesaList from "pages/Zesa";
import ZesapaymentPage from "pages/ScanPay/Zesa-Payment";
import NFCAndScan from "pages/Authentication/NfcAndScan";
import CommonWhiteList from "pages/Authentication/common-whitelist";

const authProtectedRoutes = [

  // New pages
  { path: "/transaction", component: Transaction },
  { path: "/deposit-money/:id?", component: DepositMoneyIndex },
  { path: "/deposit-history", component: DepositHistory },
  // withdrawals
  { path: "/withdrawalslist", component: WithdrawalsList },
  { path: "/withdrawals-setting", component: withdrawalsSetting },
  { path: "/payments", component: Payments },
  { path: "/payment-details/:id?", component: RefundPayments },
  { path: "/disputes", component: Disputes },
  { path: "/verifications", component: setting },
  { path: "/send-money", component: SendMoney },
  { path: "/send-money-history", component: SendHistory },
  { path: "/request-money", component: RequestMoney },
  { path: "/request-money-history", component: RequestHistory },
  { path: "/withdraw-money", component: WithdrawMoney },
  { path: "/campaigns", component: campaigns },
  { path: "/create-offer/:id?", component: CreateOffer },
  { path: "/campaign-redeemed", component: CampaignRedeemed },
  { path: "/campaign-coupon", component: campaignsCoupon },
  { path: "/merchant-register", component: MerchantRegistration },
  { path: "/user-profile", component: Userprofile },
  { path: "/print-out", component: PrintOut },
  { path: "/manage-user-groups", component: ManageUserGroup },
  { path: "/add-user-group/:id?", component: AddUserGroup },
  { path: "/manage-roles", component: ManageRoles },
  { path: "/add-role/:id?", component: AddRole },
  { path: "/general", component: General },
  { path: "/Notifications", component: Notifications },
  { path: "/get-dashboard", component: getdashboard },
  { path: "/sub-admin-profile", component: SubAdminUserprofile },
  { path: "/active-log", component: ActiveLog },
  { path: '/statement', component: Statement },
  { path: "/get-payment-details", component: GetWaysDetails },
  { path: "/themes-list", component: ThemesList },
  { path: "/themes/create", component: ThemesCreate },
  { path: "/themes/update/:id", component: ThemesUpdate },
  { path: "/initiate-scan-pay", component: InitiateScanPay },
  { path: "/orders", component: OrderTable },
  { path: "/scan-pay/:id?", component: EditScanPay },
  { path: "/qr-code-list", component: ListOfQRCode },
  { path: "/create-qr-code", component: CreateQrcode },
  { path: '/order-payment/:id?', component: ExternalPayment },
  { path: '/devices', component: ListOfDevices },
  { path: '/refunds', component: RefundTable },
  { path: '/refund-details/:id', component: RefundsPaymentDetails },
  { path: '/quick/create', component: Createquick },
  { path: '/quick/item/edit', component: UploadCsvStep2 },
  { path: '/quick/item/list', component: QuickList },
  { path: '/zesa/list', component: ZesaList },
  { path: '/zesa/payment/dtails/:id', component: ZesapaymentPage },

  { path: "/", exact: true, component: () => <Redirect to="/get-dashboard" /> },
];

const publicRoutes = [
  // { path: "/captch-otp", component: CaptchOTP },
  { path: "/logout", component: Logout },
  { path: "/log-in ", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
  { path: '/registration-form', component: CompleteRegistration },
  { path: '/registration-form2', component: CompleteRegistrations },
  { path: "/re-verify", component: ReVerifyForm },
  { path: "/registration", component: GetRegistration },
  { path: "/pages-maintenance", component: PagesMaintenance },
  { path: "/pages-comingsoon", component: PagesComingsoon },
  { path: "/pages-404", component: Pages404 },
  { path: "/pages-500", component: Pages500 },
  { path: "/auth-lock-screen", component: LockScreen },
  { path: "/cookies", component: CookiePolicyContnent },
  { path: "/terms-condition", component: TermsAndCondition },
  { path: "/privacy-policy", component: PrivacyPlicy },
  { path: "/login", component: NewLogin },
  { path: "/verification", component: NationalIdVerification },
  { path: "/:url/add", component: WhiteList },
  { path: "/get9878979798", component: NFCAndScan },
  { path: "/add", component: CommonWhiteList }
]

export { authProtectedRoutes, publicRoutes };
