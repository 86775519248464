import React, { useState, useEffect } from 'react';
import { googleLogout, useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';
import { loginWithGoogle, merchanInformation, merchantRegisterWithGoogle } from 'Auth/ApiFunction';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';
import { useData } from 'Context/ContextProvider';
import { io } from 'socket.io-client';
import ModuleLoginOtp from './login-otp-popup';


const API_BASE_URL = process.env.REACT_APP_API_URL;

function LoginWithGoogle() {
    const { iPAddress, userInfo } = useData();
    const history = useHistory();
    const [showPopup, setShowPopup] = useState(false);
    const [showPassword, setShowPassword] = useState(false);


    const login = useGoogleLogin({
        onSuccess: (codeResponse) => fetchUserProfile(codeResponse),
        onError: (error) => console.log('Login Failed:', error)
    });

    const fetchUserProfile = async (user) => {
        try {
            const response = await axios.get(`https://www.googleapis.com/oauth2/v1/userinfo?access_token=${user.access_token}`, {
                headers: {
                    Authorization: `Bearer ${user.access_token}`,
                    Accept: 'application/json'
                }
            });
            signUpWithGoogle(response.data);
        } catch (err) {
            console.log(err);
        }
    };

    const signUpWithGoogle = async (item) => {
        try {
            const formData = {
                Email: item?.email,
                FullName: item?.name,
                ProfileImage: item?.picture,
                IPAddress: iPAddress
            };

            const response = await loginWithGoogle(formData);
            toastr.success(response.message);
            if (response.isShow) {
                setShowPopup(response.isShow)
                return;
            }
            const socket = io(API_BASE_URL);

            if (response && response.token) {
                const expirationDate = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
                const authUserData = {
                    token: response.token,
                    expires: expirationDate.toISOString(),
                    IPAddress: iPAddress,
                };
                Cookies.set("authUsers", JSON.stringify(authUserData), { expires: expirationDate });
                localStorage.setItem("authUsers", JSON.stringify(response.token));
                localStorage.setItem("PublicKey", response?.PublicKey);
                localStorage.setItem("MerchantApiKey", response?.MerchantApiKey);

                let data = {
                    MerchantId: response.result.id,
                    Status: "ACTIVE",
                    LastInactiveDateTime: '',
                    DateTime: new Date(),
                    ...userInfo
                }
                await merchanInformation(data);
                socket.emit("merchantInfo", JSON.stringify(data));

                if (response.result?.Register_Status === 'COMPLETED') {
                    if (response.result?.kycVerified === 'ReVerify') {
                        history.push("/re-verify");
                    } else {
                        history.push("/get-dashboard");
                    }
                } else {
                    history.push("/registration-form2");
                }
            }
            logOut()
        } catch (error) {
            setShowPopup(false)
            if (error?.response?.data?.message) {
                toastr.warning(error?.response?.data?.message);
            } else {
                toastr.error('An error occurred during registration');
            }
            logOut()
        }
    };

    const logOut = () => {
        googleLogout();
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    return (
        <React.Fragment>
            <ModuleLoginOtp
                isOpen={showPopup}
                setShowPopup={setShowPopup}
                onClose={handleClosePopup}
                userInfo={userInfo}
                iPAddress={iPAddress}
            />
            <div className='google-button'>
                <img src='https://icon2.cleanpng.com/20171220/dxq/google-png-5a3aafee6ff5c8.9595681415137955664586.jpg' alt="Google icon" />
                <button onClick={login}>Signin With Google</button>
            </div>
        </React.Fragment>

    );
}

export default LoginWithGoogle;
