// import Breadcrumbs from "components/Common/Breadcrumb";
// import React, { useCallback, useEffect, useState } from "react";
// import Dropzone from "react-dropzone";
// import { Button, Card, Col, Form, Input, Label, Row } from "reactstrap";
// import { Link } from "react-router-dom"
// import { fetchLogoOfmerchant, updateTheLogoOfMerchant } from "Context/ContextApi";
// import toastr from "toastr";
// import "toastr/build/toastr.min.css";
// import { useData } from "Context/ContextProvider";

// const API_BASE_URL = process.env.REACT_APP_API_URL;
// const UploadCSV = () => {
//     const { mData } = useData();
//     const [selectedFiles, setSelectedFiles] = useState([]);
//     const [errorMessage, setErrorMessage] = useState("")
//     const [data, setData] = useState();


//     const formatBytes = (bytes, decimals = 2) => {
//         if (bytes === 0) return "0 Bytes";
//         const k = 1024;
//         const dm = decimals < 0 ? 0 : decimals;
//         const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

//         const i = Math.floor(Math.log(bytes) / Math.log(k));
//         return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
//     };


//     const handleSumbit = async () => {
//         try {
//             if (selectedFiles.length === 0) {
//                 setErrorMessage("Please upload file");
//                 return;
//             }
//             const formData = new FormData()
//             selectedFiles?.forEach((file, index) => {
//                 formData.append(`file`, file);
//             });
//             const response = await updateTheLogoOfMerchant(formData)
//             setSelectedFiles([])
//             setData(response?.result)
//             setErrorMessage("")
//             toastr.success(response.message, "Success", {
//                 timeOut: 7000,
//                 progressBar: true,
//                 closeDuration: 700,
//                 positionClass: "toast-top-right"
//             });
//         } catch (error) {
//             console.log(error);
//         }
//     }

//     const handleAcceptedFiles = useCallback(async (files) => {
//         const updatedFiles = files.map(file =>
//             Object.assign(file, {
//                 preview: URL.createObjectURL(file),
//                 formattedSize: formatBytes(file.size),
//             })
//         );
//         const img = new Image();
//         img.src = updatedFiles;
//         img.onload = () => {
//             const width = img.naturalWidth;
//             const height = img.naturalHeight;
//             if (width == height) {
//                 return false;
//             }
//             return true;
//         }
//         setSelectedFiles(updatedFiles);
//     }, []);

//     return (
//         <>
//             <React.Fragment>
//                 <Row>

//                         <div className="create_ticket_form">
//                             <Label>Upload csv file</Label>
//                             <Form>
//                                 <Dropzone onDrop={handleAcceptedFiles} multiple>
//                                     {({ getRootProps, getInputProps }) => (
//                                         <div className="dropzone">
//                                             <div
//                                                 className="dz-message needsclick"
//                                                 {...getRootProps()}
//                                             >
//                                                 <input {...getInputProps()} />
//                                                 <div className="mb-3">
//                                                     <i className="display-4 text-muted bx bxs-cloud-upload" />
//                                                 </div>
//                                                 <h4>Drop files here or click to upload.</h4>
//                                             </div>
//                                         </div>
//                                     )}
//                                 </Dropzone>
//                                 {errorMessage && <div className="Error_message_for_upload_file">
//                                     <p>
//                                         {errorMessage}
//                                     </p>
//                                 </div>}
//                                 <div
//                                     className="dropzone-previews mt-3"
//                                     id="file-previews"
//                                 >
//                                     {selectedFiles.map((f, i) => (
//                                         <Card
//                                             className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
//                                             key={i + "-file"}
//                                         >
//                                             <div className="p-2">
//                                                 <Row className="align-items-center">
//                                                     <Col className="col-auto">
//                                                         <img
//                                                             data-dz-thumbnail=""
//                                                             height="80"
//                                                             className="avatar-sm rounded bg-light"
//                                                             alt={f.name}
//                                                             src={f.preview}
//                                                         />
//                                                     </Col>
//                                                     <Col>
//                                                         <Link
//                                                             to="#"
//                                                             className="text-muted font-weight-bold"
//                                                         >
//                                                             {f.name}
//                                                         </Link>
//                                                         <p className="mb-0">
//                                                             <strong>{f.formattedSize}</strong>
//                                                         </p>
//                                                     </Col>
//                                                 </Row>
//                                             </div>
//                                         </Card>
//                                     ))}
//                                 </div>
//                             </Form>
//                         </div>
//                         <Button
//                             className="w-100 mt-4 procces_Button"
//                             style={{ fontSize: "18px" }}
//                             onClick={handleSumbit}
//                         >
//                             {" "}
//                             Upload
//                         </Button>
//                 </Row>
//             </React.Fragment>
//         </>
//     )
// }
// export default UploadCSV

import React, { useCallback, useState } from "react";
import Dropzone from "react-dropzone";
import { Button, Card, Col, Form, Label, Row } from "reactstrap";
import { Link } from "react-router-dom";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { updateTheLogoOfMerchant, uploadCSVFile } from "Context/ContextApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const UploadCSV = () => {
    const history = useHistory();
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [errorMessage, setErrorMessage] = useState("");

    const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };

    const handleSumbit = async () => {
        try {
            if (selectedFiles.length === 0) {
                setErrorMessage("Please upload a file");
                return;
            }
            const formData = new FormData();
            selectedFiles.forEach((file) => {
                formData.append("file", file);
            });
            const response = await uploadCSVFile(formData);
            history.push("/quick/item/edit");
            setSelectedFiles([]);
            setErrorMessage("");
            toastr.success(response.message
                // "File uploaded successfully"
                , "Success", {
                timeOut: 7000,
                progressBar: true,
                positionClass: "toast-top-right",
            });
        } catch (error) {
            console.log(error);
            toastr.error("Failed to upload file", "Error");
        }
    };

    const handleAcceptedFiles = useCallback((files) => {
        const updatedFiles = files.map((file) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setSelectedFiles(updatedFiles);
    }, []);

    const handleCancelFile = (index) => {
        const updatedFiles = selectedFiles.filter((_, i) => i !== index);
        setSelectedFiles(updatedFiles);
    };

    return (
        <>
            <Row>
                <div className="create_ticket_form">
                    <Label><h4>Upload CSV file</h4></Label>
                    <Form>
                        <Dropzone onDrop={handleAcceptedFiles} multiple={false}>
                            {({ getRootProps, getInputProps }) => (
                                <div className="dropzone">
                                    <div
                                        className="dz-message needsclick"
                                        {...getRootProps()}
                                    >
                                        <input {...getInputProps()} />
                                        <div className="mb-3">
                                            <i className="display-4 text-muted bx bxs-cloud-upload" />
                                        </div>
                                        <h4>Drop files here or click to upload.</h4>
                                    </div>
                                </div>
                            )}
                        </Dropzone>
                        {errorMessage && (
                            <div className="Error_message_for_upload_file">
                                <p>{errorMessage}</p>
                            </div>
                        )}

                        <div className="dropzone-previews mt-3" id="file-previews">
                            {selectedFiles.map((f, i) => (
                                <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete file-card"
                                    key={i + "-file"}
                                >
                                    <div className="p-2 file-container">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""
                                                    height="80"
                                                    className="avatar-sm rounded bg-light"
                                                    alt={f.name}
                                                    src={f.preview}
                                                />
                                            </Col>
                                            <Col>
                                                <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                >
                                                    {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                </p>
                                            </Col>
                                            <Col className="col-auto">
                                                <span
                                                    className="cancel-button"
                                                    onClick={() => handleCancelFile(i)}
                                                >
                                                    &#10006;
                                                </span>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            ))}
                        </div>
                    </Form>
                </div>
                <Button
                    className="w-100 mt-4 procces_Button"
                    style={{ fontSize: "18px" }}
                    onClick={handleSumbit}
                >
                    Upload
                </Button>
            </Row>

            <style jsx>{`
                .file-container {
                    position: relative;
                    padding: 10px;
                }
                .cancel-button {
                    color: red;
                    font-size: 18px;
                    cursor: pointer;
                    display: none;
                }
                .file-card:hover .cancel-button {
                    display: inline;
                }
            `}</style>
        </>
    );
};

export default UploadCSV;
