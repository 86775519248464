import React from "react";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

export const WhiteListURL = ({ mData }) => {
    const baseURL = `${window.location.protocol}//${window.location.host}/${mData?.result?.ReferenceURL}/add`;
    const openURL = () => {
        window.open(baseURL, '_blank');
    };
    
    const copyToClipboard = async () => {
        try {
            await navigator.clipboard.writeText(baseURL);
            toastr.success('URL copied to clipboard!');
        } catch (err) {
            toastr.error('Failed to copy URL!');
        }
    };

    return (
        <>
            <React.Fragment>
                <Card className="p-3">
                    <h4 className="personal_info_use_profile mb-2 mt-3 ms-4 title-color-common">
                        White list url
                    </h4>
                    <CardBody>
                        <Row>
                            <Col lg={12} md={12} sm={12} xs={12}>
                                <div className="reference-white-list-url">
                                    <p onClick={openURL}><strong >URL {" "} : {" "} </strong> {baseURL}</p>
                                    <Button color="primary" onClick={openURL} className="me-2">
                                        Open URL in New Tab
                                    </Button>
                                    <Button color="secondary" onClick={copyToClipboard}>
                                        Copy URL
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </React.Fragment>
        </>
    );
};
