import React, { useEffect, useState } from "react";
import { Card, Col, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import { QuickTableList } from "./TableList";
import { fQuickItems } from "Context/ContextApi";
import { useData } from "Context/ContextProvider";

const QuickList = () => {
    const {userDataa} = useData();
    const [data, setData] = useState([])

    useEffect(() => {
        handleFetch();
      }, [])
    
      const handleFetch = async () => {
        try {
          const response = await fQuickItems();
          setData(response.result)
        } catch (error) {
          console.log(error);
        }
      }

      console.log(data, "data");
      
    

    document.title = "GET Platform  | Quick List"
    return (
        <React.Fragment>
            <div className="page-content" style={{ marginLeft: "5%", marginRight: "5%" }}>
                <Breadcrumbs title="GET" breadcrumbItem="Quick list" />
                <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
                    <Row>
                        <Col className="ms-5 me-5 t">
                            <h2 className="text-center mt-5 mb-4 itle-color-common" style={{ fontFamily: "Gilroy" }}>
                                QUICK
                            </h2>
                            <p className="text-center heading_description mb-5 description-color">
                                Create quick items
                            </p>
                            <QuickTableList productData={data} userDataa={userDataa} setData={setData} />

                        </Col>
                    </Row>
                </Card>
            </div>
        </React.Fragment>
    );
};

export default QuickList;
