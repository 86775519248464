
// import React, { useEffect, useRef, useState } from "react";
// import { Col, Row, } from "reactstrap";
// import "flatpickr/dist/themes/material_blue.css";
// import Breadcrumbs from "components/Common/Breadcrumb";
// import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
// import { useData } from "Context/ContextProvider";

// const API_BASE_URL = process.env.REACT_APP_API_URL
// const Createquick = () => {
//     const { userDataa, mData, socketTransaction } = useData();

//     document.title = "GET Platform  | Create quick items";
//     return (
//         <React.Fragment>
//             <div className="page-content"
//                 style={{ marginLeft: "5%", marginRight: "5%" }}>
//                 <Breadcrumbs title="GET" breadcrumbItem="Transaction" />
//                 <Row>
//                     <Col md={{ offset: 3, size: 6, }}>
//                         <h2 className="text-center mt-5 mb-4 title-color-common" style={{ fontFamily: "Gilroy", }}>
//                             QUICK
//                         </h2>
//                     </Col>
//                 </Row>

//             </div>

//         </React.Fragment>
//     );
// };




// export default Createquick;


import React, { useState } from "react";
import { Card, Col, Row } from "reactstrap";
import Breadcrumbs from "components/Common/Breadcrumb";
import UploadCSV from "./upload.csv";
import DynamicTable from "./create-table";
import QuickManualTable from "./create-table";
// import Papa from "papaparse"; // This is a CSV parser for JavaScript

const Createquick = () => {
  const [selectedOption, setSelectedOption] = useState("");
  const [csvData, setCsvData] = useState([]);
  const [tableVisible, setTableVisible] = useState(false);

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
    if (e.target.value !== "csvUpload") {
      setCsvData([]);
      setTableVisible(false);
    }
  };

//   const handleFileUpload = (e) => {
//     const file = e.target.files[0];
//     if (file) {
//       Papa.parse(file, {
//         header: true,
//         complete: (result) => {
//           setCsvData(result.data);
//           setTableVisible(true);
//         },
//         skipEmptyLines: true,
//       });
//     }
//   };
 document.title = "GET Platform  | Quick Create"
  return (
    <React.Fragment>
      <div className="page-content" style={{ marginLeft: "5%", marginRight: "5%" }}>
        <Breadcrumbs title="GET" breadcrumbItem="Transaction" />
        <Card style={{ paddingTop: "50px", paddingBottom: "50px" }}>
        <Row>
          <Col md={{ offset: 1, size: 10 }}>
            <h2 className="text-center mt-5 mb-4 title-color-common" style={{ fontFamily: "Gilroy" }}>
              QUICK
            </h2>
            <p className="text-center heading_description mb-5 description-color">
              Create quick items
            </p>
            <div className="radio-buttons">
              <div className="d-flex gap-3">
                <input
                  type="radio"
                  id="csvUpload"
                  name="uploadOption"
                  value="csvUpload"
                  checked={selectedOption === "csvUpload"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="csvUpload">Upload CSV File</label>
              </div>
              <div className="d-flex gap-3">
                <input
                  type="radio"
                  id="otherOption"
                  name="uploadOption"
                  value="otherOption"
                  checked={selectedOption === "otherOption"}
                  onChange={handleOptionChange}
                />
                <label htmlFor="otherOption">Other Option</label>
              </div>
            </div>

            {selectedOption === "csvUpload" && (
              <UploadCSV />
            )}

            {selectedOption === "otherOption" && (
              <QuickManualTable />
            )}
          </Col>
        </Row>
        </Card>
      </div>
    </React.Fragment>
  );
};

export default Createquick;
