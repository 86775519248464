import React from 'react';
import logoImage from "../../assets/images/NewImage/getr-logo-black.png"

const CaptchaLoginFooter = () => {
    return (
        <div className='Login-footer-main-container' >
            <div className='Login-footer-main-content' >
                <a href="/get9878979798"><img src={logoImage} alt='image' /></a>
                <span>@2024 - 2025 GETransfer Pvt Ltd</span>
                <span>All rights reserved.</span>
            </div>
        <div className="PolicyFooter-main mt-3">
            <div className="d-flex gap-3 text-center">
                <h6> <a href="/privacy-policy">Privacy Policy</a></h6>
                <h6>|</h6>
                <h6><a href="/terms-condition">Terms & Conditions</a></h6>
            </div>
        </div>
        </div>
    );
};

export default CaptchaLoginFooter;