import React, { useState, useEffect, useRef } from "react";
import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import { Button } from "reactstrap";

const libraries = ["places"];

const containerStyle = {
    width: "100%",
    height: "400px",
};

const center = {
    lat: 12.8914639,
    lng: 77.6414856,
};

const markerIcon = {
    path: "M10.453 14.016l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM12 2.016q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "blue",
    fillOpacity: 0.9,
    scale: 1.5,
    strokeColor: "white",
    strokeWeight: 1,
};

function FetchGoogleLocation({ onLocationChange }) {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: "AIzaSyA4vPbZfXV95dWDKAellaSOc_2NT1FY7lg",
        libraries,
    });

    const [map, setMap] = useState(null);
    const [markerPosition, setMarkerPosition] = useState(center);
    const [address, setAddress] = useState("");
    const [latitude, setLatitude] = useState(center.lat);
    const [longitude, setLongitude] = useState(center.lng);

    const inputRef = useRef(null);

    useEffect(() => {
        if (isLoaded && inputRef.current) {
            const autocomplete = new window.google.maps.places.Autocomplete(inputRef.current);

            autocomplete.addListener("place_changed", () => {
                const place = autocomplete.getPlace();
                if (place.geometry) {
                    const newLat = place.geometry.location.lat();
                    const newLng = place.geometry.location.lng();

                    setMarkerPosition({ lat: newLat, lng: newLng });
                    setLatitude(newLat);
                    setLongitude(newLng);
                    setAddress(place.formatted_address || "");
                    if (onLocationChange) {
                        onLocationChange(newLat, newLng);
                    }
                }
            });
        }
    }, [isLoaded, onLocationChange]);

    const handleMarkerDragEnd = (event) => {
        const newLat = event.latLng.lat();
        const newLng = event.latLng.lng();
        setMarkerPosition({ lat: newLat, lng: newLng });
        setLatitude(newLat);
        setLongitude(newLng);
        if (onLocationChange) {
            onLocationChange(newLat, newLng);
        }
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({ location: { lat: newLat, lng: newLng } }, (results, status) => {
            if (status === "OK" && results[0]) {
                setAddress(results[0].formatted_address);
            }
        });
    };

    return isLoaded ? (
        <div className="mt-5">
            <div className="Registration_form_complete">
                <input
                    type="text"
                    placeholder="Search location"
                    ref={inputRef}
                    style={{ width: "100%", padding: "10px", marginBottom: "10px" }}
                />
            </div>

            <GoogleMap
                mapContainerStyle={containerStyle}
                center={markerPosition}
                zoom={15}
                onLoad={map => setMap(map)}
            >
                <Marker
                    position={markerPosition}
                    draggable={true}
                    onDragEnd={handleMarkerDragEnd}
                    icon={markerIcon}
                />
            </GoogleMap>

            <div className="google-location-address">
                <p>Address: {address}</p>
                <p>Latitude: {latitude}</p>
                <p>Longitude: {longitude}</p>
            </div>

            <Button onClick={() => setMarkerPosition(center)}>Clear</Button>
        </div>
    ) : (
        <></>
    );
}

export default FetchGoogleLocation;

