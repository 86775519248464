import React, { useState, useEffect, useCallback, useRef } from 'react';
import CryptoJS from 'crypto-js';
import { useData } from 'Context/ContextProvider';
import { getDeviceId } from 'components/Common/generateDeviceId';
import { fDeviceIdQrCode, merchanInformation } from 'Auth/ApiFunction';
import logosrc from "../../../assets/images/NewImage/Invoice-Logo.png";
import { io } from 'socket.io-client';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import Cookies from 'js-cookie';
import { messages } from 'common/data';

const API_BASE_URL = process.env.REACT_APP_API_URL;

const useIsElectron = () => {
    const [isElectron, setIsElectron] = useState(false);

    useEffect(() => {
        if (typeof window !== "undefined" && window.process && window.process.versions && window.process.versions.electron) {
            setIsElectron(true);
        }
    }, []);

    return isElectron;
};

const wordArrayToBase64 = (wordArray) => CryptoJS.enc.Base64.stringify(wordArray);
const base64ToWordArray = (base64String) => CryptoJS.enc.Base64.parse(base64String);

const encrypt = (text) => {
    const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
    return iv.toString(CryptoJS.enc.Base64) + ':' + encrypted.toString();
};

const decrypt = (encryptedData) => {
    const [ivString, encryptedText] = encryptedData.split(':');
    const iv = CryptoJS.enc.Base64.parse(ivString);
    const key = CryptoJS.enc.Utf8.parse(ENCRYPTION_KEY);
    const decrypted = CryptoJS.AES.decrypt(encryptedText, key, { iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 });
    return decrypted.toString(CryptoJS.enc.Utf8);
};

function NFCAndScan() {
    const { userInfo, iPAddress } = useData();
    const history = useHistory();
    const socketRef = useRef();
    const isElectron = useIsElectron();
    const [data, setData] = useState([])
    const [deviceId, setDeviceId] = useState('');
    const [isConnected, setIsConnected] = useState(false);
    const [counterID, setCounterId] = useState('');
    const date = new Date();

    useEffect(() => {
        fHandleDevice();
    }, [!deviceId]);

    const fHandleDevice = async () => {
        const deviceId = await getDeviceId();
        setDeviceId(deviceId);
        await handleFetchQRCode(deviceId);
    };

    const handleFetchQRCode = async (deviceId) => {
        try {
            const formData = {
                DeviceId: deviceId
            }
            const response = await fDeviceIdQrCode(formData)
            if (!response?.result || (Array.isArray(response.result) && response.result.length === 0)) {
                history.push("/add");
                return;
            }
            setData(response.result)
            setIsConnected(true)

        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (deviceId) {
            socketRef.current = io(API_BASE_URL, { query: { mDeviceId: deviceId }, });

            socketRef.current.on('connect', () => {
                // console.log('Connected to the socket with UserId:', deviceId);
            });

            socketRef.current.on("qrcodelogin", async (data) => {
                if (data && data.token) {
                    let appData = {
                        messages: "Logged in successfully.",
                        deviceId: deviceId,
                        SubAdminId: data?.result?.subResult?.id,
                        Counter: data.Counter,
                        DateTime: date,
                        MerchantId: data?.result?.result?.id
                    }

                    socketRef.current.emit("qrcodeloginSuccess", appData);

                    // console.log(data);
                    // return;

                    const expirationDate = new Date(new Date().getTime() + 12 * 60 * 60 * 1000);
                    const authUserData = {
                        token: data.token,
                        expires: expirationDate.toISOString(),
                        IPAddress: iPAddress,
                    };
                    Cookies.set("authUsers", JSON.stringify(authUserData), { expires: expirationDate });
                    localStorage.setItem("authUsers", JSON.stringify(data.token));
                    localStorage.setItem("PublicKey", data?.PublicKey);
                    let mdata = {
                        MerchantId: data?.result?.result?.id,
                        SubAdminId: data?.result?.subResult?.id,
                        Status: "ACTIVE",
                        LastInactiveDateTime: '',
                        DateTime: date,
                        DeviceId: deviceId,
                        Counter: data.Counter,
                        ...userInfo
                    };
                    await merchanInformation(mdata);
                    setTimeout(() => {
                        if (data.result?.result?.Register_Status === "COMPLETED") {
                            if (data.result?.result?.kycVerified === "ReVerify") {
                                history.push("/re-verify");
                            } else {
                                history.push("/get-dashboard");
                            }
                        } else {
                            history.push("/registration-form2");
                        }
                        window.location.reload();
                    }, 3000);

                }
            });

            socketRef.current.on('disconnect', () => {
                // console.log('Socket disconnected');
            });
            return () => {
                socketRef.current.disconnect();
            };
        }
    }, [deviceId]);

    return (
        <React.Fragment>
            <div className="Device-qrc-code">
                {!isConnected ? (
                    <div>
                        <h1>GETrx</h1>
                        <div style={{ margin: '20px 0' }}>
                            <img
                                src={logosrc}
                                style={{ maxWidth: '500px', borderRadius: '8px' }}
                            />
                        </div>
                        <h1>INITALIZING</h1>
                    </div>
                ) : (
                    <div>
                        <h1>TAP NFC / SCAN QR</h1>
                        <div className='nfcAndScan_container'>
                            {data.map((item, index) => (
                                <div style={{ margin: '20px 0' }} key={index}  >
                                    <h1>{item.CounterName}</h1>
                                    <img
                                        src={`${API_BASE_URL}/${item.QRCode}`}
                                        alt={`QR Code for ${counterID}`}
                                        style={{ maxWidth: '500px', borderRadius: '8px' }}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        </React.Fragment>
    );
}

export default NFCAndScan;