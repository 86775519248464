import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, FormGroup, Input, Label, Modal, Row } from "reactstrap";
import print from "../../../assets/images/NewImage/printing.png";
import success from "../../../assets/images/GET-logo.png";
import ticket from "../../../assets/images/NewImage/billet.png";
import PrintOut from "pages/DepositMoney/PrintOut";
import ReactToPrint from "react-to-print";
import { approveRefundAmount,  fMerchantPaymentRefundHistory,  refundTaxCalclations, sendEmailFromMerchant } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import backImg from "../../../assets/images/NewImage/back.png"
import cancelImage from "../../../assets/images/NewImage/cancel.png"
import infoImage from "../../../assets/images/NewImage/info.png"
import { TransactionPrint } from "pages/Transactions/TransactionPrint";
import html2pdf from 'html2pdf.js';
import { useData } from "Context/ContextProvider";
import RefundPaymentDetails from "pages/Payments/payment.status";
import Breadcrumbs from "components/Common/Breadcrumb";

const API_BASE_URL = process.env.REACT_APP_API_URL;
function RefundsPaymentDetails() {
    const { id } = useParams();
    const componentRef = useRef();
    const history = useHistory()
    const { iPAddress } = useData();
    const [showPrintUI, setShowPrintUI] = useState(false);
    const [currentStep, setCurrentStep] = useState(1);
    const [pin, setPin] = useState("")
    const [popupOpen, setPopupOpen] = useState(false)
    const [pinError, setPinError] = useState('');
    const [transactionDetails, setTransactionDetails] = useState({})
    const [netTotalAfterSuccess, setNetTotalAfterSuccess] = useState('')
    const [refndTransactionDetails, setRefndTransactionDetails] = useState({})
    const [isPinButton, setIsPinbutton] = useState(true)
    const [validationErrors, setValidationErrors] = useState({});
    let jsonParseData = refndTransactionDetails.Tax_Details ? JSON.parse(refndTransactionDetails.Tax_Details) : ""
    const [refundStatus, setRefundStatus] = useState(false)
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState('');
    const [data, setData] = useState({})
    const [merchantLogo, setMerchantLogo] = useState({})
    const [pdfContent, setPdfContent] = useState(null);
    const [refundDetails, setRefundDetails] = useState({})

    const handleBeforePrint = () => {
        setPdfContent(componentRef.current);
    };

    const handleEmailClick = async () => {
        try {
            setPdfContent(componentRef.current);
            const content = componentRef.current.innerHTML;
            const options = {
                filename: 'transaction.pdf',
                html2canvas: {},
                jsPDF: {}
            };
            const pdfBlob = await html2pdf().from(content).set(options).outputPdf('blob');
            const pdfFile = new File([pdfBlob], 'transaction.pdf', { type: 'application/pdf' });

            if (pdfFile) {
                const formData = new FormData();
                formData.append('VPA', refndTransactionDetails.Receiver_vpa);
                formData.append('pdf', pdfFile);
                const response = await sendEmailFromMerchant(formData);
                toastr.success(response.message)
            } else {
                console.log('No PDF content available to send');
            }
        } catch (error) {
            console.log(error);
        }
    }

    let parseTax = data.result ? data.result[1].Tax : ""
    let refundParseTax = data.rResult ? data.rResult[1].Tax : ""
    const [sendForm, setSendForm] = useState({
        amount: "",
        remarks: "",
    })

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSendForm({ ...sendForm, [name]: value });
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    useEffect(() => { if (id) { fetchPaymentTransactionDetails() } }, [id])

    const fetchPaymentTransactionDetails = async () => {
        try {
            const formData = {
                id: id
            }
            const response = await fMerchantPaymentRefundHistory(formData)
            setRefndTransactionDetails(response.result)
            setMerchantLogo(response.businessName)
            setRefundDetails(response.refundResponse)
        } catch (error) {
            console.log(error);
        }
    }

    const validateForm = () => {
        let errors = {};
        if (!sendForm.amount || isNaN(sendForm.amount) || sendForm.amount <= 0) {
            errors.amount = "Please enter a valid amount greater than zero.";
        }

        else if (refndTransactionDetails.Payment_type === "Payment Request" && parseFloat(sendForm.amount) > parseFloat(refndTransactionDetails.NetTotal)) {
            errors.amount = "Amount cannot be greater than the net total";
        }
        else if (refndTransactionDetails.Payment_type === "Merchant Transfer" && parseFloat(sendForm.amount) > parseFloat(refndTransactionDetails.Amount - refndTransactionDetails?.DiscountAmount)) {
            errors.amount = "Amount cannot be greater than the Amount";
        }
        if (!sendForm.remarks) {
            errors.remarks = "Enter Transaction description";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleProceed = nextStep => {
        if (nextStep >= 1 && nextStep <= 3 && validateForm()) {
            setCurrentStep(nextStep);
        } else if (nextStep === 0) {
            setCurrentStep(1);
        }
    };

    const handlePopup = () => {
        setPopupOpen(true)
    }

    const handleCheckAndDeposit = () => {
        if (!pin || isNaN(pin) || pin.length !== 4) {
            setPinError('PIN must be a 4-digit number.');
            return;
        }
        setPinError('');
        walletToBankTransafer()
    }

    const walletToBankTransafer = async () => {
        try {
            setIsPinbutton(false)
            const formData = {
                Amount: sendForm.amount,
                Description: sendForm.remarks,
                id: refndTransactionDetails?.id,
                Pin: pin,
                IPAddress: iPAddress,
                RefundRequestId: refundDetails.id
            }
            const response = await approveRefundAmount(formData)
            if (response.status === "success") {
                handleProceed(currentStep + 1);
                setPopupOpen(false)
                toastr.success(response.message)
                setTransactionDetails(response.transactionDetails)
                setSendForm({
                    amount: "",
                    remarks: "",
                })
                history.push("/payments")
            } else if (response.status === 'failed') {
                toastr.warning(response.message)
                setIsPinbutton(true)
            }
            setPin("")
            setNetTotalAfterSuccess(response.netTotal)
        } catch (error) {
            const errorMessage = error?.response?.data?.message || error.message;
            toastr.warning(errorMessage)
            setPin("")
            setIsPinbutton(true)
        }
    }

    const handleResertForm = () => {
        setSendForm({
            amount: "",
            remarks: "",
        })
    }

    const fecthTaxesFrombackend = async () => {
        try {
            const formData = {
                Amount: parseFloat(sendForm.amount),
                Module: 'f08fbe27-7333-4b61-9dcb-30b21ea8ce26',
                Module_Type: '901a9c66-f904-4241-91b6-04c3453374c9',
                Nature: 'BUSINESS',
                Source: "WALLET"
            }
            const response = await refundTaxCalclations(formData)
            setData(response)
        } catch (error) {
            console.log(error);
        }
    }

    const handleBack = () => {
        history.goBack()
    }

    const handleShowPopup = (title, content) => {
        setPopupData(content);
        setShowPopup(true);
    };

    const handleClosePopup = () => {
        setShowPopup(false);
    };

    document.title = "GET Platform  | Details"

    const parseDescription = refndTransactionDetails?.Description ? JSON.parse(refndTransactionDetails?.Description) : '';

    return (
        <>
            {showPopup && (
                <div className="transaction_popup_main">
                    <div className="Refund-popup-content">
                        <span className="close" onClick={handleClosePopup}>
                            &times;
                        </span>
                        <div className="offers-main-container  ">
                            <div className="offer-module-text">
                                <span>Offer Details </span>
                                <span>
                                    <img src={cancelImage} alt="cancel" onClick={() => setShowPopup(false)} />
                                </span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Offers Name</span>
                                <span>{popupData.OffersName}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Type</span>
                                <span>{popupData.Type}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Method</span>
                                <span>{popupData.Method}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Start Date</span>
                                <span>{new Date(popupData?.StartDate).toLocaleString()}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>End Date</span>
                                <span>{new Date(popupData?.EndDate).toLocaleString()}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Discount Percentage</span>
                                <span>% {popupData.DiscountPercentage} </span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Allowed Maximum Discount</span>
                                <span>{refndTransactionDetails.Currency} {popupData.AllowedMaxDiscount}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Create On</span>
                                <span>{new Date(popupData?.TimeStamp).toLocaleString()}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {showPrintUI && <PrintOut onClose={() => setShowPrintUI(false)} />}
            {!showPrintUI && (
                <React.Fragment>
                    <Modal
                        isOpen={popupOpen}
                        role="dialog"
                        autoFocus={true}
                        centered
                    >
                        <div className="modal-content">
                            <div className="modal-header border-bottom-0">
                                <button
                                    type="button"
                                    className="btn-close"
                                    onClick={() => setPopupOpen(false)}
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                ></button>
                            </div>
                            <div className="modal-body">
                                <div className="text-center mb-4">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-8">
                                            <h4 className="model_heading_text title-color-common">Verify Your pin</h4>
                                            <FormGroup>
                                                <Label for="pin" className="generate_pin_label">PIN:</Label>
                                                <Input type="password" name="pin" id="pin" placeholder="Enter your pin"
                                                    className="css_for_generatePin_input" min="0"
                                                    value={pin} onChange={(e) => setPin(e.target.value)} />
                                                {pinError && <small className="text-danger">{pinError}</small>}
                                            </FormGroup>
                                            {isPinButton && (
                                                <Button className="model_confirm_button procces_Button" type="button" onClick={handleCheckAndDeposit}>
                                                    Confirm
                                                </Button>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                    <div
                        className="page-content"
                        style={{ marginLeft: "5%", marginRight: "5%" }}
                    >
                        <div className="container-fluid">
                            <Breadcrumbs title="GET" breadcrumbItem="Refund Details" />
                            <Container className="mt-5">
                                <Card className="mb-5 pb-5" >
                                    <Row>
                                        <Col lg={8}>
                                            <div className="p-3 curser-back" onClick={handleBack} >
                                                <img src={backImg} alt="image" width="40" />
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            {currentStep === 1 && (<>
                                                <div className="Refund_payment_buttons">
                                                    <ReactToPrint
                                                        trigger={() => (
                                                            <Button type="button">
                                                                Print
                                                            </Button>
                                                        )}
                                                        content={() => componentRef.current}
                                                        onBeforeGetContent={handleBeforePrint}
                                                        filename='transaction.pdf'
                                                    />

                                                    <Button onClick={handleEmailClick}>Email</Button>
                                                    {refndTransactionDetails.Refund_Status === 'false' && refndTransactionDetails?.Status === "completed" &&
                                                        (refndTransactionDetails.Payment_type === 'Payment Request' || refndTransactionDetails.Payment_type === "Merchant Transfer") && (
                                                            <Button onClick={() => setRefundStatus(!refundStatus)}>Refund</Button>
                                                        )}
                                                </div>
                                            </>
                                            )}
                                        </Col>
                                        <Col md={{ offset: 3, size: 6, }}>
                                            {currentStep === 2 && (
                                                <h4 className="text-center after_heading_description_In_progress title-color-common">Confirm your transfer</h4>
                                            )}
                                            {currentStep === 3 && (
                                                <h4 className="text-center after_heading_description_In_progress title-color-common">Money transfer complete</h4>
                                            )}
                                        </Col>
                                    </Row>
                                    {currentStep === 1 && (
                                        <Row>
                                            <Col md={{ offset: 3, size: 6, }}>
                                                <div className="refund_payment_details_header">
                                                    <h3>{refndTransactionDetails.sender_name ? refndTransactionDetails.sender_name : refndTransactionDetails.Sender_vpa}</h3>
                                                    <h3 className="refund_payment_details_Amount"> {refndTransactionDetails.Currency} {refndTransactionDetails?.NetPaid}</h3>
                                                    <h3>{new Date(refndTransactionDetails?.Transaction_date).toLocaleString()}</h3>
                                                    <h3>{refndTransactionDetails.Payment_type}</h3>
                                                    <RefundPaymentDetails refndTransactionDetails={refndTransactionDetails} />
                                                    <h3>{refndTransactionDetails.Sender_vpa}</h3>
                                                </div>
                                            </Col>
                                            <Col md={{ offset: 3, size: 6, }}>
                                                <Row>
                                                    <Col sm="12" xs="12" className="">
                                                        <div className="refund_payment_details mb-0">
                                                            <h2 > Transaction details</h2>
                                                            <h3> UTR : {refndTransactionDetails?.UTR}</h3>
                                                            <h3> VAT : {merchantLogo?.VAT}</h3>
                                                        </div>
                                                    </Col>
                                                    <Col sm="12" xs="12" className="">
                                                        <div className="payment_details_information mb-3">
                                                            <h2 className="title-color-common">Information</h2>
                                                            {parseDescription?.Description && <><h3> Description</h3>  <p>{parseDescription?.Description}</p></>}
                                                            {parseDescription?.last4Digits && <> <h3>Provided Card </h3> <p>**** **** **** {parseDescription?.last4Digits}</p>
                                                            </>}
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <div className="deposit_money_secondStep_form">
                                                    {Array.isArray(jsonParseData) && jsonParseData.map((item, index) => (
                                                        <div key={index}>
                                                            <div className={`tax-details-transacrtion-main-container ${item.TotalTitle === 'Net Total' || item.TotalTitle === 'Net Refund' ||
                                                                item.Title === 'Refund Net Tax' || item.Title === 'Net Tax' || item.TotalTitle === 'Refund Net Total' ? ' deposite_money_second_step_text_border_line' : ''}`}>
                                                                {item.Title ? (
                                                                    <span>{item.Title}
                                                                        {item.Title === "Offer Applied" && (
                                                                            <img src={infoImage} onClick={() => handleShowPopup("Offer Details", (jsonParseData.find(item => item.OfferDetails === 'Offer Details').Value))} className="info-icon-for-offers" />
                                                                        )}
                                                                    </span>
                                                                ) : (
                                                                    item.TotalTitle && <span>{item.TotalTitle} </span>
                                                                )}
                                                                {(typeof item.Value !== 'object' && item.Value !== undefined) && <div className="tax-details-Amount"><span>{refndTransactionDetails.Currency}</span> <span>{parseFloat(item.Value).toFixed(2)}</span></div>}

                                                            </div>
                                                            {Array.isArray(item.Tax) && item.Tax.map((taxItem, taxIndex) => (
                                                                <div key={taxIndex} className="transaction_details ps-4">
                                                                    <div className="transaction_detailsForTaxDetals">
                                                                        <div className="transaction_detail_refund">
                                                                            <div className="d-flex gap-2">
                                                                                <label>{taxItem.Particular}</label>
                                                                                {taxItem.Percentage && <small>{taxItem.Percentage} %</small>}
                                                                            </div>
                                                                            <div>
                                                                                {taxItem.RefundRate && <small>Refund Rate ({taxItem.RefundRate} %)</small>}
                                                                            </div>
                                                                        </div>

                                                                        <div className="transaction_detail_Amount">
                                                                            <span>{refndTransactionDetails.Currency} {parseFloat(taxItem.NetTotal).toFixed(2)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                        </div>
                                                    ))}
                                                </div>
                                            </Col>
                                            <Col md={{ offset: 3, size: 6, }} >
                                                <div className="refund-images">
                                                    <img src={refundDetails?.Image1 ? `${API_BASE_URL}/${refundDetails?.Image1}` : infoImage} alt="" />
                                                    <img src={refundDetails?.Image2 ? `${API_BASE_URL}/${refundDetails?.Image2}` : infoImage} alt="" />
                                                </div>
                                                <p className="refund-remarks">{refundDetails?.Remarks}</p>
                                            </Col>
                                            <Col md={{ offset: 3, size: 6, }} >
                                                {refundStatus && (
                                                    <div className="form_gruap_for_deposit">
                                                        <Col md="12" sm="12">
                                                            <div className="form_group_for_inside_in_deposit">
                                                                <label>Amount</label>
                                                                <input type="number" placeholder="Enter amount"
                                                                    min="0" name="amount" value={sendForm.amount} onChange={handleChange} />
                                                                {validationErrors.amount && (
                                                                    <span className="error-message">{validationErrors.amount}</span>
                                                                )}
                                                            </div>
                                                        </Col>

                                                        <div className="form_group_for_inside_in_deposit">
                                                            <label>Transaction description</label>
                                                            <textarea type="text" placeholder="Enter Transaction description" name="remarks" value={sendForm.remarks} onChange={handleChange} />
                                                            {validationErrors.remarks && (
                                                                <span className="error-message">{validationErrors.remarks}</span>
                                                            )}
                                                        </div>
                                                        <Button
                                                            className="procces_Button"
                                                            onClick={() => { handleProceed(currentStep + 1), fecthTaxesFrombackend() }}
                                                        >
                                                            Proceed
                                                        </Button>
                                                    </div>
                                                )}
                                            </Col>
                                        </Row>
                                    )}
                                    {currentStep === 2 && (
                                        <Row>
                                            <Col
                                                md={{
                                                    offset: 3,
                                                    size: 6,
                                                }}
                                            >
                                                <div className="deposit_money_secondStep_form">
                                                    <h5 className="text-center" style={{ color: "#db9c45" }}>
                                                        {/* Recipient */}
                                                    </h5>
                                                    <h4 className="text-center">{sendForm.userName}</h4>
                                                    {refundParseTax && (
                                                        <div className="deposit_money_secondStep_form">
                                                            <div className="deposite_money_second_step_text  ">
                                                                <span>REFUND </span>
                                                            </div>
                                                            <div className="deposite_money_second_step_text  ">
                                                                <span>REQUESTED </span>
                                                                <span>{refndTransactionDetails.Currency} {parseFloat(data?.rResult[0]?.Value)}</span>
                                                            </div>
                                                            <div className="deposite_money_second_step_text  ">
                                                                <span>Tax </span>
                                                            </div>
                                                            {refundParseTax?.map((item, index) => (
                                                                <div className="transaction_details ps-4" key={index}>
                                                                    <div className="transaction_detailsForTaxDetals">
                                                                        <div className="transaction_detail_Title_name">
                                                                            <label>{item.Particular}</label>
                                                                            {item.Percentage && (
                                                                                <small>{item.Percentage} %</small>
                                                                            )}
                                                                        </div>
                                                                        <div className="transaction_detail_Amount">
                                                                            <span>{refndTransactionDetails.Currency} {parseFloat(item.NetTotal).toFixed(2)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="transaction_details deposite_money_second_step_text_border_line ">
                                                                <span>Net Tax </span>
                                                                <span>{refndTransactionDetails.Currency} {parseFloat(data?.rResult[2].Value)}</span>
                                                            </div>
                                                            <div className="transaction_details deposite_money_second_step_text_border_line">
                                                                <span>Net Total </span>
                                                                <span>{refndTransactionDetails.Currency} {parseFloat(data?.rResult[3]?.Value)}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    {parseTax && (
                                                        <div className="deposit_money_secondStep_form mt-3">
                                                            <div className="deposite_money_second_step_text  ">
                                                                <span>Tax </span>
                                                            </div>
                                                            {parseTax?.map((item, index) => (
                                                                <div className="transaction_details ps-4" key={index}>
                                                                    <div className="transaction_detailsForTaxDetals">
                                                                        <div className="transaction_detail_Title_name">
                                                                            <label>{item.Particular}</label>
                                                                            {item.Percentage && (
                                                                                <small>{item.Percentage} %</small>
                                                                            )}
                                                                        </div>
                                                                        <div className="transaction_detail_Amount">
                                                                            <span>{refndTransactionDetails.Currency} {parseFloat(item.NetTotal).toFixed(2)}</span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            ))}
                                                            <div className="transaction_details deposite_money_second_step_text_border_line ">
                                                                <span>Net Tax </span>
                                                                <span>{refndTransactionDetails.Currency} {parseFloat(data?.result[2].Value)}</span>
                                                            </div>
                                                            <div className="transaction_details deposite_money_second_step_text_border_line">
                                                                <span>Net Total </span>
                                                                <span>{refndTransactionDetails.Currency} {parseFloat(data?.rResult[3]?.Value - data?.result[2].Value).toFixed(2)}</span>
                                                            </div>
                                                        </div>
                                                    )}
                                                    <Button className="procces_Button mt-3" onClick={() => { handlePopup() }}>
                                                        Confirm & Deposit
                                                    </Button>
                                                    <span
                                                        className="text-center"
                                                        onClick={() => handleProceed(currentStep - 1)}
                                                    >
                                                        Back
                                                    </span>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                    {currentStep === 3 && (
                                        <Row>
                                            <Col
                                                md={{
                                                    offset: 3,
                                                    size: 6,
                                                }}
                                            >
                                                <div className="deposite_money_step_three_main">
                                                    <img
                                                        src={success}
                                                        alt="success"
                                                        className="success_image"
                                                    />
                                                    <h5 className="text-center">Success!</h5>
                                                    <p className="text-center">
                                                        Money has been transferred successfully.
                                                    </p>
                                                    <div className="send_money_step_three_box_main">
                                                        <img
                                                            src={ticket}
                                                            alt="icon"
                                                            className="avatar-md rounded-circle img-thumbnail"
                                                        />
                                                        <div className="send_money_step_three_box">
                                                            <h4>{sendForm.userName}</h4>
                                                            <h6>Transferred amount</h6>
                                                            <h2>{refndTransactionDetails.Currency} {parseFloat(netTotalAfterSuccess)}</h2>
                                                        </div>
                                                    </div>
                                                    <p className="text-center">
                                                        The recipient will be notified via an email or phone
                                                        number after money has been successfully transferred
                                                        to their account.
                                                    </p>
                                                    <div className="deposit_monet_step_three_button">
                                                        <ReactToPrint
                                                            trigger={() => (
                                                                <Button type="button" className="deposit_monet_step_three_print procces_Button">
                                                                    <img src={print} alt="print" />
                                                                    Print code
                                                                </Button>
                                                            )}
                                                            content={() => componentRef.current}
                                                        />
                                                        <Button
                                                            className="deposit_monet_step_three_deposit_again"
                                                            onClick={() => { handleProceed(0), handleResertForm() }}
                                                        >
                                                            Send again
                                                        </Button>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    )}
                                </Card>
                            </Container>
                        </div>
                    </div>
                </React.Fragment>)}
            <ReactToPrint content={() => componentRef.current} fileName="transaction.pdf" />
            <div style={{ display: "none" }}>
                <TransactionPrint ref={componentRef} transactionDetails={refndTransactionDetails} merchantLogo={merchantLogo} />
            </div>
        </>
    );
}
export default RefundsPaymentDetails;
