import { changeDeliveryStatus } from 'Context/ContextApi';
import React, { useState, useEffect } from 'react';
import { Button, Input, Modal } from 'reactstrap';
import toastr from "toastr";
import "toastr/build/toastr.min.css"

const DeliveryTracking = ({ data, setData }) => {
    const [currentStep, setCurrentStep] = useState(data?.Status);
    const [showPopup, setShowPopup] = useState(false);
    const [estimatedTime, setEstimatedTime] = useState('');
    const [status, setStatus] = useState('')

    const steps = [
        { label: 'Initaite', status: 'Initaite' },
        { label: 'Requested', status: 'Requested' },
        { label: 'In Progress', status: 'In Progress' },
        currentStep === 'Cancelled'
            ? { label: 'Cancelled', status: 'Cancelled' }
            : { label: 'Dispatched', status: 'Dispatched' },
        { label: 'Completed', status: 'Completed' },

    ];

    useEffect(() => {
        if (data?.Status) {
            setCurrentStep(data.Status);
        }
    }, [data]);

    const getStatusClass = (stepStatus) => {
        const currentIndex = steps.findIndex(step => step.status === currentStep);
        const stepIndex = steps.findIndex(step => step.status === stepStatus);

        //     if (steps.findIndex(step => step.status === stepStatus) < steps.findIndex(step => step.status === currentStep)) {
        //         return 'completed';
        //     }

        // if (stepStatus === currentStep) {
        //     return 'current';
        // }

        if (stepIndex < currentIndex) {
            return 'completed';
        }
        if (stepStatus === currentStep) {
            return 'current';
        }
        return 'upcoming';
    };



    const handleSubmitEstimatedTime = async () => {
        if (!estimatedTime) {
            toastr.warning('Enter estimated time');
            return;
        }
        setShowPopup(false);
        await handleChange();
    };

    const handleChangeTheStatus = async (item) => {
        setStatus(item)
        if (currentStep === 'Cancelled' || currentStep === 'Completed') {
            toastr.warning('This process is already Completed or Cancelled.');
            return;
        }
        if (item === 'Initiate' || item === 'Requested' || item === 'In Progress' || item === 'Completed') {
            toastr.warning('You cannot change to this step directly.');
            return;
        }
        if (item === 'Dispatched') {
            if (data.Status === 'In Progress') {
                setShowPopup(true);
                return;
            } else {
                toastr.warning('You cannot change to this step directly.');
            }
        } else {
            await handleChange();
        }
    }

    const handleChange = async () => {
        try {
            const formData = {
                id: data.id,
                Status: status,
                UserId: data.UserId,
                EstimatedTime: estimatedTime
            }
            const response = await changeDeliveryStatus(formData)
            toastr.success(response.message)
            setCurrentStep(item)
            setData(response.result)
            setShowPopup(false)
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message)
            console.log(error.response);

        }
    }

    return (
        <>
            <div className="tracking-container">
                <div className="tracking-progress-bar">
                    {steps.map((step, index) => (
                        <div key={index} className={`tracking-step ${getStatusClass(step.status)}`}>
                            <div className="circle" onClick={() => handleChangeTheStatus(step.status)}></div>
                            <p className="tracking-step-label">{step.label}</p>
                        </div>
                    ))}
                </div>
            </div>
            <Modal isOpen={showPopup} role="dialog" autoFocus={true} centered>
                <div className="modal-content text-center">
                    <div className="isDEfault_popup_content">
                        <h4>Estimate Delivery Time</h4>
                        <div className="form_group_for_inside_in_deposit mt-3">
                            <Input
                                type="datetime-local"
                                value={estimatedTime}
                                onChange={(e) => setEstimatedTime(e.target.value)}
                                placeholder="Enter estimated time (e.g., 2 hours)"
                            />
                        </div>
                        <div className='d-flex justify-content-around mt-4'>
                            <Button onClick={() => setShowPopup(false)}>Cancel</Button>
                            <Button onClick={handleSubmitEstimatedTime} className='procces_Button'>Submit</Button>
                        </div>
                    </div>
                </div>
            </Modal>
        </>
    );
};

export default DeliveryTracking;
