import React, { useEffect, useState } from "react";
import { Row, Col, Modal, ModalHeader, ModalBody, ModalFooter, Button } from "reactstrap";
const API_BASE_URL = process.env.REACT_APP_API_URL;

export const QuickQRViewModal = ({ isOpen, qrCode, data, userDataa, handleCancel }) => {
    const qrCodeUrl = `${API_BASE_URL}/${userDataa?.id}/quickQRC/${qrCode}`;

    const handleDownload = () => {
        const link = document.createElement('a');
        link.href = qrCodeUrl;
        link.download = `${data.QRCode}.png`;
        link.click();
    };

    const handlePrint = () => {
        const printWindow = window.open('', '_blank');
        printWindow.document.write(`
            <html>
                <head>
                    <title>Print QR Code</title>
                    <style>
                        @media print {
                            body { text-align: center; }
                            .print-content {
                                display: block;
                                margin: 0 auto;
                            }
                        }
                    </style>
                </head>
                <body>
                    <h2>QR Code for ${userDataa?.BusinessName}</h2>
                    <div class="print-content">
                        <img src="${qrCodeUrl}" alt="QR Code" />
                    </div>
                    <p>Below is the QR code for user ${userDataa?.BusinessName}. Scan to access the information.</p>
                </body>
            </html>
        `);
        printWindow.document.close();
        printWindow.focus();
        printWindow.print();
        printWindow.close();
    };

    return (
        <React.Fragment>
            <Modal fullscreen='lg' center isOpen={isOpen} toggle={handleCancel}>
                <ModalHeader toggle={handleCancel}>QR Code</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col>
                            <h5>QR Code for {userDataa?.BusinessName}</h5>
                            <img className="img-fluid" src={qrCodeUrl} alt="QR Code" />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={handleDownload}>Download</Button>
                    <Button color="secondary" onClick={handlePrint}>Print</Button>
                    <Button color="danger" onClick={handleCancel}>Close</Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

