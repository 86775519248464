
import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Row,
  InputGroup,
} from "reactstrap";
import "./TransactionStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import Breadcrumbs from "components/Common/Breadcrumb";
import { getAllTransactionHistory } from "Context/ContextApi";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import ReactToPrint from "react-to-print";
import { TransactionPrint } from "./TransactionPrint";
import "jspdf-autotable";
import { useData } from "Context/ContextProvider";
import arrowicon from "../../assets/images/NewImage/skip-track.png"
import { Link } from "react-router-dom/cjs/react-router-dom.min";

const CURRENCY = process.env.CURRENCY;
const API_BASE_URL = process.env.REACT_APP_API_URL
const Transaction = () => {
  const ref = useRef()
  const { userDataa, mData , socketTransaction} = useData();
  const [transactionData, setTransactionData] = useState([])
  const [status, setStatus] = useState("")
  const [payment_type, setPayment_type] = useState("")
  const [currency, setCurrency] = useState("ZiG")
  const [dateRange, setDateRange] = useState([]);
  const [popupData, setPopupData] = useState('')
  const [businesName, setBusinesName] = useState({})
  const [actionData, setActionData] = useState({})
  const [rowHighlights, setRowHighlights] = useState(false)
  useEffect(() => {
    if (socketTransaction) {
      setTransactionData(prev => [...socketTransaction, ...prev]);
      setRowHighlights(true);
      setTimeout(() => {
        setRowHighlights(false);
      }, 3000);
    }
  }, [socketTransaction])


  const sortedProducts = [...transactionData];
  sortedProducts.sort((a, b) => a.Status.localeCompare(b.Status));
  const handleDateChange = (selectedDates) => {
    setDateRange(selectedDates);
  };

  useEffect(() => {
    let jsonparse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
    setActionData(jsonparse)
  }, [mData?.subResult?.Permissions])


  useEffect(() => {
    fetchTheMerchantTransacrionHistory(status, payment_type)
  }, [userDataa])

  
  // useEffect(() => {
  //   if (userDataa?.id && userDataa?.id > 0) {
  //     const socket = io(`${API_BASE_URL}`);
  //     const userId = userDataa?.id;
  //     socket.emit('userConnected', userId);
  //     socket.on('vaultTransaction', (data) => {
  //       setTransactionData(prev => [...data, ...prev]);
  //     });
  //     return () => {
  //       socket.disconnect();
  //     };
  //   }
  // }, [userDataa?.id]);

  const fetchTheMerchantTransacrionHistory = async (Status, PaymentType) => {
    try {
      const formData = {
        payment_type: PaymentType,
        status: Status
      }
      const response = await getAllTransactionHistory(formData)
      setTransactionData(response.result)
      setBusinesName(response.businessName)
    } catch (error) {
      console.log(error);
    }
  }

  const handleReset = () => {
    setCurrency("")
    setPayment_type("")
    setStatus("")
    fetchTheMerchantTransacrionHistory(status, payment_type)
  }
  const columns = [
    {
      dataField: "Transaction_date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "UTR",
      text: "UTR",
      sort: true,
    },
    {
      dataField: "sender_name",
      text: "Payer",
      sort: true,
    },
    {
      dataField: "Sender_vpa",
      text: "Payer VPA",
      sort: true
    },
    {
      dataField: "NetPaid",
      text: " Net Paid",
      sort: true,
    },
    {
      dataField: "Payment_type",
      text: "Payment Type",
      sort: true,
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{
            color: row.Status === "completed" ? "green" : "red",
            fontFamily: "Gilroy",
            fontWeight: "600",
            fontSize: "18px",
            textTransform: "uppercase"
          }}
        >
          {cell}
        </span>
      ),
    },
    {
      dataField: "",
      text: "Details",
      formatter: (cell, row) => (
        <div>
          <Link to={`/payment-details/${row?.id}`}>
            <p
              className="Details_popup"
            // onClick={() => togglePopup(row)}
            >
              <img src={arrowicon} alt="icon" />
            </p>
          </Link>
        </div>
      ),
    }
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: transactionData.length,
    custom: true,
  };

  const { SearchBar } = Search;
  const componentRef = useRef();
   document.title = "GET Platform  | Transactions"

  return (
    <React.Fragment>
      <div className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}>
        <Breadcrumbs title="GET" breadcrumbItem="Transaction" />
        <Row>
          <Col
            md={{
              offset: 3,
              size: 6,
            }}
          >
            <h2
              className="text-center mt-5 mb-4 title-color-common"
              style={{ fontFamily: "Gilroy",  }}
            >
              TRANSACTIONS
            </h2>
            <p className="text-center heading_description mb-5 description-color">
              History of all transactions in your account
            </p>
          </Col>
        </Row>
        <Row>
          <Col lg="12" md="12" sm="12" xs="12">
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
              }}
              className="mb-4"
            >
              <div style={{ width: "80%", display: "flex", gap: "10px", flexWrap: "wrap" }}>
                <div className="filtter_Date_range">
                  <InputGroup >
                    <Flatpickr
                      className="date_picker"
                      placeholder="Select date range"
                      options={{
                        mode: "range",
                        dateFormat: "Y-m-d",
                      }}
                      onChange={handleDateChange}
                    />
                  </InputGroup>
                </div>
                <select className="filtter_select_box" name="status" value={status}
                  onChange={(e) => {
                    const selectedStatus = e.target.value;
                    setStatus(selectedStatus);
                    fetchTheMerchantTransacrionHistory(selectedStatus, payment_type);
                  }}
                >
                  <option defaultValue hidden>
                    Status
                  </option>
                  <option value="">All</option>
                  <option value="completed">Successfull</option>
                  <option value="pending"> Pending</option>
                  <option value="Rejected"> Rejected</option>
                  <option value="failed">Failed</option>
                </select>
                <select className="filtter_select_box" name="payment_type" value={payment_type} onChange={(e) => {
                  const selectedPaymentType = e.target.value;
                  setPayment_type(selectedPaymentType);
                  fetchTheMerchantTransacrionHistory(status, selectedPaymentType);
                }}>
                  <option defaultValue hidden>
                    Type
                  </option>
                  <option value="">All</option>
                  <option value="personal transfer">Personal </option>
                  <option value="merchant transfer"> Merchant </option>
                  <option value="topup">Topup</option>
                  <option value="GetMoney">Request</option>
                  <option value="bankwithdraw">Withdraw</option>
                </select>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  gap: "20px",
                }}
              >
                <span style={{ fontSize: "16px", fontFamily: "Gilroy" }} onClick={handleReset}>
                  Reset
                </span>
                <Button
                  type="submit"
                  style={{ fontSize: "16px", fontFamily: "Gilroy" }}
                  onClick={fetchTheMerchantTransacrionHistory}
                  className="procces_Button"
                >
                  Apply filter
                </Button>


              </div>
            </div>
            <Card className="mt-5">
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions)}
                  keyField="id"
                  columns={columns}
                  data={transactionData}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      columns={columns}
                      data={transactionData}
                      search
                      exportCSV
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row className="mb-2">
                            <Col md="4">
                              <div className="search-box me-2 mb-2 d-inline-block">
                                <div className="position-relative">
                                  <SearchBar {...toolkitProps.searchProps} />
                                  <i className="bx bx-search-alt search-icon" />
                                </div>
                              </div>
                            </Col>
                          </Row>

                          <Row>
                            <Col xl="12">
                              <div className="table-responsive">
                                <BootstrapTable

                                  // id="my-table"
                                  keyField={"id"}
                                  responsive
                                  bordered={false}
                                  striped={false}
                                  defaultSorted={defaultSorted}
                                  //   selectRow={selectRow}
                                  // classes={
                                  //   "table align-middle table-nowrap"
                                  // }
                                  classes={` table align-middle table-nowrap  ${rowHighlights ? "custom-table" : ""}`}
                                  headerWrapperClasses={"thead-light"}
                                  {...toolkitProps.baseProps}
                                  {...paginationTableProps}
                                  style={{
                                    fontWeight: "Gilroy",
                                  }}
                                  headerClasses={"custom-header-class"}
                                  rowStyle={{
                                    fontSize: "15px",
                                    fontFamily: "Gilroy",
                                    fontWeight: "400",
                                  }}
                                />
                              </div>
                            </Col>
                          </Row>

                          <Row className="align-items-md-center mt-30">
                            <Col className="inner-custom-pagination d-flex">
                              <div className="d-inline">
                                <SizePerPageDropdownStandalone
                                  {...paginationProps}
                                />
                              </div>
                              <div className="text-md-right ms-auto">
                                <PaginationListStandalone
                                  {...paginationProps}
                                />
                              </div>
                            </Col>
                          </Row>
                        </React.Fragment>

                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
      <ReactToPrint content={() => componentRef.current} />
      <div style={{ display: "none" }}>
        <TransactionPrint ref={componentRef} transactionDetails={popupData} />
      </div>

    </React.Fragment>
  );
};




export default Transaction;
