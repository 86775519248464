import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt';


const key = process.env.REACT_APP_GET_APP_LAB_KEY;
const iv = process.env.REACT_APP_IV;

export const encryptData = (data) => {
    try {
        const ciphertext = CryptoJS.AES.encrypt(data, key, {
            iv: CryptoJS.enc.Hex.parse(iv),
            mode: CryptoJS.mode.CBC,
            padding: CryptoJS.pad.Pkcs7,
        }).toString()
        return ciphertext;
    } catch (error) {
        console.error("Error encrypting data:", error);
        throw error;
    }
};


export const encryptDataPublicKey = async (publicKeyPem, aesKey) => {
    const key = await crypto.subtle.importKey(
        'spki',
        convertPemToBinary(publicKeyPem),
        {
            name: 'RSA-OAEP',
            hash: { name: 'SHA-256' },
        },
        false,
        ['encrypt']
    );

    const encryptedKeyBuffer = await crypto.subtle.encrypt(
        {
            name: 'RSA-OAEP',
        },
        key,
        new TextEncoder().encode(aesKey)
    );

    return btoa(String.fromCharCode(...new Uint8Array(encryptedKeyBuffer)));
};

const convertPemToBinary = (pem) => {
    const lines = pem.split('\n').filter(line => line.trim() && !line.startsWith('-----'));
    const binaryString = lines.join('');
    const binary = atob(binaryString);
    const len = binary.length;
    const bytes = new Uint8Array(len);
    for (let i = 0; i < len; i++) {
        bytes[i] = binary.charCodeAt(i);
    }
    return bytes.buffer;
};


export const encryptDataAES = async (data) => {
    const aesKey = CryptoJS.lib.WordArray.random(32);
    const iv = CryptoJS.lib.WordArray.random(16);

    const encrypted = CryptoJS.AES.encrypt(data, aesKey, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
    }).toString();
    return {
        encryptedData: encrypted,
        aesKey: aesKey.toString(CryptoJS.enc.Base64),
        iv: iv.toString(CryptoJS.enc.Base64)
    };
};

