import React, { useState, useEffect } from "react";

import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";
import "flatpickr/dist/themes/material_blue.css";
import { deleteQuickItems, gMerchantOffers, updateQuickStatus, updateTheCampaignStatus } from "Context/ContextApi";
import arrowicon from "../../../assets/images/NewImage/skip-track.png"
import Toggle from "react-toggle";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import { QuickQRViewModal } from "./qrcodeModal";
import viewIcon from "../../../assets/images/NewImage/icons8-eye-64.png"
import deleteimg from "../../../assets/images/NewImage/supprimer.png"

export const QuickTableList = ({ productData, userDataa, setData }) => {
    const [modalData, setModalData] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [qrCode, setQrCode] = useState('')

    const handleToggle = async (id, isChecked) => {
        try {
            const formData = {
                id: id,
                Status: `${isChecked ? "ACTIVE" : "INACTIVE"}`
            }
            const response = await updateQuickStatus(formData)
            toastr.success(response.message)
        } catch (error) {
            console.log(error);
        }
    };

    const handleView = async (item, qrdata) => {
        setModalData(item)
        setQrCode(qrdata)
        setIsOpen(true)
    }

    
const handleDeletePermission = async (id)=>{
    try {
      const formData = {
        id:id
      }
      const response = await deleteQuickItems(formData)
        toastr.success(response.message, "Success", {
        timeOut: 7000,
        progressBar: true, 
        closeDuration:700,
        positionClass: "toast-top-right" 
      });
      setData(response.result);
    } catch (error) {
      console.log(error);
    }
  }

    const columns = [

        {
            dataField: "TimeStamp",
            text: "Date",
            sort: true,
            formatter: (cell, row) => (
                <div>{new Date(cell).toLocaleString("en-US", {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                })}</div>
            ),
        },
        {
            dataField: "Product_name",
            text: "Name",
            sort: true,
        },
        {
            dataField: "Invoice_Reference",
            text: "Invoice Reference",
            sort: true,
        },
        {
            dataField: "price_Per_ZWG",
            text: "price Per ZWG",
            sort: true,
        },
        {
            dataField: "Price_Per_USD",
            text: "Price Per USD",
            sort: true,
        },
        {
            dataField: "View",
            text: "QR Code ",
            sort: true,
            formatter: (cell, row) => (
                <span style={{ color: row.status === "Success" ? "green" : "red", display: "flex", gap: "5px" }}>
                    <img src={viewIcon} alt="delete" style={{ width: "25px", cursor: "pointer" }} onClick={() => handleView(row, row.QRCode)} />
                </span>
            ),
        },
        {
            dataField: "Active",
            text: "Status",
            sort: true,
            formatter: (cell, row) => (
                <div className="campaign_check_box_Active_InActive">
                    {/* <span >
                    ACTIVE
                </span> */}
                    <Toggle
                        aria-labelledby='biscuit-label'
                        id={`toggle-${row.id}`}
                        defaultChecked={row.Status === "ACTIVE"}
                        onChange={(e) => handleToggle(row.id, e.target.checked)}
                    />
                     {/* <span >
                    INACTIVE
                </span> */}
                </div>
            ),
        },

        {
            dataField: "edit",
            text: "Action",
            sort: true,
            formatter: (cell, row) => (
              <span style={{ color: row.status === "Success" ? "green" : "red", display:"flex", gap:"5px" }}>
      
                <img src={deleteimg} alt="delete" style={{width:"25px", cursor:"pointer"}} onClick={()=>handleDeletePermission(row.id)}/>
              </span>
            ),
          },
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: productData.length,
        custom: true,
    };

    const handleCancel = () => {
        setIsOpen(false)
    }
    return (
        <React.Fragment>
            <QuickQRViewModal isOpen={isOpen} qrCode={qrCode} data={modalData} userDataa={userDataa} handleCancel={handleCancel} />
                <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={productData}
                >
                    {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                            keyField="id"
                            columns={columns}
                            data={productData}
                            search
                        >
                            {toolkitProps => (
                                <React.Fragment>
                                    <Row>
                                        <Col xl="12">
                                            <div className="table-responsive">
                                                <BootstrapTable
                                                    keyField={"id"}
                                                    responsive
                                                    bordered={false}
                                                    striped={false}
                                                    defaultSorted={defaultSorted}
                                                    // selectRow={selectRow}
                                                    classes={"table align-middle table-nowrap"}
                                                    headerWrapperClasses={"thead-light"}
                                                    {...toolkitProps.baseProps}
                                                    {...paginationTableProps}
                                                    style={{
                                                        fontWeight: "Gilroy",
                                                    }}
                                                    headerClasses={"custom-header-class"}
                                                    rowStyle={{
                                                        fontSize: "15px",
                                                        fontFamily: "Gilroy",
                                                        fontWeight: "400"
                                                    }}
                                                />
                                            </div>
                                        </Col>
                                    </Row>

                                    <Row className="align-items-md-center mt-30">
                                        <Col className="inner-custom-pagination d-flex">
                                            <div className="d-inline">
                                                <SizePerPageDropdownStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                            <div className="text-md-right ms-auto">
                                                <PaginationListStandalone
                                                    {...paginationProps}
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                </React.Fragment>
                            )}
                        </ToolkitProvider>
                    )}
                </PaginationProvider>
        </React.Fragment>
    );
};

