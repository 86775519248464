import React, { useEffect, useRef, useState } from "react";
import './RegisterNewStyle.scss'
import logosrc from "../../assets/images/GET-logo.png"
import { Button, Col, Container, Form, Input, Row } from "reactstrap";
import cancel from "../../assets/images/NewImage/cancel.png"
import {  nationalidCheck, verifyTheNationalId } from "Auth/ApiFunction";
import toastr, { error, success } from "toastr";
import "toastr/build/toastr.min.css";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import FileUpload, { addressProofText, bussinessproofText, sentenceMap, trandingLicenceText, validMerchantTypes } from "./RegisterFile";
import { useData } from "Context/ContextProvider";
import { fetchMerchantProfile, updateMerchantRegisterForm } from "Context/ContextApi";
import Merchanttlogo from "../../assets/images/NewImage/Invoice-Logo.png";

const CompleteRegistration = () => {
    const fileInputRef = useRef(null);
    const history = useHistory()
    const [validationErrors, setValidationErrors] = useState({});
    const [selectedFileKey, setSelectedFileKey] = useState(null);
    const [accpect, setAccept] = useState("")
    const [signatoryText, setSignatoryText] = useState('');
    const [addresProof, setAddressProof] = useState('')
    const [bussinessProofText, setBussinessProoftext] = useState('')
    const [tradingLicenceText, setTradingLicenceText] = useState('')
    const [verifyOrNot, setVerifyOrNot] = useState(false)
    const [nationalId, setNationalId] = useState(null)
    const [nationalIdboolen, setNationalIdboolen] = useState(false)
    const [isForm, setIsForm] = useState({
        MerchantType: "",
        Title: "",
        FullName: "",
        Alias: "",
        Email: "",
        Mobile: "",
        NationalIDno: "",
        AddressProofNo: "",
        VPA:"",

        PersonalAddressLine1: "",
        FlatNo: "",
        PersonalAddressLine2: "",
        PersonalNearby: "",
        PersonalCity: "",
        PersonalProviance: "",
        PersonalCountry: "",
        PersonalZipcode: "",

        BusinessName: "",
        BusinessAddressLine1: "",
        BusinessAddressLine2: "",
        BusinessNearby: "",
        BusinessCity: "",
        BusinessProviance: "",
        BusinessCountry: "",
        BusinessZipcode: "",

        NationalIDImg1: null,
        NationalIDImg2: null,
        AddressProofImg1: null,
        AddressProofImg2: null,
        BusinessAddressProofImg1: null,
        BusinessAddressProofImg2: null,
        TradingLicenceImg1: null,
        TradingLicenceImg2: null,
        MemorandumArticleImg1: null,
        MemorandumArticleImg2: null,
        CertOfIncImg1: null,
        CertOfIncImg2: null,
        UtilityImg1: null,
        UtilityImg2: null,
    })

    const bussinessMerchantTypes = ["StreetVendor", "SoleTrader", "PublicLimited", "PrivateLimited", "Partnership", "NGO", "Club"];

    useEffect(() => {
        updateSignatoryText(isForm.MerchantType);
    }, [isForm.MerchantType]);

    const handlechanges = (e) => {
        const { name, value } = e.target;
        setIsForm({ ...isForm, [name]: value });
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
        updateSignatoryText(value);
    }
    
    const effact = "effact"
    useEffect(() => { fData() }, [effact])

    const fData = async () => {
        try {
            const response = await fetchMerchantProfile()
            setIsForm({ ...isForm, ...response.result });
        } catch (error) {
            console.log(error);
        }
    }

    const updateSignatoryText = (selectedValue) => {
        const newText = sentenceMap[selectedValue] || 'National ID';
        setSignatoryText(newText);
        const addressText = addressProofText[selectedValue] || 'address proof';
        setAddressProof(addressText);
        const bussinessText = bussinessproofText[selectedValue] || 'bussiness proof';
        setBussinessProoftext(bussinessText)
        const tradingLicence = trandingLicenceText[selectedValue] || 'Trading Licence'
        setTradingLicenceText(tradingLicence)
    };

    const handleUploadClick = (key) => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
            setSelectedFileKey(key);
        }
    };

    const handleFileChange = (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile && selectedFileKey) {
            setIsForm({ ...isForm, [selectedFileKey]: selectedFile });
            setSelectedFileKey(null);
        }
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [selectedFileKey]: undefined,
        }));
    };

    const handleCancelImage = (imageKey) => {
        setIsForm({ ...isForm, [imageKey]: null });
    };

    const ensureVPAFormat = (vpa) => {
        const trimmedVPA = vpa.trim(); 
        if (!trimmedVPA.endsWith('@GET')) {
          return trimmedVPA + '@GET';
        }
        return trimmedVPA;
      };

    const validCharactersRegex = /^[a-zA-Z0-9@]*$/;

    // validation 
    const validateForm = () => {
        const formattedVPA = ensureVPAFormat(isForm.VPA);
        let errors = {};

        if (!isForm.NationalIDno.trim()) {
            errors.NationalIDno = "National ID number is required";
        }

        if (!isForm.AddressProofNo ) {
            errors.AddressProofNo = "Address Proof No is required";
        }

        if (!formattedVPA.match(validCharactersRegex)) {
          errors.VPA = 'Only alphanumeric characters and "@" are allowed at the beginning';
        } else if (formattedVPA.length < 8) {
          errors.VPA = 'The VPA must be at least 8 characters long';
        }
        
        // if (!isForm.VPA.match(validCharactersRegex)) {
        //     errors.VPA = 'Only alphanumeric characters are allowed';
        //   } else if (isForm.VPA.length < 12 || isForm.VPA.slice(-4) !== '@GET') {
        //     errors.VPA = 'The VPA must be at least 8 characters long and end with "@GET"';
        //   }

        if (!isForm.PersonalAddressLine1 ) {
            errors.PersonalAddressLine1 = "Residential address is required";
        }

        if (!isForm.BusinessAddressLine1 ) {
            errors.BusinessAddressLine1 = "Business address is required";
        }
        if (!isForm.PersonalCity ) {
            errors.PersonalCity = "City is required"
        }
        if (!isForm.PersonalProviance ) {
            errors.PersonalProviance = "Proviance is required"
        }
        if (!isForm.PersonalCountry ) {
            errors.PersonalCountry = "Country is required"
        }
        if (!isForm.PersonalZipcode ) {
            errors.PersonalZipcode = "Zipcode is required"
        }
        if (!isForm.BusinessNearby ) {
            errors.BusinessNearby = "Land mark / Nearby area is required"
        }
        if (!isForm.BusinessCity ) {
            errors.BusinessCity = "Business City is required"
        }
        if (!isForm.BusinessProviance ) {
            errors.BusinessProviance = "Business Proviance City is required"
        }
        if (!isForm.BusinessZipcode ) {
            errors.BusinessZipcode = "Business Zipcode is required"
        }
        if (!isForm.BusinessCountry ) {
            errors.BusinessCountry = "Business Country is required"
        }

        // if (!isForm.BusinessName.trim()) {
        //     errors.BusinessName = "Business Name / Company Name is required"
        // }

        // if (!isForm.NationalIDImg1) {
        //     errors.NationalIDImg1 = "Please upload a document file";
        // } else {
        //     const fileValidationError = validateFile(isForm.NationalIDImg1);
        //     if (fileValidationError) {
        //         errors.NationalIDImg1 = fileValidationError;
        //     }
        // }

        if (!isForm.AddressProofImg1) {
            errors.AddressProofImg1 = "Please upload a document file";
        } else {
            const fileValidationError = validateFile(isForm.AddressProofImg1);
            if (fileValidationError) {
                errors.AddressProofImg1 = fileValidationError;
            }
        }
        if (!isForm.TradingLicenceImg1) {
            errors.TradingLicenceImg1 = "Please upload a document file";
        } else {
            const fileValidationError = validateFile(isForm.TradingLicenceImg1);
            if (fileValidationError) {
                errors.TradingLicenceImg1 = fileValidationError;
            }
        }
        if (!isForm.BusinessAddressProofImg1) {
            errors.BusinessAddressProofImg1 = "Please upload a document file";
        } else {
            const fileValidationError = validateFile(isForm.BusinessAddressProofImg1);
            if (fileValidationError) {
                errors.BusinessAddressProofImg1 = fileValidationError;
            }
        }
        if (isForm.MerchantType === "PublicLimited") {
            if (!isForm.MemorandumArticleImg1) {
                errors.MemorandumArticleImg1 = "Please upload a document file";
            } else {
                const fileValidationError = validateFile(isForm.MemorandumArticleImg1);
                if (fileValidationError) {
                    errors.MemorandumArticleImg1 = fileValidationError;
                }
            }
        }
        if (isForm.MerchantType === "PrivateLimited" || isForm.MerchantType === "Partnership") {
            if (!isForm.CertOfIncImg1) {
                errors.CertOfIncImg1 = "Please upload a document file";
            } else {
                const fileValidationError = validateFile(isForm.CertOfIncImg1);
                if (fileValidationError) {
                    errors.CertOfIncImg1 = fileValidationError;
                }
            }
        }
        if (isForm.MerchantType === "SoleTrader") {
            if (!isForm.UtilityImg1) {
                errors.UtilityImg1 = "Please upload a document file";
            } else {
                const fileValidationError = validateFile(isForm.UtilityImg1);
                if (fileValidationError) {
                    errors.UtilityImg1 = fileValidationError;
                }
            }
        }
        if (isForm.MerchantType === "NGO") {
            if (!isForm.ConstitutionImg1) {
                errors.ConstitutionImg1 = "Please upload a document file";
            } else {
                const fileValidationError = validateFile(isForm.ConstitutionImg1);
                if (fileValidationError) {
                    errors.ConstitutionImg1 = fileValidationError;
                }
            }
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };
    const validateFile = (file) => {
        if (!file) {
            return "Please upload a file";
        }
        return null;
    };

    const handleSubmitForm = async () => {
        try {
            const isValid = validateForm();
            if (nationalIdboolen === false) {
                toastr.warning("Upload Valid National Id ")
                return
            }
            if (accpect && nationalIdboolen && isValid) {
                const formData = new FormData()
                for (const [key, value] of Object.entries(isForm)) {
                    if (value instanceof File) {
                        formData.append(key, value);
                    } else if (Array.isArray(value) && value.length > 0 && value[0] instanceof File) {
                        value.forEach((file, index) => {
                            formData.append(`${key}`, file);
                        });
                    } else {
                        formData.append(key, value);
                    }
                }
               const response = await updateMerchantRegisterForm(formData)
                if(response.status === "success"){
                    toastr.success(response.message, "Success", {
                        timeOut: 7000,
                        progressBar: true, 
                        closeDuration:700,
                        positionClass: "toast-top-right" 
                      });
                    history.push("/get-dashboard")
                }
            }
        } catch (error) {
            toastr.warning(error?.response?.data?.message)
        }
    }

    const handleNationalId = async (e) => {
        const image = e.target.files[0];
        setNationalId(image)
        await handleCheckNationalIdDoc(image);
        setIsForm({ ...isForm, NationalIDImg1: image })
    }

    const handleCheckNationalIdDoc = async (image) => {
        try {
            const formData = new FormData()
            formData.append("Image", image)
            const response = await nationalidCheck(formData)
            const enteredNationalIDno = isForm.NationalIDno;
            const apiNationalIDno = response.result.IdNumber;
            const cleanApiNationalIDno = apiNationalIDno ? apiNationalIDno.replace(/[-\s]/g, '') : '';
            const cleanEnteredNationalIDno = enteredNationalIDno ? enteredNationalIDno.replace(/[-\s]/g, '') : '';
            const firstElevenDigitsApi = cleanApiNationalIDno.substring(0, 11);
            const firstElevenDigitsEntered = cleanEnteredNationalIDno.substring(0, 11);
            const isMatching = firstElevenDigitsApi === firstElevenDigitsEntered;

            if (isMatching) {
                // toastr.success("National ID numbers are the same")
                alert("National ID numbers are the same")
                setNationalIdboolen(true)
                setIsForm({
                    ...isForm,
                    FullName: response.result.FirstName,
                })
            } else {
                alert("National ID numbers are different");
                setNationalIdboolen(true)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleVerify = async () => {
        try {
            const formData = { NationalId: isForm?.NationalIDno }
            const response = await verifyTheNationalId(formData)
            if (response.result === true) {
               toastr.success('National id is verifid', "Success", {
                    timeOut: 7000,
                    progressBar: true, 
                    closeDuration:700,
                    positionClass: "toast-top-right" 
                  });
                setVerifyOrNot(response.result)
            }else{
                toastr.warning('Enter valid national Id number', "warning", {
                    timeOut: 7000,
                    progressBar: true, 
                    closeDuration:700,
                    positionClass: "toast-top-right" 
                  });
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <div className="registration_main_section">
                <div className="onBording_logo">
                    <img src={Merchanttlogo} alt="logo" />
                        <Link to="/logout">
                            <span>logout</span>
                        </Link>
                </div>
                <Container>
                    <div className="registration_heading_text">
                        <h1>MERCHANT ONBOARDING</h1>
                    </div>
                    <>
                        <Row className="mt-4">
                            <Col lg="6" md="6" sm="12" xs="12">
                                <Row>
                                    <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                        <div className="Registration_form_complete">
                                            <label><span>*</span>National</label>
                                            <Input type="text" placeholder="Enter National Id No" name="NationalIDno" value={isForm.NationalIDno} onChange={handlechanges} />
                                            {validationErrors.NationalIDno && <span className="Onbording-error-message">{validationErrors.NationalIDno}</span>}
                                            <Button className={`verfy-button-for-nationalid  ${verifyOrNot ? "verifid-national-number" : ""}`} onClick={handleVerify}>{verifyOrNot ? "Verified" : "Verify"}</Button>
                                        </div>
                                    </Col>
                                    <Col lg="6" md="6" sm="6" xs="6" className="pt-3">
                                        <div className="Registration_form_complete">
                                            <label><span>*</span>Image</label>
                                            <Input type="file" onChange={handleNationalId} />
                                            {nationalId && (<div className="register_national_image mt-2">
                                                <img src={URL.createObjectURL(nationalId)} alt="National ID Image" />
                                            </div>)}
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label>VPA</label>
                                    <Input type="text" placeholder="Enter your VPA" name="VPA" value={isForm.VPA} onChange={handlechanges} />
                                    {validationErrors.VPA && <span className="Onbording-error-message">{validationErrors.VPA}</span>}
                                </div>
                            </Col>
                        </Row>
                    </>
                    <Form>
                        <Row>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Full Name</label>
                                    <Input type="text" placeholder="Enter your full name" name="FullName" value={isForm.FullName} onChange={handlechanges} />
                                    {validationErrors.FullName && <span className="Onbording-error-message">{validationErrors.FullName}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label>Alias</label>
                                    <Input type="text" placeholder="Enter your alias" name="Alias" value={isForm.Alias} onChange={handlechanges} />
                                    {validationErrors.Alias && <span className="Onbording-error-message">{validationErrors.Alias}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Email</label>
                                    <Input type="text" placeholder="Enter your email" name="Email" value={isForm.Email} onChange={handlechanges} disabled />
                                    {validationErrors.Email && <span className="Onbording-error-message">{validationErrors.Email}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <div className="Registration_form_complete">
                                    <label><span>*</span>Mobile number</label>
                                    <Input type="text" placeholder="Enter your mobile number" name="Mobile" value={isForm.Mobile} onChange={handlechanges} disabled />
                                    {validationErrors.Mobile && <span className="Onbording-error-message">{validationErrors.Mobile}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <div className="Registration_form_complete">
                                        <label><span>*</span>Residential Address</label>
                                        <Input type="text" placeholder="Enter street address or P.O. BOX" name="PersonalAddressLine1" value={isForm.PersonalAddressLine1} onChange={handlechanges} />
                                        {validationErrors.PersonalAddressLine1 && <span className="Onbording-error-message">{validationErrors.PersonalAddressLine1}</span>}
                                    </div>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <div className="Registration_form_complete">
                                        <Input type="text" placeholder="Enter address line 2" name="PersonalAddressLine2" value={isForm.PersonalAddressLine2} onChange={handlechanges} />
                                    </div>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <div className="Registration_form_complete">
                                        <Input type="text" placeholder="Enter Flat No" name="FlatNo" value={isForm.FlatNo} onChange={handlechanges} />
                                    </div>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <div className="Registration_form_complete">
                                        <Input type="text" placeholder="Enter landmark / nearby area" name="PersonalNearby" value={isForm.PersonalNearby} onChange={handlechanges} />
                                    </div>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <Row>
                                        <Col md="6" sm="12" xs="12" className="pt-3">
                                            <div className="Registration_form_complete">
                                                <Input type="text" placeholder="Enter province" name="PersonalProviance" value={isForm.PersonalProviance} onChange={handlechanges} />
                                                {validationErrors.PersonalProviance && <span className="Onbording-error-message">{validationErrors.PersonalProviance}</span>}
                                            </div>
                                        </Col>
                                        <Col md="6" sm="12" xs="12" className="pt-3">
                                            <div className="Registration_form_complete">
                                                <Input type="text" placeholder="Enter postal / zipcode" name="PersonalZipcode" value={isForm.PersonalZipcode} onChange={handlechanges} />
                                                {validationErrors.PersonalZipcode && <span className="Onbording-error-message">{validationErrors.PersonalZipcode}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <Row>
                                        <Col md="6" sm="12" xs="12" className="pt-3">
                                            <div className="Registration_form_complete">
                                                <Input type="text" placeholder="Enter city / locality" name="PersonalCity" value={isForm.PersonalCity} onChange={handlechanges} />
                                                {validationErrors.PersonalCity && <span className="Onbording-error-message">{validationErrors.PersonalCity}</span>}
                                            </div>
                                        </Col>
                                        <Col md="6" sm="12" xs="12" className="pt-3">
                                            <div className="Registration_form_complete">
                                                <select name="PersonalCountry" value={isForm.PersonalCountry} onChange={handlechanges}>
                                                    <option value="" disabled hidden >Select country</option>
                                                    <option value="ZIMBABWE">ZIMBABWE</option>
                                                </select>
                                                {validationErrors.PersonalCountry && <span className="Onbording-error-message">{validationErrors.PersonalCountry}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-3">
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <div className="Registration_form_complete">
                                        <label><span>*</span>Business Address</label>
                                        <Input type="text" placeholder="Enter Business / Company Name" name="BusinessName" value={isForm.BusinessName} onChange={handlechanges} />
                                        {validationErrors.BusinessName && <span className="Onbording-error-message">{validationErrors.BusinessName}</span>}
                                    </div>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <div className="Registration_form_complete">

                                        <Input type="text" placeholder="Enter street address or P.O. BOX" name="BusinessAddressLine1" value={isForm.BusinessAddressLine1} onChange={handlechanges} />
                                        {validationErrors.BusinessAddressLine1 && <span className="Onbording-error-message">{validationErrors.BusinessAddressLine1}</span>}
                                    </div>
                                </Col>

                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <div className="Registration_form_complete">
                                        <Input type="text" placeholder="Enter address line 2" name="BusinessAddressLine2" value={isForm.BusinessAddressLine2} onChange={handlechanges} />
                                        {validationErrors.BusinessAddressLine2 && <span className="Onbording-error-message">{validationErrors.BusinessAddressLine2}</span>}
                                    </div>
                                </Col>

                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <div className="Registration_form_complete">
                                        <Input type="text" placeholder="Enter landmark / nearby area" name="BusinessNearby" value={isForm.BusinessNearby} onChange={handlechanges} />
                                        {validationErrors.BusinessNearby && <span className="Onbording-error-message">{validationErrors.BusinessNearby}</span>}
                                    </div>
                                </Col>

                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <Row>
                                        <Col md="6" sm="12" xs="12" className="pt-3">
                                            <div className="Registration_form_complete">
                                                <Input type="text" placeholder="Enter province" name="BusinessProviance" value={isForm.BusinessProviance} onChange={handlechanges} />
                                                {validationErrors.BusinessProviance && <span className="Onbording-error-message">{validationErrors.BusinessProviance}</span>}
                                            </div>
                                        </Col>
                                        <Col md="6" sm="12" xs="12" className="pt-3">
                                            <div className="Registration_form_complete">
                                                <Input type="text" placeholder="Enter postal / zipcode" name="BusinessZipcode" value={isForm.BusinessZipcode} onChange={handlechanges} />
                                                {validationErrors.BusinessZipcode && <span className="Onbording-error-message">{validationErrors.BusinessZipcode}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="12" md="12" sm="12" xs="12" className="pt-3">
                                    <Row>
                                        <Col md="6" sm="12" xs="12" className="pt-3">
                                            <div className="Registration_form_complete">
                                                <Input type="text" placeholder="Enter city / locality" name="BusinessCity" value={isForm.BusinessCity} onChange={handlechanges} />
                                                {validationErrors.BusinessCity && <span className="Onbording-error-message">{validationErrors.BusinessCity}</span>}
                                            </div>
                                        </Col>
                                        <Col md="6" sm="12" xs="12" className="pt-3">
                                            <div className="Registration_form_complete">
                                                <select name="BusinessCountry" value={isForm.BusinessCountry} onChange={handlechanges}>
                                                    <option value="" disabled hidden>Select country</option>
                                                    <option value="ZIMBABWE">ZIMBABWE</option>
                                                </select>
                                                {validationErrors.BusinessCountry && <span className="Onbording-error-message">{validationErrors.BusinessCountry}</span>}
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Col>
                        </Row>
                    </Form>
                    <Row className="mt-5">
                        <Col md={{
                            offset: 3,
                            size: 6
                        }}>
                            <div className="Registration_form_complete">
                                <select name="MerchantType" value={isForm.MerchantType} onChange={handlechanges}>
                                    <option value="" disabled hidden>SELECT NATURE OF BUSINESS</option>
                                    <option value="PublicLimited">PUBLIC LIMITED</option>
                                    <option value="PrivateLimited">PRIVATE LIMITED</option>
                                    <option value="Partnership">PARTNERSHIP</option>
                                    <option value="SoleTrader">SOLE TRADERS</option>
                                    <option value="NGO">NON-PROFIT ORGANISATIONS (NGOs, CHURCHES,TRUSTS)</option>
                                    <option value="Club">SOCIETIES,ASSOCIATION,CLUBS ETC </option>
                                    <option value="StreetVendor">STREET VENDOR</option>
                                </select>
                            </div>
                        </Col>
                    </Row>
                    {isForm.MerchantType === "PublicLimited" && (
                        <>
                            <Row className="mt-4">
                                <Col lg="7" md="7" sm="12" xs="12">
                                    <div className="Registration_form_complete_for_file">
                                        <span><small>*</small>Memorandum / Article of association </span>
                                    </div>
                                </Col>
                                <Col lg="5" md="5" sm="12" xs="12">
                                    <Row>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <span>*</span>
                                                        <button htmlFor="fileInput"
                                                            onClick={() => handleUploadClick('MemorandumArticleImg1')}>Upload</button>
                                                    </div>
                                                    <Input ref={fileInputRef}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} />

                                                </div>
                                                {isForm.MemorandumArticleImg1 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.MemorandumArticleImg1.name}</span>
                                                        <img src={cancel} alt="*"
                                                            onClick={() => handleCancelImage('MemorandumArticleImg1')}
                                                        />
                                                    </div>
                                                </div>}

                                            </div>

                                        </Col>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <button onClick={() => handleUploadClick('MemorandumArticleImg2')}>Upload</button>
                                                    </div>
                                                    <Input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />
                                                </div>
                                                {isForm.MemorandumArticleImg2 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.MemorandumArticleImg2.name}</span>
                                                        <img src={cancel} alt="*"
                                                            onClick={() => handleCancelImage('MemorandumArticleImg2')} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                        <div className="file_errors_in_onboarding">
                                            {validationErrors.MemorandumArticleImg1 && <span className="Onbording-error-message">{validationErrors.MemorandumArticleImg1}</span>}
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                    {validMerchantTypes.includes(isForm.MerchantType) && (
                        <>
                            <Row className="mt-4">
                                <Col lg="7" md="7" sm="12" xs="12">
                                    <div className="Registration_form_complete_for_file">
                                        <div>
                                            <span><small>*</small>{addresProof}</span>
                                        </div>
                                        <div className={`registration_national_id_no_input_feild ${validationErrors.AddressProofNo ? "error_national_id_no" : ""}`}>
                                            <Input type="text" placeholder="Enter address proof NO" name="AddressProofNo" value={isForm.AddressProofNo} onChange={handlechanges} />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg="5" md="5" sm="12" xs="12">
                                    <Row>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <span>*</span>
                                                        <button htmlFor="fileInput" onClick={() => handleUploadClick('AddressProofImg1')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} />

                                                </div>
                                                {isForm.AddressProofImg1 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.AddressProofImg1.name}</span>
                                                        <img src={cancel} alt="*" onClick={() => handleCancelImage('AddressProofImg1')} />
                                                    </div>
                                                </div>}
                                            </div>

                                        </Col>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <button onClick={() => handleUploadClick('AddressProofImg2')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />

                                                </div>
                                                {isForm.AddressProofImg2 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.AddressProofImg2.name}</span>
                                                        <img src={cancel} alt="*" onClick={() => handleCancelImage('AddressProofImg2')} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                        <div className="file_errors_in_onboarding">
                                            {validationErrors.AddressProofImg1 && <span className="Onbording-error-message">{validationErrors.AddressProofImg1}</span>}
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                    {bussinessMerchantTypes.includes(isForm.MerchantType) && (
                        <>
                            <Row className="mt-4">
                                <Col lg="7" md="7" sm="12" xs="12">
                                    <div className="Registration_form_complete_for_file">
                                        <span><small>*</small> {bussinessProofText}</span>
                                    </div>
                                </Col>
                                <Col lg="5" md="5" sm="12" xs="12">
                                    <Row>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <span>*</span>
                                                        <button htmlFor="fileInput"
                                                            onClick={() => handleUploadClick('BusinessAddressProofImg1')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} />

                                                </div>
                                                {isForm.BusinessAddressProofImg1 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.BusinessAddressProofImg1.name}</span>
                                                        <img src={cancel} alt="*" onClick={() => handleCancelImage('BusinessAddressProofImg1')} />
                                                    </div>
                                                </div>}

                                            </div>

                                        </Col>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <button onClick={() => handleUploadClick('BusinessAddressProofImg2')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />

                                                </div>
                                                {isForm.BusinessAddressProofImg2 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.BusinessAddressProofImg2.name}</span>
                                                        <img src={cancel} alt="*" onClick={() => handleCancelImage('BusinessAddressProofImg2')} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                        <div className="file_errors_in_onboarding">
                                            {validationErrors.BusinessAddressProofImg1 && <span className="Onbording-error-message">{validationErrors.BusinessAddressProofImg1}</span>}
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                    {validMerchantTypes.includes(isForm.MerchantType) && (
                        <>
                            <Row className="mt-4">
                                <Col lg="7" md="7" sm="12" xs="12">
                                    <div className="Registration_form_complete_for_file">
                                        <span><small>*</small>{tradingLicenceText} </span>
                                    </div>
                                </Col>
                                <Col lg="5" md="5" sm="12" xs="12">
                                    <Row>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <span>*</span>
                                                        <button htmlFor="fileInput"
                                                            onClick={() => handleUploadClick('TradingLicenceImg1')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} />

                                                </div>
                                                {isForm.TradingLicenceImg1 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.TradingLicenceImg1.name}</span>
                                                        <img src={cancel} alt="*" onClick={() => handleCancelImage('TradingLicenceImg1')} />
                                                    </div>
                                                </div>}

                                            </div>

                                        </Col>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <button onClick={() => handleUploadClick('TradingLicenceImg2')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />

                                                </div>
                                                {isForm.TradingLicenceImg2 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.TradingLicenceImg2.name}</span>
                                                        <img src={cancel} alt="*" onClick={() => handleCancelImage('TradingLicenceImg2')} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                        <div className="file_errors_in_onboarding">
                                            {validationErrors.TradingLicenceImg1 && <span className="Onbording-error-message">{validationErrors.TradingLicenceImg1}</span>}
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                    {(isForm.MerchantType === "PrivateLimited" || isForm.MerchantType === "Partnership") && (
                        <>
                            <Row className="mt-4">
                                <Col lg="7" md="7" sm="12" xs="12">
                                    <div className="Registration_form_complete_for_file">
                                        <span><small>*</small>Certificate of Incorporation </span>

                                    </div>
                                </Col>
                                <Col lg="5" md="5" sm="12" xs="12">
                                    <Row>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <span>*</span>
                                                        <button htmlFor="fileInput"
                                                            onClick={() => handleUploadClick('CertOfIncImg1')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} />

                                                </div>
                                                {isForm.CertOfIncImg1 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.CertOfIncImg1.name}</span>
                                                        <img src={cancel} alt="*"
                                                            onClick={() => handleCancelImage('CertOfIncImg1')}
                                                        />
                                                    </div>
                                                </div>}

                                            </div>

                                        </Col>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <button onClick={() => handleUploadClick('CertOfIncImg2')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />

                                                </div>
                                                {isForm.CertOfIncImg2 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.CertOfIncImg2.name}</span>
                                                        <img src={cancel} alt="*"
                                                            onClick={() => handleCancelImage('CertOfIncImg2')} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                        <div className="file_errors_in_onboarding">
                                            {validationErrors.CertOfIncImg1 && <span className="Onbording-error-message">{validationErrors.CertOfIncImg1}</span>}
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                    {isForm.MerchantType === "SoleTrader" && (
                        <>
                            <Row className="mt-4">
                                <Col lg="7" md="7" sm="12" xs="12">
                                    <div className="Registration_form_complete_for_file">
                                        <span><small>*</small>Utility bill or rent agreement </span>
                                    </div>
                                </Col>
                                <Col lg="5" md="5" sm="12" xs="12">
                                    <Row>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <span>*</span>
                                                        <button htmlFor="fileInput"
                                                            onClick={() => handleUploadClick('UtilityImg1')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} />

                                                </div>
                                                {isForm.UtilityImg1 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.UtilityImg1.name}</span>
                                                        <img src={cancel} alt="*"
                                                            onClick={() => handleCancelImage('UtilityImg1')}
                                                        />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <button onClick={() => handleUploadClick('UtilityImg2')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />

                                                </div>
                                                {isForm.UtilityImg2 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.UtilityImg2.name}</span>
                                                        <img src={cancel} alt="*"
                                                            onClick={() => handleCancelImage('UtilityImg2')} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                        <div className="file_errors_in_onboarding">
                                            {validationErrors.UtilityImg1 && <span className="Onbording-error-message">{validationErrors.UtilityImg1}</span>}
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                    {isForm.MerchantType === "NGO" && (
                        <>
                            <Row className="mt-4">
                                <Col lg="7" md="7" sm="12" xs="12">
                                    <div className="Registration_form_complete_for_file">
                                        <span><small>*</small> Copy of Constitution </span>
                                    </div>
                                </Col>
                                <Col lg="5" md="5" sm="12" xs="12">
                                    <Row>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <span>*</span>
                                                        <button htmlFor="fileInput"
                                                            onClick={() => handleUploadClick('ConstitutionImg1')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef}
                                                        type="file"
                                                        style={{ display: 'none' }}
                                                        onChange={handleFileChange} />
                                                </div>
                                                {isForm.ConstitutionImg1 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.ConstitutionImg1.name}</span>
                                                        <img src={cancel} alt="*"
                                                            onClick={() => handleCancelImage('ConstitutionImg1')}
                                                        />
                                                    </div>
                                                </div>}

                                            </div>
                                        </Col>
                                        <Col sm="6" xs="6">
                                            <div className="onboarding_form_gropup_right_side_end">
                                                <div className="Registration_form_complete_for_file_right_side">
                                                    <div className="onboarding_form_file_button">
                                                        <button onClick={() => handleUploadClick('ConstitutionImg2')}>Upload</button>
                                                    </div>
                                                    <input ref={fileInputRef} type="file" style={{ display: 'none' }} onChange={handleFileChange} />

                                                </div>
                                                {isForm.ConstitutionImg2 && <div className="onborading_cancel_main">
                                                    <div className="registration_file_name_and_cancel">
                                                        <span>{isForm.ConstitutionImg2.name}</span>
                                                        <img src={cancel} alt="*"
                                                            onClick={() => handleCancelImage('ConstitutionImg2')} />
                                                    </div>
                                                </div>}
                                            </div>
                                        </Col>
                                        <div className="file_errors_in_onboarding">
                                            {validationErrors.ConstitutionImg1 && <span className="Onbording-error-message">{validationErrors.ConstitutionImg1}</span>}
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </>
                    )}
                    <div className="terms_and_condtion_registration">
                        <Input type="checkbox" name="accpect" value={accpect} onChange={(e) => setAccept(e.target.checked)} /><span>i accpect the terms & conditons</span>
                    </div>
                    <div className="Registration_button">
                        <button onClick={handleSubmitForm}>SUBMIT</button>
                    </div>
                </Container>
            </div>
        </React.Fragment>
    )
}
export default CompleteRegistration