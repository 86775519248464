import Fingerprint2 from 'fingerprintjs2';

export const GenerateDeviceId = async () => {
    const storedDeviceId = localStorage.getItem('deviceIds');
    if (storedDeviceId) {
        return storedDeviceId;
    } else {
        Fingerprint2.get((components) => {
            const values = components.map(component => component.value);
            const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
            return deviceId;
            // localStorage.setItem('deviceId', deviceId);
        });
    }
};

export const getDeviceId = async () => {
    return new Promise((resolve) => {
        Fingerprint2.get((components) => {
            const values = components.map((component) => component.value);
            const deviceId = Fingerprint2.x64hash128(values.join(''), 31);
            resolve(deviceId);
        });
    });
};