import CryptoJS from 'crypto-js';

const IV_LENGTH = 16;

const MerchantApiKey = localStorage.getItem('MerchantApiKey');

const mkey1 = 'Ka1eQOMrzDk9HvHrUlI8hGLKdhPFxUUF';

export const EncryptFormDataByAlgo = async (formData) => {
  const data = 'x' + mkey1 + mkey1 + mkey1 + '=';
  const hashedKey = CryptoJS.SHA256(data).toString(CryptoJS.enc.Base64).substr(0, 32);
  const iv = CryptoJS.lib.WordArray.random(IV_LENGTH);
  const key = CryptoJS.enc.Utf8.parse(hashedKey);
  const encrypted = CryptoJS.AES.encrypt(formData, key, { iv: iv });
  return iv.toString(CryptoJS.enc.Base64) + ':' + encrypted.toString();
}

// export const EncryptFormDataByAlgo = (text) => {
//   if (!mkey1) {
//     console.warn('Encryption key (mkey) is missing!');
//     return '';
//   }
//   const iv = CryptoJS.lib.WordArray.random(16);
//   const key = CryptoJS.enc.Utf8.parse(mkey1);
//   const encrypted = CryptoJS.AES.encrypt(text, key, { iv: iv });
//   return iv.toString(CryptoJS.enc.Base64) + ':' + encrypted.toString();
// };
