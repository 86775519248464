import { takeEvery, put, call, takeLatest } from "redux-saga/effects";

import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { loginSuccess, logoutUserSuccess, apiError } from "./actions";
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";
import Cookies from "js-cookie";
const fireBaseBackend = getFirebaseBackend();

const API_BASE_URL = process.env.REACT_APP_API_URL;


function* loginUser({ payload: { user, history } }) {
  try {
    const data = {
      Email: user.email,
      Password_: user.password,
      IPAddress: user.IPAddress
    }
    const response = yield call(postJwtLogin, data);
    if (response && response.token) {
    
      const expirationDate = new Date(new Date().getTime() + 2 * 60 * 60 * 1000);
      // const expirationDate = new Date(new Date().getTime() + 7 * 60 * 1000);
      
      const authUserData = {
        token: response.token,
        expires: expirationDate.toISOString(),
        IPAddress: user.IPAddress,
      };
      Cookies.set("authUsers", JSON.stringify(authUserData), { expires: expirationDate });
      localStorage.setItem("authUsers", JSON.stringify(response.token));
      yield put(loginSuccess(response.token));
      yield put(apiError(response.message));
      if (response.result?.Register_Status === 'COMPLETED') {
        if (response.result?.kycVerified === 'ReVerify') {
          history.push("/re-verify");
        } else {
          history.push("/get-dashboard");
        }
      } else {
        history.push("/registration-form");
      }
    } else {
      if (response && response.status === "failed" && response.message === "Password Doesn't Match.Login With Correct Credentials.") {
        yield put(apiError("Incorrect email or password. Please try again."));
      } else {

        if (response && response.status === 401) {
          const errorResponse = JSON.parse(response.response);
          if (errorResponse && errorResponse.status === "failed" && errorResponse.message === "Password Doesn't Match.Login With Correct Credentials.") {
            yield put(apiError("Incorrect email or password. Please try again."));
          } else {
            yield put(apiError(errorResponse.message));
          }
        } else {
          yield put(apiError(response.message));
        }
      }
    }
  } catch (error) {
    yield put(apiError(error.response.data.message));
  }
}
authSaga.js


function* logoutUser({ payload: { history } }) {
  try {
    localStorage.removeItem("authUsers");
    Cookies.remove("authUsers");
   
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = yield call(fireBaseBackend.logout);
      yield put(logoutUserSuccess(response));
    }
    history.push("/get9878979798");
  } catch (error) {
    yield put(apiError(error.message));
  }
}

function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);

  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
