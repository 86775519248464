// FileUpload.js
import React from 'react';
import PropTypes from 'prop-types';
import cancel from "../../assets/images/NewImage/cancel.png"

const FileUpload = ({ label, name, onClick, onCancel, file, error }) => {
  return (
    <div className="onboarding_form_gropup_right_side_end">
      <div className="onboarding_form_group_for_file_right_side">
        <div className="onboarding_form_file_button">
          <span>*</span>
          <button onClick={() => onClick(name)}>Upload</button>
        </div>
        <input type="file" style={{ display: 'none' }} onChange={(e) => onClick(e, name)} />
      </div>
      {file && (
        <div className="onborading_cancel_main">
          <div className="onboarding_file_name_and_cancel">
            <span>{file.name}</span>
            <img src={cancel} alt="*" onClick={() => onCancel(name)} />
          </div>
        </div>
      )}
      {error && <div className="file_errors_in_onboarding">
      <span className="Onbording-error-message">{error}
            </span></div>}
    </div>
  );
};

FileUpload.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  file: PropTypes.object,
  error: PropTypes.string,
};

export default FileUpload;


export const validMerchantTypes = ["NGO", "Club", "StreetVendor", "SoleTrader", "PublicLimited", "PrivateLimited", "Partnership"];

export const sentenceMap = {
    'PublicLimited': 'National ID of Signatory',
    'PrivateLimited': ' National ID of Signatory',
    'Partnership': 'National ID of Signatory',
    'SoleTrader': 'National ID of Signatory',
    'NGO': ' National ID of Trustee',
    'Club': 'National ID of Member',
    'StreetVendor': ' National ID',
};

export const addressProofText = {
    'PublicLimited': 'Address Proof of Signatory ',
    'PrivateLimited': 'Address Proof of Signatory ',
    'Partnership': 'Address Proof of Signatory ',
    'SoleTrader': ' Address Proof of Signatory',
    'NGO': 'Address Proof of Trustee ',
    'Club': 'Address Proof of Member',
    'StreetVendor': 'Address Proof / Rent Agreement',
};

export const bussinessproofText = {
    'PublicLimited': 'Business Address proof',
    'PrivateLimited': 'Business Address proof ',
    'Partnership': 'Business Address proof ',
    'SoleTrader': ' Business Address proof',
    'StreetVendor': 'Business / Shop Address proof( if applicable)',
    'NGO': 'NOG’s Address proof',
    'Club': ' Outlet’sAddress proof',
};

export const trandingLicenceText = {
    'PublicLimited': 'Trading Licence if applicable  ',
    'PrivateLimited': 'Trading Licence if applicable  ',
    'Partnership': 'Trading Licence if applicable  ',
    'SoleTrader': 'Trading Licence if applicable ',
    'NGO': 'Trading Licence if applicable  ',
    'Club': 'Trading Licence if applicable ',
    'StreetVendor': 'Trading Licence if applicable ',
}
