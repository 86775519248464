import React, { useEffect, useState } from "react";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import { editRequestMoney } from "Context/ContextApi";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { ZesaPyamentsDetails } from "./form";

function ZesapaymentPage() {
    const { id } = useParams();
    const { iPAddress, mData, userDataa, } = useData();
    const [data, setData] = useState({})
    useEffect(() => {
        handleScanPay(id)
    }, [id]);
    
    const handleScanPay = async (item) => {
        try {
            const formData = { id: item };
            const response = await editRequestMoney(formData)
            setData(response.result)
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message)
        }
    }
    document.title = "GET Platform  | zesa payment details"
    return (
        <React.Fragment>
            <ZesaPyamentsDetails data={data} iPAddress={iPAddress} mData={mData} userDataa={userDataa} />
        </React.Fragment>
    );
}
export default ZesapaymentPage;
