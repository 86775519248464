import React, { useEffect, useState } from "react";
import './registration.scss'
import logosrc from "../../assets/images/NewImage/Invoice-Logo.png"
import { Button, Col, Form, Input, Row } from "reactstrap"
import { inserRegistrationDataIntoMerchant, sendOtp, validationOtpcheck, } from "Auth/ApiFunction";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useHistory, Link } from "react-router-dom/cjs/react-router-dom.min";
import bannerIcon from "../../assets/images/NewImage/Image_merchant_dashbaord.png"
import 'react-phone-number-input/style.css';
import GoogleRegister from "./googleAuthProvider";
import UnderlineShape from "./underLineSvg";

const GetRegistration = () => {
    const history = useHistory()
    const [validationErrors, setValidationErrors] = useState({});
    const [checkedError, setCheckedError] = useState(false)
    const [accept, setAccept] = useState("")
    const [otp, setOtp] = useState("")
    const [validOtp, setValidOtp] = useState(false)
    const [isForm, setIsForm] = useState({
        FullName: "",
        Alias: "",
        Email: "",
        Mobile: "",
        BusinessName: "",
        countryCode: "91",
    })

    const validateForms = () => {
        let errors = {};

        if (!isForm.FullName.trim()) {
            errors.FullName = "Full Name is required";
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!isForm.Email.trim() || !emailRegex.test(isForm.Email.trim())) {
            errors.Email = "Enter a valid email address";
        }

        const mobileRegex = /^\d{10}$/;
        if (!isForm.Mobile.trim() || !mobileRegex.test(isForm.Mobile.trim())) {
            errors.Mobile = "Enter a valid 10-digit mobile number";
        }
        const minMobileLength = 10;
        const maxMobileLength = 15;
        if (isForm.Mobile.trim().length < minMobileLength || isForm.Mobile.trim().length > maxMobileLength) {
            errors.Mobile = `Mobile number must be between ${minMobileLength} and ${maxMobileLength} digits`;
        }

        if (!isForm.BusinessName.trim()) {
            errors.BusinessName = "Business / Company Name is required"
        }

        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handlechanges = (e) => {
        const { name, value } = e.target;
        setIsForm({ ...isForm, [name]: value });
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    }

    const handleCountryChange = (e) => {
        const countryCode = e.target.value;
        setIsForm({ ...isForm, countryCode, });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const isValid = validateForms();
            if (!accept) {
                setCheckedError(true)
                return
            }
            if (!validOtp) {
                setValidationErrors({ ...validationErrors, OTP: "Please enter valid OTP" });
                return
            }
            if (isValid && accept) {
                const formData = {
                    FullName: isForm.FullName,
                    Alias: isForm.Alias,
                    Email: isForm.Email,
                    Mobile: isForm.Mobile,
                    BusinessName: isForm.BusinessName,
                }
                const response = await inserRegistrationDataIntoMerchant(formData)
                toastr.success(response.message)
                history.push("/get9878979798")
            }
        } catch (error) {
            toastr.warning(error?.response?.data?.message)
        }
    }

    const [otpRequestDisabled, setOtpRequestDisabled] = useState(false);
    const [timer, setTimer] = useState(0);

    useEffect(() => {
        let interval;
        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prevTimer => prevTimer - 1);
            }, 1000);
        } else if (timer === 0 && otpRequestDisabled) {
            setOtpRequestDisabled(false);
        }

        return () => clearInterval(interval);
    }, [timer]);

    const handleSendOtp = async () => {
        try {
            if (otpRequestDisabled) return;
            const formData = {
                Mobile: isForm.Mobile,
                countryCode: isForm.countryCode
            }
            const response = await sendOtp(formData)
            toastr.success(response.message)
            setOtpRequestDisabled(true);
            setTimer(60);
        } catch (error) {
            console.log(error);
        }
    }

    const handleValidateOtp = async () => {
        try {
            const formData = {
                otp: otp,
                Mobile: isForm.Mobile
            }
            const response = await validationOtpcheck(formData)
            setValidationErrors({ ...validationErrors, OTP: "" });
            toastr.success(response.message)
            setValidOtp(response.result)
            if (response.result) {
                setOtpRequestDisabled(true);
            }
        } catch (error) {
            console.log(error);
        }
    }

    document.title = "GET Platform | Registration";

    return (
        <React.Fragment>
            <div className="registration-main-section ">
                <div className="registration_logo mb-4">
                    <a href="login"><img src={logosrc} alt="image" /></a>
                    <Link to="/login">
                        <span>Login</span>
                    </Link>
                </div>
                <Row>
                    <Col lg="4" md="4" sm="12" xs="12" >
                        <div className="form-heading-text">
                            <h1><b>Join us and never miss a thing -
                                <span className="text-relative ps-1">
                                    SIGN UP!
                                    <UnderlineShape className="text-absolute -bottom-4 start-0 h-2.5 w-24 text-blue md:w-28 xl:-bottom-1.5 xl:w-36" />
                                </span></b></h1>
                            <p>By signing up, you will gain access to exclusive content, special offers, and be the first to hear about exciting news and updates.</p>
                        </div>
                        <GoogleRegister />
                        <div className="google-or-line-container">
                            <div className="line"></div>
                            <div className="google-or-line-text">OR</div>
                            <div className="line"></div>
                        </div>
                        <Row>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-2">
                                <div className="registration_form_group">
                                    <label><span>*</span>Full Name</label>
                                    <input type="text" placeholder="Enter your full name" name="FullName" value={isForm.FullName} onChange={handlechanges} />
                                    {validationErrors.FullName && <span className="Onbording-error-message">{validationErrors.FullName}</span>}
                                </div>
                            </Col>
                            <Col lg="6" md="6" sm="12" xs="12" className="pt-2">
                                <div className="registration_form_group">
                                    <label>Alias</label>
                                    <input type="text" placeholder="Enter your alias" name="Alias" value={isForm.Alias} onChange={handlechanges} />
                                    {validationErrors.Alias && <span className="Onbording-error-message">{validationErrors.Alias}</span>}
                                </div>
                            </Col>
                            <Col lg="12" md="12" sm="12" xs="12" className="pt-2">
                                <div className="registration_form_group">
                                    <label><span>*</span>Business name</label>
                                    <input type="text" placeholder="Enter Business / Company Name" name="BusinessName" value={isForm.BusinessName} onChange={handlechanges} />
                                    {validationErrors.BusinessName && <span className="Onbording-error-message">{validationErrors.BusinessName}</span>}
                                </div>
                            </Col>
                            <Col lg="12" md="12" sm="12" xs="12" className="pt-2">
                                <div className="registration_form_group">
                                    <label><span>*</span>Email</label>
                                    <input type="text" placeholder="Enter your email" name="Email" value={isForm.Email} onChange={handlechanges} />
                                    {validationErrors.Email && <span className="Onbording-error-message">{validationErrors.Email}</span>}
                                </div>
                            </Col>
                            <Col lg="8" md="8" sm="12" xs="12" className="pt-2">
                                <div className="registration_form_group">
                                    <label><span>*</span>Mobile number</label>
                                    <div className="registration_form_group_select_countryCode">
                                        <select
                                            className="form-select"
                                            onChange={handleCountryChange}
                                            value={isForm.countryCode}
                                        >
                                            <option value="91">+91</option>
                                            <option value="263">+263</option>
                                        </select>
                                        <input type="text" placeholder="Enter your mobile number" name="Mobile" value={isForm.Mobile} onChange={handlechanges} />
                                    </div>
                                    {validationErrors.Mobile && <span className="Onbording-error-message">{validationErrors.Mobile}</span>}
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12" xs="12" className="pt-2">
                                {/* <div className="registration_button_OTP">
                                    <Button onClick={handleSendOtp}>Request OTP</Button>
                                </div> */}
                                <div className="registration_button_OTP">
                                    <Button onClick={handleSendOtp} disabled={otpRequestDisabled}>
                                        {otpRequestDisabled ? `Wait ${timer}s` : 'Request OTP'}
                                    </Button>
                                </div>
                            </Col>
                            <Col lg="8" md="8" sm="12" xs="12" className="pt-5">
                                <div className="registration_form_group">
                                    <input type="number" disabled={validOtp} placeholder="Enter your OTP" name="otp" value={otp} onChange={(e) => setOtp(e.target.value)} />
                                    {validationErrors.OTP && <span className="Onbording-error-message">{validationErrors.OTP}</span>}
                                </div>
                            </Col>
                            <Col lg="4" md="4" sm="12" xs="12" className="pt-3">
                                <div className="registration_button_OTP">
                                    <Button disabled={validOtp} onClick={handleValidateOtp}>
                                        {validOtp ? "Validated" : "Validate OTP"}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                        <div className={`terms_and_condtion_registration ${checkedError ? "checked-error" : ""}`}>
                            <Input type="checkbox" name="accept" value={accept} onChange={(e) => setAccept(e.target.checked)} />
                            <span><b>i accept the terms & conditons</b></span>
                        </div>
                        <div className="registration_button">
                            <button onClick={handleSubmit}>SUBMIT </button>
                        </div>
                        <div className="do-you-have-account-text text-center">
                            <span>Already have an account? <Link to="login">Login</Link></span>
                        </div>
                    </Col>
                    <Col lg="2" md="2"></Col>
                    <Col lg="6" md="6" sm="12" xs="12" >
                        <div className="form-left-heading-text mb-3">
                            <h2><b>The simplest way to manage your workspace.</b></h2>
                        </div>
                        <div><img src={bannerIcon} alt="image" /></div>
                    </Col>
                </Row>

            </div>
        </React.Fragment>
    )
}
export default GetRegistration;










