import React, { useState, useEffect, useContext, useRef } from "react";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { withTranslation } from "react-i18next";
import SimpleBar from "simplebar-react";
import MetisMenu from "metismenujs";
import {
  CampaignsSvg,
  Dashboardsvg,
  DepositMoneySvg,
  PaymentSvg,
  RequestMoneySvg,
  SendMoneySvg,
  SettingSvg,
  TransactionsSvg,
  VerificationSvg,
  WithdrawalsSvg,
} from "assets/svg/svg";
import merchantLogo from "../../assets/images/NewImage/merchant_Portal_Logo.png"
import { compileString } from "sass";
  
const token = JSON.parse(localStorage.getItem("authUsers"));
const permision = localStorage.getItem("paths")
const localColorIcon = localStorage.getItem("iconColors")

const SidebarContent =({ location, t, token,  fetchData }) => {

// const token = JSON.parse(localStorage.getItem("authUsers"));
const [iconColor, setIconColor] = useState('#FFAF30')
  const [isClicked, setIsClicked] = useState(false);
  const [subJasonParse, setSubJasonParse] = useState({});
  const [hitOneTime, setHitOneTime] = useState(false)
  useEffect(() => {
    if (token && !hitOneTime) {
      fetchData()
      setHitOneTime(true)
    }
  }, [token]);
  
  useEffect(()=>{
    if(permision){
      const data = JSON.parse(permision)
      setSubJasonParse({...data})
    }
  },[permision])

  const refDiv = useRef();

  const handleClick = () => {
    setIsClicked(!isClicked);
  };

  useEffect(() => {
    initMenu();
  }, [location.pathname]);

  const initMenu = () => {
    new MetisMenu("#side-menu");

    let matchingMenuItem = null;
    const ul = document.getElementById("side-menu");
    const items = ul.getElementsByTagName("a");
    for (let i = 0; i < items.length; ++i) {
      if (location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      activateParentDropdown(matchingMenuItem);
    }
  };

  const scrollElement = item => {
    setTimeout(() => {
      if (refDiv.current !== null) {
        if (item) {
          const currentPosition = item.offsetTop;
          if (currentPosition > window.innerHeight) {
            if (refDiv.current)
              refDiv.current.getScrollElement().scrollTop =
                currentPosition - 300;
          }
        }
      }
    }, 300);
  };

  const activateParentDropdown = item => {
    item.classList.add("active");
    const parent = item.parentElement;

    const parent2El = parent.childNodes[1];
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show");
    }

    if (parent) {
      parent.classList.add("mm-active");
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add("mm-show"); // ul tag

        const parent3 = parent2.parentElement; // li tag

        if (parent3) {
          parent3.classList.add("mm-active"); // li
          parent3.childNodes[0].classList.add("mm-active"); //a
          const parent4 = parent3.parentElement; // ul
          if (parent4) {
            parent4.classList.add("mm-show"); // ul
            const parent5 = parent4.parentElement;
            if (parent5) {
              parent5.classList.add("mm-show"); // li
              parent5.childNodes[0].classList.add("mm-active"); // a tag
            }
          }
        }
      }
      scrollElement(item);
      return false;
    }
    scrollElement(item);
    return false;
  };

  const isDashboardPage = location.pathname === "/get-dashboard";
  const istransaction = location.pathname === "/transaction";
  const isdepositmoney = location.pathname === "/deposit-money" || location.pathname === "/deposit-history";
  const issendmoney = location.pathname === "/send-money" || location.pathname === "/send-money-history";
  const isrequestmoney = location.pathname === "/request-money" || location.pathname === "/request-money-history";
  const isWithdrawals = location.pathname === "/Withdrawals";
  const isuserprofiles = location.pathname === "/user-profile";
  const islogout = location.pathname === "/logout";
  const ispayments = location.pathname === "/payments";
  const isverificationSvg = location.pathname === "/verification";
  const isCampaigns =location.pathname === "/create-offer" || location.pathname === "/campaign-coupon";
  const isgetsettings =location.pathname === "/manage-roles" || location.pathname === "/withdrawals-setting" 
  || location.pathname === "/manage-user-groups" || location.pathname === "/general" || location.pathname === "/get-payment-details";
  const isClickedWithDrawal = location.pathname === "/withdraw-money" || location.pathname === "/withdrawalslist";
  const isActiveLogSvg = location.pathname === "/active-log";
  const isThemesSvg = location.pathname === "/themes-list";
  const isScanpayOrders = location.pathname === "/orders";

  return (
    <>
     <SimpleBar className="h-100" ref={refDiv}>
     <div id="sidebar-menu"
     className="side-menu-item-color"
      // style={{ background: 'linear-gradient(to right, #000000, #000033)'}}
     >
       <ul className="metismenu list-unstyled mb-4" id="side-menu">
       { subJasonParse?.Dashboard?.view !== false ? (
           <li className="menu-title">{t("Menu")}</li>
           ) :null} 
         { subJasonParse?.Dashboard?.view !== false ? (
         <li>
           <Link to="/get-dashboard" className="">
             <i>
               <Dashboardsvg fill={isDashboardPage ?  localColorIcon || iconColor : "#C1BFDF "} />
             </i>
             <span>{t("Dashboard")}</span>
           </Link>
         </li>
       ) :null} 
       
         {/* new pages  */}

          <li className="menu-title">{t("TRANSACTIONS")}</li>
         {subJasonParse?.Payments?.view !== false ? (
         <li>
           <Link to="/payments" className="">
             <i>
               <PaymentSvg fill={ispayments ? localColorIcon || iconColor : "#C1BFDF "} />
             </i>
             <span>{t("Payments")}</span>
           </Link>
         </li>
           ) :null} 
          { subJasonParse?.Transaction?.view !== false ? ( 
         <li>
           <Link to="/transaction" className="">
             <i>
               <TransactionsSvg fill={istransaction ? localColorIcon || iconColor : "#C1BFDF "} />
             </i>
             <span>{t("Transactions")}</span>
           </Link>
         </li>
        ) :null} 
          {/* { subJasonParse?.Transaction?.view !== false ? ( 
         <li>
           <Link to="/orders" className="">
             <i>
               <PaymentSvg fill={isScanpayOrders ? localColorIcon || iconColor : "#C1BFDF "} />
             </i>
             <span>{t("Orders")}</span>
           </Link>
         </li>
        ) :null}  */}


  {subJasonParse?.['Request money']?.view !== false ? (
            <>
          <li>
            <Link to="/#" className="has-arrows">
              <i>
                <RequestMoneySvg fill={isrequestmoney ? localColorIcon || iconColor : "#C1BFDF"} />
              </i>
              <span>{t("Scan Pay ")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
            { subJasonParse?.Request?.view !== false ? (
              <li>
                <Link to="/orders" className="">
                  <span>{t("Orders")}</span>
                </Link>
              </li>
            ):null}
              { subJasonParse?.['Request money']?.view !== false ? (
              <li>
                <Link to="/initiate-scan-pay" className="">
                  <span>{t("Request")}</span>
                </Link>
              </li>
              ):null}
                { subJasonParse?.['Request money']?.view !== false ? (
              <li>
                <Link to="/refunds" className="">
                  <span>{t("Refunds Request")}</span>
                </Link>
              </li>
              ):null}
            </ul>
          </li>
          </>
        ) :null} 

         {/* request money */}
         { subJasonParse?.['Request money']?.view !== false ? (
           <>
         <li>
           <Link to="/#" className="has-arrows">
             <i>
               <RequestMoneySvg fill={isrequestmoney ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Payment Request")}</span>
           </Link>
           <ul className="sub-menu" aria-expanded="false">
           { subJasonParse?.Request?.view !== false ? (
             <li>
               <Link to="/request-money" className="">
                 <span>{t("Request")}</span>
               </Link>
             </li>
           ):null}
             { subJasonParse?.['Request History']?.view !== false ? (
             <li>
               <Link to="/request-money-history" className="">
                 <span>{t("History")}</span>
               </Link>
             </li>
             ):null}
           </ul>
         </li>
         </>
       ) :null} 
         
           {subJasonParse?.['Send money']?.view !== false ? (
         <li>
           <Link to="/#" className="has-arrows">
             <i>
               <SendMoneySvg fill={issendmoney ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Send Money")}</span>
           </Link>
           <ul className="sub-menu" aria-expanded="false">
           { subJasonParse?.Send?.view !== false ? (
             <li>
               <Link to="/send-money" className="">
                 <span>{t("Send")}</span>
               </Link>
             </li>
           ):null}
             { subJasonParse?.['Send History']?.view !== false ? (
             <li>
               <Link to="/send-money-history" className="">
                 <span>{t("History")}</span>
               </Link>
             </li>
             ):null}
           </ul>
         </li>
         ) :null} 

         {/* withdrawals  */}
         
         {   subJasonParse?.Withdrawal?.view !== false ? (
         <li>
           <Link to="/Withdrawals" className="has-arrows">
             <i>
               <WithdrawalsSvg fill={isClickedWithDrawal ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Withdrawals")}</span>
           </Link>
           <ul className="sub-menu sub_menu_line" aria-expanded="false"> 
           { subJasonParse?.['Withdrawal money']?.view !== false ? (
             <li>
               <Link to="/withdraw-money" className="">
                 {t("Withdraw Money")}
               </Link>
             </li>
           ):null}
             { subJasonParse?.['Withdrawal History']?.view !== false ? (
             <li>
               <Link to="/withdrawalslist">{t("History")}</Link>
             </li>
             ):null}
           </ul>
         </li>
        ) :null} 
         { subJasonParse?.['Deposit money']?.view !== false ? (
         <li>
           <Link to="/#" className="has-arrows">
             <i>
               <DepositMoneySvg fill={isdepositmoney ? localColorIcon || iconColor : "#C1BFDF "} />
             </i>
             <span>{t("Deposit")}</span>
           </Link>
           <ul className="sub-menu" aria-expanded="false">
           { subJasonParse?.Deposit?.view !== false ? (
             <li>
               <Link to="/deposit-money" className="">
                 <span>{t("Deposit")}</span>
               </Link>
             </li>
           ):null}
           { subJasonParse?.['Deposit History']?.view !== false ? (
             <li>
               <Link to="/deposit-history" className="">
                 <span>{t("History")}</span>
               </Link>
             </li>
               ):null}
           </ul>
         </li>
      ):null} 
      <li>
        <Link to="/statement" className="">
              <i>
                <VerificationSvg fill={isverificationSvg ? localColorIcon || iconColor : "#C1BFDF"} />
              </i>
              <span>{t("Statement")}</span>
        </Link>
      </li>
         <li className="menu-title">{t("OTHERS")}</li>
         { subJasonParse?.Campaigns?.view !== false ? (
         <li>
           <Link to="/verifications" className="">
             <i>
               <VerificationSvg fill={isverificationSvg ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Verification")}</span>
           </Link>
         </li>
        ) :null}  
          {subJasonParse.Verification?.view !== false  ? ( 
         <li>
           <Link to="/#" className="has-arrows">
             <i>
               <CampaignsSvg fill={isCampaigns ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Campaigns")}</span>
           </Link>
           <ul className="sub-menu" aria-expanded="false">
           { subJasonParse?.['Create Campaigns']?.view !== false ? (
             <li>
               <Link to="/create-offer" className="d-flex">
                 <span>{t("Create & Manage")}</span>
               </Link>
             </li>
           ):null}
            { subJasonParse?.['Campaigns History']?.view !== false ? (
             <li>
               <Link to="/campaigns" className="d-flex ">
                 <span>{t("List")}</span>
               </Link>
             </li>
            ):null}
             { subJasonParse?.['Campaigns Redeemed']?.view !== false ? (
             <li>
               <Link to="/campaign-redeemed" className="d-flex ">
                 <span>{t("Redeemed")}</span>
               </Link>
             </li>
             ):null}
           </ul>
         </li>
         ) :null} 
          {subJasonParse.Setting?.view !== false  ?(
         <li>
           <Link to="/#" className="has-arrows">
             <i>
               <SettingSvg fill={isgetsettings ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Settings")}</span>
           </Link>
           <ul className="sub-menu" aria-expanded="false">
             <li>
               <Link to="/withdrawals-setting">{t("Bank")}</Link>
             </li>
             <li>
               <Link to="/manage-roles" className="">
                 <span>{t("Roles & Permissions")}</span>
               </Link>
             </li>
             <li>
               <Link to="/manage-user-groups" className="">
                 <span>{t("User groups")}</span>
               </Link>
             </li>
             <li>
               <Link to="/general" className="">
                 <span>{t("General")}</span>
               </Link>
             </li>
             <li>
               <Link to="/get-payment-details" className="">
                 <span>{t("Payment Details")}</span>
               </Link>
             </li>
           </ul>
         </li>
     ) :null} 
      { subJasonParse?.Campaigns?.view !== false ? (
         <li>
           <Link to="/qr-code-list" className="">
             <i>
               <VerificationSvg fill={isverificationSvg ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("QR")}</span>
           </Link>
         </li>
        ) :null}  
         { subJasonParse?.Campaigns?.view !== false ? (
         <li>
           <Link to="/devices" className="">
             <i>
               <VerificationSvg fill={isverificationSvg ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Devices")}</span>
           </Link>
         </li>
        ) :null}  

  { subJasonParse?.Campaigns?.view !== false ? (
          <li>
            <Link to="/zesa/list" className="">
              <i>
                <VerificationSvg fill={isverificationSvg ? localColorIcon || iconColor : "#C1BFDF"} />
              </i>
              <span>{t("Zesa")}</span>
            </Link>
          </li>
          ) :null} 

  {subJasonParse?.['Send money']?.view !== false ? (
          <li>
            <Link to="/#" className="has-arrows">
              <i>
                <SendMoneySvg fill={issendmoney ? localColorIcon || iconColor : "#C1BFDF"} />
              </i>
              <span>{t("Quick")}</span>
            </Link>
            <ul className="sub-menu" aria-expanded="false">
            { subJasonParse?.Send?.view !== false ? (
              <li>
                <Link to="/quick/create" className="">
                  <span>{t("Create")}</span>
                </Link>
              </li>
            ):null}
              { subJasonParse?.Send?.view !== false ? (
              <li>
                <Link to="/quick/item/list" className="">
                  <span>{t("List")}</span>
                </Link>
              </li>
            ):null}
            </ul>
          </li>
          ) :null} 
          
       <li>
           <Link to="/active-log" className="">
             <i>
               <VerificationSvg fill={isActiveLogSvg ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Activity")}</span>
           </Link>
         </li>
         <li>
           <Link to="/themes-list" className="">
             <i>
               <VerificationSvg fill={isThemesSvg ? localColorIcon || iconColor : "#C1BFDF"} />
             </i>
             <span>{t("Themes")}</span>
           </Link>
         </li>
 </ul>
 <div className="Bottom-box-logo ">
            <Link to="/" className="logo logo-light">
              <span className="logo-sm">
                <img src={merchantLogo} alt="" height="50" width="50"/>
              </span>
              <span className="logo-lg">
                <img src={merchantLogo} alt=""  height="60" width="60"/>
              </span>
            </Link>
          </div>
     </div>
   </SimpleBar>
    </>
  );
};

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  data:PropTypes.object,
};

export default withRouter(withTranslation()(SidebarContent));













































// import PropTypes from "prop-types";
// import React, { Component } from "react";

// //Simple bar
// import SimpleBar from "simplebar-react";

// // MetisMenu
// import MetisMenu from "metismenujs";
// import { withRouter } from "react-router-dom";
// import { Link } from "react-router-dom";

// //i18n
// import { withTranslation } from "react-i18next";
// import {
//   CampaignsSvg,
//   Dashboardsvg,
//   DepositMoneySvg,
//   DisputesSvg,
//   LogoutSvg,
//   PaymentSvg,
//   RequestMoneySvg,
//   SendMoneySvg,
//   SettingSvg,
//   TicketSvg,
//   TransactionsSvg,
//   UserProfileSvg,
//   VerificationSvg,
//   WithdrawalsSvg,
// } from "assets/svg/svg";
// import { useData, DataContext } from "Context/ContextProvider";

// class SidebarContent extends Component {
//   // static contextType = DataContext;
//   constructor(props) {
//     super(props);
//     this.refDiv = React.createRef();
//     this.state = {
//       isClicked: false,
//     };
//   }
//   handleClick = () => {
//     this.setState(prevState => ({
//       isClicked: !prevState.isClicked,
//     }));
//   };

//   componentDidMount() {
//     this.initMenu();
//   }

//   // eslint-disable-next-line no-unused-vars
//   componentDidUpdate(prevProps, prevState, ss) {
//     if (this.props.type !== prevProps.type) {
//       this.initMenu();
//     }
//   }

//   initMenu() {
//     new MetisMenu("#side-menu");

//     let matchingMenuItem = null;
//     const ul = document.getElementById("side-menu");
//     const items = ul.getElementsByTagName("a");
//     for (let i = 0; i < items.length; ++i) {
//       if (this.props.location.pathname === items[i].pathname) {
//         matchingMenuItem = items[i];
//         break;
//       }
//     }
//     if (matchingMenuItem) {
//       this.activateParentDropdown(matchingMenuItem);
//     }
//   }

//   // componentDidUpdate() {}

//   scrollElement = item => {
//     setTimeout(() => {
//       if (this.refDiv.current !== null) {
//         if (item) {
//           const currentPosition = item.offsetTop;
//           if (currentPosition > window.innerHeight) {
//             if (this.refDiv.current)
//               this.refDiv.current.getScrollElement().scrollTop =
//                 currentPosition - 300;
//           }
//         }
//       }
//     }, 300);
//   };

//   activateParentDropdown = item => {
//     item.classList.add("active");
//     const parent = item.parentElement;

//     const parent2El = parent.childNodes[1];
//     if (parent2El && parent2El.id !== "side-menu") {
//       parent2El.classList.add("mm-show");
//     }

//     if (parent) {
//       parent.classList.add("mm-active");
//       const parent2 = parent.parentElement;

//       if (parent2) {
//         parent2.classList.add("mm-show"); // ul tag

//         const parent3 = parent2.parentElement; // li tag

//         if (parent3) {
//           parent3.classList.add("mm-active"); // li
//           parent3.childNodes[0].classList.add("mm-active"); //a
//           const parent4 = parent3.parentElement; // ul
//           if (parent4) {
//             parent4.classList.add("mm-show"); // ul
//             const parent5 = parent4.parentElement;
//             if (parent5) {
//               parent5.classList.add("mm-show"); // li
//               parent5.childNodes[0].classList.add("mm-active"); // a tag
//             }
//           }
//         }
//       }
//       this.scrollElement(item);
//       return false;
//     }
//     this.scrollElement(item);
//     return false;
//   };

//   render() {
//     const {  mData } = this.context;

//     console.log(mData, "mData");

//     const isDashboardPage = this.props.location.pathname === "/get-dashboard";
//     const istransaction = this.props.location.pathname === "/transaction";
//     const isdepositmoney = this.props.location.pathname === "/deposit-money" ||
//       this.props.location.pathname === "/deposit-history";
//     const issendmoney = this.props.location.pathname === "/send-money" ||
//       this.props.location.pathname === "/send-money-history";
//     const isrequestmoney = this.props.location.pathname === "/request-money" ||
//       this.props.location.pathname === "/request-money-history";
//     const isWithdrawals = this.props.location.pathname === "/Withdrawals";
//     const isticket = this.props.location.pathname === "/ticket";
//     const isdisputes = this.props.location.pathname === "/disputes";
//     const isuserprofiles = this.props.location.pathname === "/user-profile";

//     const islogout = this.props.location.pathname === "/logout";
//     const ispayments = this.props.location.pathname === "/payments";
//     const isverificationSvg = this.props.location.pathname === "/verification";
//     const isCampaigns =
//       this.props.location.pathname === "/create-offer" ||
//       this.props.location.pathname === "/campaign-coupon";

//     const isgetsettings =
//       this.props.location.pathname === "/manage-roles" ||
//       this.props.location.pathname === "/withdrawals-setting"||
//       this.props.location.pathname === "/manage-user-groups";

//     const isClickedWithDrawal =
//       this.props.location.pathname === "/withdraw-money" ||
//       this.props.location.pathname === "/withdrawalslist" ;

//     return (
//       <React.Fragment>
//           <DataContext.Consumer>
//         {value => {
//           const { mData } = value;
//           console.log("mData from Consumer: ", mData);
//         }}
//       </DataContext.Consumer>
//         <SimpleBar className="h-100" ref={this.refDiv}>
//           <div id="sidebar-menu">
//             <ul className="metismenu list-unstyled" id="side-menu">
//               <li className="menu-title">{this.props.t("Menu")}</li>

//               <li>
//                 <Link to="/get-dashboard" className="">
//                   <i>
//                     <Dashboardsvg
//                       fill={isDashboardPage ? "#FFAF30 " : "#C1BFDF "}
//                     />
//                   </i>
//                   <span>{this.props.t("Dashboard")}</span>
//                 </Link>
//               </li>
//               {/* new pages  */}

//               <li className="menu-title">{this.props.t("TRANSACTIONS")}</li>
//               <li>
//                 <Link to="/payments" className="">
//                   <i>
//                     <PaymentSvg fill={ispayments ? "#FFAF30 " : "#C1BFDF "} />
//                   </i>
//                   <span>{this.props.t("Payments")}</span>
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/transaction" className="">
//                   <i>
//                     <TransactionsSvg
//                       fill={istransaction ? "#FFAF30 " : "#C1BFDF "}
//                     />
//                   </i>
//                   <span>{this.props.t("Transactions")}</span>
//                 </Link>
//               </li>

//               {/* request money */}

//               <li>
//                 <Link to="/#" className="has-arrows">
//                   <i>
//                     <RequestMoneySvg
//                       fill={isrequestmoney ? "#FFAF30 " : "#C1BFDF"}
//                     />
//                   </i>
//                   <span>{this.props.t("Payment Request")}</span>
//                 </Link>
//                 <ul className="sub-menu" aria-expanded="false">
//                   <li>
//                     <Link to="/request-money" className="">
//                       <span>{this.props.t("Request")}</span>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/request-money-history" className="">
//                       <span>{this.props.t("History")}</span>
//                     </Link>
//                   </li>
//                 </ul>
//               </li>

//               <li>
//                 <Link to="/#" className="has-arrows">
//                   <i>
//                     <SendMoneySvg fill={issendmoney ? "#FFAF30 " : "#C1BFDF"} />
//                   </i>
//                   <span>{this.props.t("Send Money")}</span>
//                 </Link>
//                 <ul className="sub-menu" aria-expanded="false">
//                   <li>
//                     <Link to="/send-money" className="">
//                       <span>{this.props.t("Send")}</span>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/send-money-history" className="">
//                       <span>{this.props.t("History")}</span>
//                     </Link>
//                   </li>
//                 </ul>
//               </li>


//               {/* withdrawals  */}
//               <li>
//                 <Link to="/Withdrawals" className="has-arrows">
//                   <i>
//                     <WithdrawalsSvg
//                       fill={isClickedWithDrawal ? "#FFAF30 " : "#C1BFDF"}
//                     />
//                   </i>
//                   <span>{this.props.t("Withdrawals")}</span>
//                 </Link>
//                 <ul className="sub-menu sub_menu_line" aria-expanded="false">
//                   <li>
//                     <Link to="/withdraw-money" className="">
//                       {this.props.t("Withdraw Money")}
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/withdrawalslist">{this.props.t("History")}</Link>
//                   </li>
                
//                 </ul>
//               </li>

//               <li>
//                 <Link to="/#" className="has-arrows">
//                   <i>
//                     <DepositMoneySvg
//                       fill={isdepositmoney ? "#FFAF30 " : "#C1BFDF "}
//                     />
//                   </i>
//                   <span>{this.props.t("Deposit")}</span>
//                 </Link>
//                 <ul className="sub-menu" aria-expanded="false">
//                   <li>
//                     <Link to="/deposit-money" className="">
//                       <span>{this.props.t("Deposit")}</span>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/deposit-history" className="">
//                       <span>{this.props.t("History")}</span>
//                     </Link>
//                   </li>
//                 </ul>
//               </li>


//               <li className="menu-title">{this.props.t("OTHERS")}</li>

//               <li>
//                 <Link to="/verification" className="">
//                   <i>
//                     <VerificationSvg
//                       fill={isverificationSvg ? "#FFAF30 " : "#C1BFDF"}
//                     />
//                   </i>
//                   <span>{this.props.t("Verification")}</span>
//                 </Link>
//               </li>
//               <li>
//                 <Link to="/#" className="has-arrows">
//                   <i>
//                     <CampaignsSvg fill={isCampaigns ? "#FFAF30 " : "#C1BFDF"} />
//                   </i>
//                   <span>{this.props.t("Campaigns")}</span>
//                 </Link>
//                 <ul className="sub-menu" aria-expanded="false">
//                   <li>
//                     <Link to="/create-offer" className="d-flex">
//                       {/* <div className="submenu_border_line"></div> */}
//                       <span>{this.props.t("Create & Manage")}</span>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/campaign-coupon" className="d-flex ">
//                       {/* <div className="submenu_border_line"></div> */}
//                       <span>{this.props.t("View History")}</span>
//                     </Link>
//                   </li>
//                 </ul>
//               </li>
//               <li>
//                 <Link to="/#" className="has-arrows">
//                   <i>
//                     <SettingSvg fill={isgetsettings ? "#FFAF30 " : "#C1BFDF"} />
//                   </i>
//                   <span>{this.props.t("Settings")}</span>
//                 </Link>
//                 <ul className="sub-menu" aria-expanded="false">
//                 <li>
//                     <Link to="/withdrawals-setting">
//                       {this.props.t("Bank")}
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/manage-roles" className="">
//                       <span>{this.props.t("Roles & Permissions")}</span>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/manage-user-groups" className="">
//                       <span>{this.props.t("User groups")}</span>
//                     </Link>
//                   </li>
//                   <li>
//                     <Link to="/general" className="">
//                       <span>{this.props.t("General")}</span>
//                     </Link>
//                   </li>
//                 </ul>
//               </li>

//               {/* example  */}
//               {/* <li>
//                   <Link to="/calendar" className="">
//                     <i className="bx bx-calendar" />
//                     <span>{this.props.t("Calendar")}</span>
//                   </Link>
//                 </li>

//                 <li>
//                   <Link to="/#" className="has-arrow">
//                     <i className="bx bx-store" />
//                     <span>{this.props.t("Ecommerce")}</span>
//                   </Link>
//                   <ul className="sub-menu" aria-expanded="false">
//                     <li>
//                       <Link to="/ecommerce-products">
//                         {this.props.t("Products")}
//                       </Link>
//                     </li>
//                   </ul>
//                 </li> */}
//               {/* ------------------ end ----------------------- */}
//             </ul>
//           </div>
//         </SimpleBar>
//       </React.Fragment>
//     );
//   }
// }

// SidebarContent.propTypes = {
//   location: PropTypes.object,
//   t: PropTypes.any,
//   type: PropTypes.string,
// };
// SidebarContent.contextType = DataContext;
// export default withRouter(withTranslation()(SidebarContent));
