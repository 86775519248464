import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import logosrc from "../../../assets/images/NewImage/Invoice-Logo.png";
import { addDeviceIdOnWhiteList, addToWhiteListThisDevice, fDeviceIdQrCode, generateWhiteListOtp, } from "Auth/ApiFunction";
import CaptchaLoginFooter from "components/Common/captcha-login-otp";
import { useHistory, useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getDeviceId } from "components/Common/generateDeviceId";

const CommonWhiteList = () => {
    document.title = "GET Platform | White List";
    const history = useHistory();
    const { url } = useParams();
    const [deviceId, setDeviceId] = useState('');
    const { iPAddress, userInfo } = useData();
    const [otp, setOtp] = useState(new Array(6).fill(""));
    const [isDevice, setIsDevice] = useState(false);
    const [counterName, setCounterName] = useState('');
    const [number, setNumber] = useState('')
    const [Counters, setCounter] = useState([])

    useEffect(() => {
        fHandleDevice();
    }, [!deviceId]);

    const fHandleDevice = async () => {
        const deviceId = await getDeviceId();
        setDeviceId(deviceId);
        await handleFetchQRCode(deviceId);
    };

    const handleFetchQRCode = async (deviceId) => {
        try {
            const formData = {
                DeviceId: deviceId
            }
            const response = await fDeviceIdQrCode(formData)
            if ((Array.isArray(response.result) && response.result.length > 0)) {
                history.push("/get9878979798");
                return;
            }
        } catch (error) {
            console.log(error);
        }
    }


    const handleChange = (e, index) => {
        const value = e.target.value;
        if (value === "" || /^[a-zA-Z0-9]$/.test(value)) {
            const newOtp = otp.slice();
            newOtp[index] = value;
            setOtp(newOtp);
            if (e.target.nextSibling && value !== "") {
                e.target.nextSibling.focus();
            }
            if (newOtp.every(char => char !== "")) {
                handleSubmit(newOtp);
            }
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === "Backspace") {
            e.preventDefault();
            const newOtp = otp.slice();
            newOtp[index] = "";
            setOtp(newOtp);
            if (e.target.previousSibling) {
                e.target.previousSibling.focus();
            }
        }
    };

    const handlePaste = (e) => {
        const pasteData = e.clipboardData.getData("text").slice(0, 6);
        if (/^[a-zA-Z0-9]{1,6}$/.test(pasteData)) {
            const newOtp = [...pasteData.split("")];
            setOtp(newOtp);
            if (newOtp.every(char => char !== "")) { handleSubmit(newOtp); }
        }
        e.preventDefault();
    };

    const handleSubmit = async (otpValueArray) => {
        try {
            const otpValue = otpValueArray.join("");
            if (otpValue.length < 6) {
                toastr.error("Please enter the full OTP.");
                return;
            }
            const formData = {
                otp: otpValue,
                IPAddress: iPAddress,
                DeviceId: deviceId,
                Latitude: userInfo?.location?.lat,
                Longitude: userInfo?.location?.lon,
                Mobile: number
            };
            const response = await addToWhiteListThisDevice(formData);
            toastr.success(response?.message);
            setCounter(response.Counters)
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message);
            setOtp(new Array(6).fill(""));
        }
    };

    const handleAdd = async () => {
        try {
            if (!counterName) {
                toastr.error("Please enter device counter Name.");
                return;
            }

            const formData = {
                IPAddress: iPAddress,
                DeviceId: deviceId,
                Latitude: userInfo?.location?.lat,
                Longitude: userInfo?.location?.lon,
                Mobile: number,
                CounterName: counterName
            };
            const response = await addDeviceIdOnWhiteList(formData);
            setIsDevice(response?.result);
            toastr.success(response?.message);
            history.push("/get9878979798")
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message);
            setOtp(new Array(6).fill(""));
        }
    };

    const handleRequestOTP = async () => {
        try {
            if (!number) {
                toastr.error("Please refresh the page");
                return;
            }
            const formData = {
                Mobile: number,
                DeviceId: deviceId
            };
            const response = await generateWhiteListOtp(formData);
            setIsDevice(response?.result);
            toastr.success(response?.message);
            if(response?.result){
            history.push("/get9878979798")

            }
        } catch (error) {
            console.log(error);
            toastr.error(error?.response?.data?.message || error?.message);
        }
    };

    return (
        <React.Fragment>
            <div className="registration-main-section">
                <div className="captcha_otp_logo mt-5"> <a href="login"> <img src={logosrc} alt="logo" /> </a> </div>
                {!isDevice ? (
                    <Row>
                        <Col lg={3}></Col>
                        <Col lg={6}>
                            <div className="login-form-heading-text-center mt-3"> <h1> <b> Check your app for a code </b></h1></div>
                            <div className="otp-box mt-5 ">
                                <Form className="whitelist-container">
                                    <FormGroup className="captcha-name-input" onPaste={handlePaste}>
                                        <Label><h5><b>Enter Number</b></h5></Label>
                                        <Input
                                            type="number"
                                            value={number}
                                            maxLength={10}
                                            placeholder="Enter a number"
                                            onChange={(e) => setNumber(e.target.value)}
                                        />
                                    </FormGroup>
                                    <div className="request-button-on-common-white-list">
                                        <Button className="procces_Button" onClick={handleRequestOTP} >Request OTP</Button>
                                    </div>
                                    <Label className="text-start-otp mt-3"><h5><b>OTP</b></h5></Label>
                                    <FormGroup className="d-flex justify-content-center captcha-otp-input" onPaste={handlePaste}>
                                        {otp.map((data, index) => (
                                            <Input
                                                key={index}
                                                type="text"
                                                maxLength="1"
                                                value={data}
                                                onChange={(e) => handleChange(e, index)}
                                                onFocus={(e) => e.target.select()}
                                                onKeyDown={(e) => handleKeyDown(e, index)}
                                                className="otp-field text-center"
                                            />
                                        ))}
                                    </FormGroup>
                                    <div className="request-button-on-common-white-list">
                                        <Button className="procces_Button" onClick={() => handleSubmit(otp)} >Validate</Button>
                                    </div>
                                    {Counters  && Counters.length > 0 && (
                                        <>
                                            <FormGroup className="captcha-name-select" onPaste={handlePaste}>
                                                <Label><h5><b>Counter number</b></h5></Label>
                                                <select value={counterName} onChange={(e) => setCounterName(e.target.value)}>
                                                    {Counters.map((item, index) => (
                                                        <option key={index} value={item.CounterName}>{item.CounterName}</option>
                                                    ))}
                                                </select>
                                            </FormGroup>
                                            <div className="request-button-on-common-white-list">
                                                <Button className="procces_Button" onClick={handleAdd} >Add</Button>
                                                <></>
                                            </div>
                                        </>
                                    )}

                                </Form>

                            </div>
                        </Col>
                        <Col lg={3}></Col>
                    </Row>
                ) : (
                    <Row>
                        <Col lg={12}>
                            <div className="login-form-heading-text-center text-center mt-3"> <h1> <b> Device Whitelisted </b> </h1>
                                <p><b>This device is already in the whitelist. You do not need to add it again.</b></p>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
            <CaptchaLoginFooter />
        </React.Fragment>
    );
};

export default CommonWhiteList;
