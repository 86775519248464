import React, { useEffect,  useState } from "react";
import { Button,  Col,  FormGroup, Input, Label,  } from "reactstrap";
import "../../pages/DepositMoney/DepositeMoneyStyle.css";
import { calaculateTaxScanPay, getAdminCampaignsAndMerchantOffers, } from "Context/ContextApi";
import TaxDetailsShow from "./taxModal";
import infoImage from "../../assets/images/NewImage/info.png"

export const ScanPayCampaigns = ({ sendForm, userDataa, handleChanges, setSendForm }) => {
    const [data, setData] = useState([]);
    const [isTaxDetails, setisTaxDetails] = useState(false)
    const [selectedOption, setSelectedOption] = useState('');
    const [taxData, setTaxData] = useState();
    const [netTotal, setNetTotal] = useState([]);
    const [selectedOffersCode, setSelectedOffersCode] = useState();

    const handleRadioChange = async (e) => {
        try {
            setSelectedOption(e.target.value);
            const formData = {
                Amount: sendForm.Amount,
                Currency: sendForm.Currency,
                Type: e.target.value,
                Method: "ScanAndPay"
            }
            const response = await getAdminCampaignsAndMerchantOffers(formData)
            setData(response?.result)
            handlSelectCampaigns();
        } catch (error) {
            console.log(error)
        }
    };

    const handleRadioOffersChange = async (e) => {
        setSelectedOffersCode(e.target.value);
    };

    useEffect(() => {
        handlSelectCampaigns();
    }, [sendForm?.Amount, sendForm?.Currency, sendForm?.OfferPercentage, sendForm?.OfferPercentage, sendForm?.OfferAmount])

    const handleCancelOffers = () => {
        setSelectedOption('')
        setSelectedOffersCode('');
        handlSelectCampaigns();
        setSendForm({
            ...sendForm,
            OfferCode: "",
            CampaignsId: "",
            OfferPercentage: "",
            OfferAmount: "",
        })
    }

    const handlSelectCampaigns = async (item) => {
        try {
            if(!sendForm?.Amount){
                return;
            }
            const formData = {
                Amount: sendForm?.Amount || 0,
                Module: 'f08fbe27-7333-4b61-9dcb-30b21ea8ce26',
                Module_Type: '901a9c66-f904-4241-91b6-04c3453374c9',
                Nature: 'PERSONAL',
                OfferId: item || '',
                Currency: sendForm?.Currency || 'USD',
                OfferCode: sendForm?.OfferCode || '',
                OfferPercentage: sendForm?.OfferPercentage || '',
                OfferAmount: sendForm?.OfferAmount || '',
            }
            const response = await calaculateTaxScanPay(formData)
            setNetTotal(response.netTotal)
            setTaxData(response.result)
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <React.Fragment>
            <FormGroup>
                <Col sm={12} className="d-flex gap-2">
                    <FormGroup check>
                        <Input
                            name="radio2"
                            type="radio"
                            value="GETrx"
                            checked={selectedOption === "GETrx"}
                            onChange={handleRadioChange}
                        />
                        {' '}
                        <Label check className="label-text">
                            GETrx
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Input
                            name="radio2"
                            type="radio"
                            value={userDataa?.BusinessName || userDataa?.FullName}
                            checked={selectedOption === (userDataa?.BusinessName || userDataa?.FullName)}
                            onChange={handleRadioChange}
                        />
                        {' '}
                        <Label check className="label-text">
                            {userDataa?.BusinessName || userDataa?.FullName}
                        </Label>
                    </FormGroup>
                    <FormGroup check>
                        <Button className="procces_Butto" onClick={handleCancelOffers} >Cancel</Button>
                    </FormGroup>
                </Col>
            </FormGroup>
            {selectedOption === 'GETrx' && (
                <FormGroup>
                    <div className="form_group_for_inside_in_deposit">
                        <Label for="CampaignsId">campaigns</Label>
                        <Input type="select" id="CampaignsId" name="CampaignsId"
                            required
                            value={sendForm.CampaignsId} onChange={handleChanges}
                        >
                            <option defaultValue hidden>Select campaigns</option>
                            {data && data?.map((item, index) => (
                                <option value={item.id} key={index} onClick={() => { handlSelectCampaigns(item.id) }}>{item?.OffersName}</option>
                            ))}

                        </Input>
                    </div>
                </FormGroup>
            )}
            {selectedOption === (userDataa?.BusinessName || userDataa?.FullName) && (
                <>
                    <Col sm={12} className="d-flex gap-2">
                        <FormGroup check>
                            <Input
                                name="Offers"
                                type="radio"
                                value="OfferAmount"
                                checked={selectedOffersCode === "OfferAmount"}
                                onChange={handleRadioOffersChange}
                            />
                            {' '}
                            <Label check className="label-text">
                                Amount
                            </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Input
                                name="Offers"
                                type="radio"
                                value="OfferPercentage"
                                checked={selectedOffersCode === "OfferPercentage"}
                                onChange={handleRadioOffersChange}
                            />
                            {' '}
                            <Label check className="label-text">
                                Percentage
                            </Label>
                        </FormGroup>
                    </Col>
                    {selectedOffersCode === "OfferAmount" && (
                        <FormGroup>
                            <div className="form_group_for_inside_in_deposit">
                                <Label for="OfferAmount">Amount</Label>
                                <Input
                                    type="number"
                                    id="OfferAmount"
                                    name="OfferAmount"
                                    required
                                    min={0}
                                    placeholder="Enter Offer Amount"
                                    value={sendForm.OfferAmount}
                                    onChange={handleChanges}
                                />
                            </div>
                        </FormGroup>
                    )}
                    {selectedOffersCode === "OfferPercentage" && (
                        <FormGroup>
                            <div className="form_group_for_inside_in_deposit">
                                <Label for="OfferPercentage">Percentage</Label>
                                <Input
                                    type="number"
                                    max={100}
                                    min={0}
                                    id="OfferPercentage"
                                    name="OfferPercentage"
                                    required
                                    placeholder="Enter Offer %"
                                    value={sendForm.OfferPercentage}
                                    onChange={handleChanges}
                                />
                            </div>
                        </FormGroup>
                    )}
                    <FormGroup>
                        <div className="form_group_for_inside_in_deposit">
                            <Label for="OfferCode">Offers code</Label>
                            <Input
                                type="text"
                                id="OfferCode"
                                name="OfferCode"
                                required
                                placeholder="Enter OfferCode"
                                value={sendForm.OfferCode}
                                onChange={handleChanges}
                            />
                        </div>
                    </FormGroup>
                </>
            )}

            {isTaxDetails && <TaxDetailsShow data={taxData} setisTaxDetails={setisTaxDetails} isTaxDetails={isTaxDetails} />}
            <FormGroup>
                <div className="form_group_for_inside_in_deposit">
                    <Label for="NetTotal" >Net Total</Label>
                    <div className="netTotal_and_taxDetails">
                        <Input
                            type="text"
                            id="NetTotal"
                            name="NetTotal"
                            placeholder="net total"
                            value={netTotal}
                        />
                        <button onClick={() => setisTaxDetails(!isTaxDetails)}>
                            <img src={infoImage} alt="image" className="info-icon-for-Scan-pay" />
                        </button>
                    </div>
                </div>
            </FormGroup>
        </React.Fragment>
    );
}
