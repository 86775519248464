import React, { useEffect, useRef, useState } from "react";
import {Row, Col, Card, CardBody, Button, InputGroup,} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
import { useData } from "Context/ContextProvider";
import arrowicon from "../../assets/images/NewImage/skip-track.png"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Breadcrumbs from "components/Common/Breadcrumb";
import { fetchScanPayOrderTable, fMerchantZesaList } from "Context/ContextApi";
import NumberInputModal from "pages/QRCode/List/sendQrModal";
import ScanPayOrderStatus from "pages/ScanPay/OrderTable/status.color";

const API_BASE_URL = process.env.REACT_APP_API_URL
const ZesaList = () => {
    const { userDataa, mData, orderData } = useData();
    const history = useHistory();
    const [products, setProducts] = useState([])
    const [status, setStatus] = useState('')
    const [currency, setCurrency] = useState('')
    const [businesName, setBusinesName] = useState({})
    const [actionData, setActionData] = useState({})
    const [dateRange, setDateRange] = useState([]);
    const [totalAmount, setTotalAmount] = useState({});
    const [rowHighlights, setRowHighlights] = useState(false)
    console.log(products, "products");
    

    useEffect(() => {
        let jsonparse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
        setActionData(jsonparse)
    }, [mData?.subResult?.Permissions])

    const handleDateChange = (selectedDates) => {
        setDateRange(selectedDates);
        if (dateRange[0] && dateRange[1]) {
            handleFetchPayment(status, currency);
        }
    };

    useEffect(() => {
        if (orderData) {
            setProducts([orderData, ...products])
        }
    }, [orderData])

    const statusOptions = [
        { value: '', label: 'All' },
        { value: 'completed', label: 'Successfull' },
        { value: 'pending', label: 'Pending' },
        { value: 'Rejected', label: 'Rejected' },
        { value: 'failed', label: 'Failed' },
    ];

    const currencyOptions = [
        { value: '', label: 'All' },
        { value: 'USD', label: 'USD' },
        { value: 'ZiG', label: 'ZiG' },
    ];

    const sortedProducts = [...products];
    sortedProducts.sort((a, b) => a.Status.localeCompare(b.Status));

    useEffect(() => {
        handleFetchPayment(status, currency);
    }, [userDataa])

    //   useEffect(() => {
    //     if (socketTransaction) {
    //       setProducts(prev => [...socketTransaction, ...prev]);
    //       setRowHighlights(true);
    //       setTimeout(() => {
    //         setRowHighlights(false);
    //       }, 3000);
    //       if (socketTransaction && socketTransaction[0]?.Payment_type == 'merchant transfer') {
    //         history.push("/payments");
    //       }
    //     }
    //   }, [socketTransaction])

    const handleFetchPayment = async (Status, Currency) => {
        try {
            const formData = {
                Status: Status,
                Currency: Currency,
            }
            const response = await fMerchantZesaList(formData)
            setProducts(response.result)
            setBusinesName(response.userInfo)
            setTotalAmount(response.TotalValue)
        } catch (error) {
            console.log(error);
        }
    }

    const columns = [
        {
            dataField: "TimeStamp",
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "FirstName",
            text: "Name",
            sort: true,
        },
        {
            dataField: "VPA",
            text: "VPA",
            sort: true,
        },
        {
            dataField: "Currency",
            text: "Currency",
            sort: true
        },
        {
            dataField: "Amount",
            text: "Amount",
            sort: true,
        },
        {
            dataField: "Type",
            text: "Type",
            sort: true,
        },
        {
            dataField: "Status",
            text: "Status",
            sort: true,
            formatter: (cell, row) => (
                <span

                >
                    < ScanPayOrderStatus data={row} />
                </span>
            ),
        },
        {
            dataField: "details",
            text: "Retry",
            sort: false,
            formatter: (cell, row) => (
                <div>
                    {row?.Type === "INTERNAL" ? (
                        <Link to={`/scan-pay/${row?.id}`}>
                            <p
                                className="Details_popup"
                            // onClick={() => togglePopup(row)}
                            >
                                <img src={arrowicon} alt="icon" />
                            </p>
                        </Link>
                    ) : (
                        <Link to={`/order-payment/${row?.id}`}>
                            <p
                                className="Details_popup"
                            // onClick={() => togglePopup(row)}
                            >
                                <img src={arrowicon} alt="icon" />
                            </p>
                        </Link>
                    )}


                </div>
            ),
        },
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: products.length,
        custom: true,
    };

    const { SearchBar } = Search;

    const handleReset = () => {
        setStatus("")
        setCurrency("")
        setDateRange([])
        handleFetchPayment(status)
    }

    document.title = "GET Platform  | Zesa";

    return (
        <React.Fragment>
            <div
                className="page-content"
                style={{ marginLeft: "5%", marginRight: "5%" }}
            >
                <div className="container-fluid">
                    <Breadcrumbs title="GET" breadcrumbItem="Zesa" />
                    <Row>
                        <Col md={{ offset: 3, size: 6, }} >
                            <h1 className="text-center all_newPaeHeading title-color-common">ZESA TABLE</h1>
                            <h5 className="text-center all_sub_new_heading description-color">
                                List of all Order you received from customers
                            </h5>
                        </Col>
                    </Row>
                    <NumberInputModal />
                    <Row className="mt-5">
                        <Col>
                            <div style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
                                <div style={{ width: "80%", display: "flex", gap: "10px", flexWrap: "wrap" }}>
                                    <div className="filtter_Date_range">
                                        <InputGroup >
                                            <Flatpickr
                                                className="date_picker"
                                                placeholder="Select date range"
                                                options={{
                                                    mode: "range",
                                                    dateFormat: "Y-m-d",
                                                }}
                                                style={{
                                                    borderRadius: "7px",
                                                    border: "1px solid black",
                                                    width: "100%",
                                                    fontFamily: "Gilroy",
                                                    color: "black",
                                                }}
                                                value={dateRange}
                                                onChange={handleDateChange}
                                            />
                                        </InputGroup>
                                    </div>
                                    <select className="filtter_select_box" name="status" value={status}
                                        onChange={(e) => {
                                            const selectedStatus = e.target.value;
                                            setStatus(selectedStatus);
                                            handleFetchPayment(selectedStatus, currency);
                                        }}
                                    >
                                        <option defaultValue hidden>Status</option>
                                        {statusOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                    <select className="filtter_select_box" name="currency" value={currency}
                                        onChange={(e) => {
                                            const selectedStatus = e.target.value;
                                            setCurrency(selectedStatus);
                                            handleFetchPayment(status, selectedStatus);
                                        }}
                                    >
                                        <option defaultValue hidden>Currency</option>
                                        {currencyOptions.map((option) => (
                                            <option key={option.value} value={option.value}>
                                                {option.label}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center",
                                        alignContent: "center",
                                        alignItems: "center",
                                        textAlign: "center",
                                        gap: "20px",
                                    }}
                                >
                                    <Button
                                        type="submit"
                                        className="procces_Button"
                                        onClick={handleReset}
                                    >
                                        Reset
                                    </Button>
                                    <Button
                                        type="submit"
                                        className="procces_Button"
                                        onClick={() => handleFetchPayment(status, currency)}
                                    >
                                        Apply filter
                                    </Button>
                                </div>
                            </div>
                            <Card className="mt-5">
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={columns}
                                        data={sortedProducts}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                columns={columns}
                                                data={products}
                                                search
                                                exportCSV
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="4">
                                                                <div className="search-box me-2 mb-2 d-inline-block">
                                                                    <div className="position-relative">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        classes={` table align-middle table-nowrap  ${rowHighlights ? "custom-table" : ""}`}
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        style={{
                                                                            fontWeight: "Gilroy",
                                                                        }}
                                                                        headerClasses={"custom-header-class"}
                                                                        rowStyle={{
                                                                            fontSize: "15px",
                                                                            fontFamily: "Gilroy",
                                                                            fontWeight: "400",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="d-inline">
                                                                    <SizePerPageDropdownStandalone {...paginationProps} />
                                                                </div>
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone {...paginationProps} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ZesaList;
