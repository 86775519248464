import React, { useState } from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, TextField } from '@mui/material';
import { Button, } from 'reactstrap';
import { uploadCsvGenerateQuckQrCode } from 'Context/ContextApi';
import toastr from "toastr";
import "toastr/build/toastr.min.css";

const QuickManualTable = () => {
  const [rows, setRows] = useState([
    { Invoice_Reference: '', Product_name: '', Min_Quantity: '', Max_Quantity: '', price_Per_ZWG: '', Price_Per_USD: '' }
  ]);

  const [errors, setErrors] = useState([]);
  const validateField = (name, value) => {
    switch (name) {
      case 'Invoice_Reference':
        if (!value) return 'Invoice Reference is required';
        if (value.length > 15) return 'Invoice Reference must be 15 characters or less';
        return '';

      case 'Product_name':
        if (!value) return 'Product Name is required';
        if (value.length > 40) return 'Product Name must be 40 characters or less';
        return '';

      case 'Min_Quantity':
        return value > 0 ? '' : 'Min Quantity must be greater than 0';

      case 'Max_Quantity':
        return value > 0 ? '' : 'Max Quantity must be greater than 0';

      case 'price_Per_ZWG':
        return value > 0 ? '' : 'Amount in ZIG must be greater than 0';

      case 'Price_Per_USD':
        return value > 0 ? '' : 'Amount in USD must be greater than 0';

      default:
        return '';
    }
  };


  // const handleInputChange = (index, event) => {
  //   const { name, value } = event.target;
  //   const newRows = [...rows];
  //   newRows[index][name] = value;
  //   setRows(newRows);
  // };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const newRows = [...rows];
    newRows[index][name] = value;
    setRows(newRows);
    const errorMessage = validateField(name, value);
    const newErrors = [...errors];
    newErrors[index] = { ...newErrors[index], [name]: errorMessage };
    setErrors(newErrors);
  };


  const handleAddRow = () => {
    setRows([...rows, { Invoice_Reference: '', Product_name: '', Min_Quantity: '', Max_Quantity: '', price_Per_ZWG: '', Price_Per_USD: '' }]);
  };


  const handleRemoveRow = (index) => {
    const newRows = [...rows];
    newRows.splice(index, 1);
    setRows(newRows);

    const newErrors = [...errors];
    newErrors.splice(index, 1);
    setErrors(newErrors);
  };

  // const handleRemoveRow = (index) => {
  //   const newRows = [...rows];
  //   newRows.splice(index, 1);
  //   setRows(newRows);
  // };

  const handleSubmit = async () => {
    try {

      let hasErrors = false;
      const newErrors = rows.map(row => {
        const rowErrors = {};
        Object.keys(row).forEach(key => {
          const errorMessage = validateField(key, row[key]);
          if (errorMessage) hasErrors = true;
          rowErrors[key] = errorMessage;
        });
        return rowErrors;
      });
      setErrors(newErrors);
      if (hasErrors) {
        toastr.error('Please fix validation errors before submitting');
        return;
      }
      const formData = {
        Results: rows,
        Type: "manal",
      }
      const response = await uploadCsvGenerateQuckQrCode(formData);
      toastr.success(response.message)
      setRows([])
    } catch (error) {
      toastr.error(error?.response?.data?.message || error?.message)
      console.error('Error saving data:', error);
    }
  };
  return (
    <React.Fragment>
      <Table >
        <TableHead>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold' }}>Invoice Ref</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Min Quantity</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Max Quantity</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Amount in ZIG</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Amount in USD</TableCell>
            <TableCell sx={{ fontWeight: 'bold' }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => (
            <TableRow key={index}>
              <TableCell>
                <TextField
                  name="Invoice_Reference"
                  value={row.Invoice_Reference}
                  label="Invoice Ref"
                  onChange={(event) => handleInputChange(index, event)}
                  variant="outlined"
                  className="form_group_quick_edit_input"
                  error={!!errors[index]?.Invoice_Reference}
                  helperText={errors[index]?.Invoice_Reference}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="Product_name"
                  value={row.Product_name}
                  label="Product Name"
                  onChange={(event) => handleInputChange(index, event)}
                  variant="outlined"
                  className="form_group_quick_edit_input"
                  error={!!errors[index]?.Product_name}
                          helperText={errors[index]?.Product_name}
                />
              </TableCell>
              <TableCell>
                <TextField
                  name="Min_Quantity"
                  value={row.Min_Quantity}
                  label="Min Quantity"
                  type="number"
                  onChange={(event) => handleInputChange(index, event)}
                  variant="outlined"
                  className="form_group_quick_edit_input"
                  error={!!errors[index]?.Min_Quantity}
                  helperText={errors[index]?.Min_Quantity}
                />
              </TableCell>
              <TableCell>
                <TextField
                  className="form_group_quick_edit_input"
                  name="Max_Quantity"
                  value={row.Max_Quantity}
                  label="Max Quantity"
                  type="number"
                  onChange={(event) => handleInputChange(index, event)}
                  variant="outlined"
                  error={!!errors[index]?.Max_Quantity}
                  helperText={errors[index]?.Max_Quantity}
                />
              </TableCell>
              <TableCell>
                <TextField
                  className="form_group_quick_edit_input"
                  name="price_Per_ZWG"
                  value={row.price_Per_ZWG}
                  label="Amount in ZIG"
                  type="number"
                  onChange={(event) => handleInputChange(index, event)}
                  variant="outlined"
                  error={!!errors[index]?.price_Per_ZWG}
                  helperText={errors[index]?.price_Per_ZWG}
                />
              </TableCell>
              <TableCell>
                <TextField
                  className="form_group_quick_edit_input"
                  name="Price_Per_USD"
                  value={row.Price_Per_USD}
                  label="Amount in USD"
                  type="number"
                  onChange={(event) => handleInputChange(index, event)}
                  variant="outlined"
                  error={!!errors[index]?.Price_Per_USD}
                  helperText={errors[index]?.Price_Per_USD}
                />
              </TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddRow}
                    sx={{ minWidth: '40px', marginRight: 1 }}
                  >
                    +
                  </Button>
                  {rows.length > 1 && (
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => handleRemoveRow(index)}
                      sx={{ minWidth: '40px' }}
                    >
                      -
                    </Button>
                  )}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>


      <div className='upload_csv_Submit_button '>
        <Button
          variant="contained"
          className='procces_Button'
          fullWidth
          sx={{ mt: 12 }}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </div>
    </React.Fragment>
  );
};

export default QuickManualTable;