import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Button,
    Input,
    InputGroup,
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
    PaginationProvider,
    PaginationListStandalone,
    SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";
import ToolkitProvider, {
    Search,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";
import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import { Link } from "react-router-dom";

import edit from "../../../assets/images/NewImage/writing.png";
import "flatpickr/dist/themes/material_blue.css";
import deleteimg from "../../../assets/images/NewImage/supprimer.png"
import { useHistory } from "react-router-dom";
import { changeDevicesStatus, deleteDevice, deleteMCounterQRCode, deletePermission, fDevices, fQrCodeList } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import Breadcrumbs from "components/Common/Breadcrumb";
import viewIcon from "../../../assets/images/NewImage/icons8-eye-64.png"
import { ViewModal } from "./viewModal";
import NumberInputModal from "./sendQrModal";
import Toggle from "react-toggle";
import { generateMerchantKey, qrDecryptCJS } from "Context/ecrypt-Response";

const ListOfDevices = () => {
    const history = useHistory();
    const { iPAddress, userDataa } = useData();
    const [data, setData] = useState([]);
    const [modalData, setModalData] = useState({})
    const [isOpen, setIsOpen] = useState(false)
    const [qrCode, setQrCode] = useState('')

    useEffect(() => { handleFetch() }, [!data])

    const handleFetch = async () => {
        try {
            const response = await fDevices();
            // const decryptKey = await generateMerchantKey('221122')
            // const decryptData = await qrDecryptCJS(response.data, decryptKey)
            // const parsedata = JSON.parse(decryptData)
            setData(response.result)
        } catch (error) {
            console.log(error);
        }
    }
    const handleDelete = async (id) => {
        try {
            const formData = { id: id }
            const response = await deleteDevice(formData)
            toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
            handleFetch();
        } catch (error) {
            console.log(error);
        }
    }

    const handleToggle = async (id, isChecked) => {
        try {
            const formData = {
                id: id,
                Status: `${isChecked ? "ACTIVE" : "INACTIVE"}`
            }
            const response = await changeDevicesStatus(formData)
            // const decryptKey = await generateMerchantKey('221122')
            // const decryptData = await qrDecryptCJS(response.data, decryptKey)
            // const parseData = JSON.parse(decryptData)
            toastr.success(response.message)
        } catch (error) {
            console.log(error); 
        }
    };

    const handleView = async (item, qrdata) => {
        setModalData(item)
        setQrCode(qrdata)
        setIsOpen(true)
    }


    const columns = [
        {
            dataField: "TimeStamp",
            text: "Date",
            sort: true,
            formatter: (cell, row) => {
                const options = {
                    year: "numeric",
                    month: "short",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    second: "numeric",
                    hour12: false,
                };
                return new Date(cell).toLocaleString("en-US", options);
            },
        },
        {
            dataField: "CounterName",
            text: "Counter Name",
            sort: true,
        },
        {
            dataField: "DeviceId",
            text: "Device Id",
            sort: true,
        },
        {
            dataField: "View",
            text: "QR Internal ",
            sort: true,
            formatter: (cell, row) => (
                <span style={{ color: row.status === "Success" ? "green" : "red", display: "flex", gap: "5px" }}>
                    <img src={viewIcon} alt="delete" style={{ width: "25px", cursor: "pointer" }} onClick={() => handleView(row, row.I_QRCode)} />
                </span>
            ),
        },
        {
            dataField: "View",
            text: "QR External",
            sort: true,
            formatter: (cell, row) => (
                <span style={{ color: row.status === "Success" ? "green" : "red", display: "flex", gap: "5px" }}>
                    <img src={viewIcon} alt="delete" style={{ width: "25px", cursor: "pointer" }} onClick={() => handleView(row, row.X_QRCode)} />
                </span>
            ),
        },
        {
            dataField: "Status",
            text: "Status",
            sort: true,
            formatter: (cell, row) => (
                <div className="campaign_check_box_Active_InActive">
                    <Toggle
                        aria-labelledby='biscuit-label'
                        id={`toggle-${row.id}`}
                        defaultChecked={row.Status === "ACTIVE"}
                        onChange={(e) => handleToggle(row.id, e.target.checked)}
                    />
                </div>
            ),
        },
        {
            dataField: "edit",
            text: "Action",
            sort: true,
            formatter: (cell, row) => (
                <span style={{ color: row.status === "Success" ? "green" : "red", display: "flex", gap: "5px" }}>
                    <img src={deleteimg} alt="delete" style={{ width: "25px", cursor: "pointer" }} onClick={() => handleDelete(row.id)} />
                </span>
            ),
        },
    ];

    const defaultSorted = [
        {
            dataField: "id",
            order: "asc",
        },
    ];

    const pageOptions = {
        sizePerPage: 10,
        totalSize: data.length,
        custom: true,
    };

    const selectRow = {
        mode: "checkbox",
    };

    const { SearchBar } = Search;
    document.title = "GET Platform  | Devices";
    const handleCancel = () => {
        setIsOpen(false)
    }

    return (
        <React.Fragment>
            <ViewModal isOpen={isOpen} qrCode={qrCode} data={modalData} userDataa={userDataa} handleCancel={handleCancel} />
            <div
                className="page-content"
                style={{ marginLeft: "5%", marginRight: "5%" }}
            >
                <div className="container">
                    <Breadcrumbs title="GET" breadcrumbItem="Devices" />
                    <Row>
                        <Col md={{ offset: 3, size: 6 }}>
                            <h1 className="text-center all_newPaeHeading title-color-common">
                                DEVICES
                            </h1>
                            <h5 className="text-center all_sub_new_heading description-color">
                                View the list of all devices you have added
                            </h5>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="10">
                            <h5 className="mb-3 sub_heading_left_side_the_page text-start">
                                All list of devices
                            </h5>
                        </Col>
                    </Row>
                    <Row className="mt-1">
                        <Col>
                            <Card className="mt-5">
                                <CardBody>
                                    <PaginationProvider
                                        pagination={paginationFactory(pageOptions)}
                                        keyField="id"
                                        columns={columns}
                                        data={data}
                                    >
                                        {({ paginationProps, paginationTableProps }) => (
                                            <ToolkitProvider
                                                keyField="id"
                                                columns={columns}
                                                data={data}
                                                search
                                            >
                                                {toolkitProps => (
                                                    <React.Fragment>
                                                        <Row className="mb-2">
                                                            <Col md="12">
                                                                <div className="search-box me-2 mb-2  d-flex">
                                                                    <div className="d-inlineo" >
                                                                        <SizePerPageDropdownStandalone
                                                                            {...paginationProps}
                                                                        />
                                                                    </div>
                                                                    <div className="position-relative  text-md-right ms-auto">
                                                                        <SearchBar {...toolkitProps.searchProps} />
                                                                        <i className="bx bx-search-alt search-icon" />
                                                                    </div>
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xl="12">
                                                                <div className="table-responsive">
                                                                    <BootstrapTable
                                                                        keyField={"id"}
                                                                        responsive
                                                                        bordered={false}
                                                                        striped={false}
                                                                        defaultSorted={defaultSorted}
                                                                        selectRow={selectRow}
                                                                        classes={"table align-middle table-wrap"}
                                                                        headerWrapperClasses={"thead-light"}
                                                                        {...toolkitProps.baseProps}
                                                                        {...paginationTableProps}
                                                                        style={{ fontWeight: "Gilroy", }}
                                                                        headerClasses={"custom-header-class"}
                                                                        rowStyle={{
                                                                            fontSize: "15px",
                                                                            fontFamily: "Gilroy",
                                                                            fontWeight: "400",
                                                                        }}
                                                                    />
                                                                </div>
                                                            </Col>
                                                        </Row>

                                                        <Row className="align-items-md-center mt-30">
                                                            <Col className="inner-custom-pagination d-flex">
                                                                <div className="text-md-right ms-auto">
                                                                    <PaginationListStandalone {...paginationProps} />
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </React.Fragment>
                                                )}
                                            </ToolkitProvider>
                                        )}
                                    </PaginationProvider>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>
        </React.Fragment>
    );
};

export default ListOfDevices;
