import React, { useEffect, useRef, useState } from "react";
import { Button, Card, Col, Container, FormGroup, Input, Label, Modal, Row, Spinner } from "reactstrap";
import toastr, { error } from "toastr";
import "toastr/build/toastr.min.css";
import wheelLogo from "../../../../assets/images/NewImage/Vault_Wheel.png"
import { cancelPaymentRequest, rejectUserZesaPayment, requestPaymentForExternal, updateUserZesaToken } from "Context/ContextApi";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import Breadcrumbs from "components/Common/Breadcrumb";
import cancelImage from "../../../../assets/images/NewImage/cancel.png"
import infoImage from "../../../../assets/images/NewImage/info.png"
import faCopy from "../../../../assets/images/NewImage/copy.png"
import { CopyToClipboard } from "react-copy-to-clipboard";
import { io } from "socket.io-client";

const API_BASE_URL = process.env.REACT_APP_API_URL;

export const ZesaPyamentsDetails = ({ data, iPAddress, mData, userDataa }) => {
    const history = useHistory();
    const [loader, setLoader] = useState(false);
    const [isDisabled, setIsDisabled] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [showPopup, setShowPopup] = useState(false);
    const [popupData, setPopupData] = useState('');
    const socketRef = useRef(null);
    const [sendForm, setSendForm] = useState({
        InvoiceId: "",
        Token: ''
    });

    const jsonParseData = data?.Data?.Tax_Details ? JSON.parse(data?.Data?.Tax_Details) : null

    useEffect(() => {
        if (data?.Data && (data?.Data?.Status === 'Completed' || data?.Data?.Status === 'Rejected')) {
            setSendForm({ InvoiceId: "", Token: "", })
            history.push(`/payment-details/${data?.Data?.TransactionId}`)
        }
        if (data?.Data) {
            setSendForm({ ...sendForm, ...data?.Data })
        }
    }, [data?.Data])


    useEffect(() => {
        if (data?.Data?.id) {
            socketRef.current = io(API_BASE_URL, { query: { orderId: data.Data.id }, });

            socketRef.current.on('connect', () => {
                // console.log('Connected to the socket with UserId:', data.Data.id);
            });
            socketRef.current.on('disconnect', () => {
                // console.log('Socket disconnected');
            });
            return () => {
                socketRef.current.disconnect();
            };
        }
    }, [data?.Data?.id]);

    const handleChanges = (e) => {
        const { name, value } = e.target;
        setSendForm((prevForm) => {
            let updatedForm = { ...prevForm, [name]: value };
            return updatedForm;
        });
        setValidationErrors((prevErrors) => ({
            ...prevErrors,
            [name]: undefined,
        }));
    };

    const validateForm = () => {
        let errors = {};

        if (!sendForm.Token || isNaN(sendForm.Token) || sendForm.Token <= 0) {
            errors.Token = "Please enter a valid Token.";
        } else if (sendForm.Token.length !== 20) {
            errors.Token = "Token must be exactly 20 characters.";
        }
        if (!sendForm.InvoiceId) {
            errors.InvoiceId = "Please enter a valid Invoice Id.";
        }
        setValidationErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async () => {
        try {
            const isValid = validateForm();
            if (!isValid) return;
            setLoader(true)
            const formData = {
                InvoiceId: sendForm?.InvoiceId,
                IPAddress: iPAddress,
                TransactionId: data?.Data?.TransactionId,
                UserId: data?.Data?.UserId,
            };
            const response = await updateUserZesaToken(formData)
            setIsDisabled(true)
            if (response.status === "success") {
                toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
                history.push("/orders")
            } else if (response.status === 'failed') {
                toastr.warning(response.message, "warning", { timeOut: 5000, progressBar: true, closeDuration: 500, positionClass: "toast-top-right" });
                setIsDisabled(false)
            }
        } catch (error) {
            setLoader(false)
            setIsDisabled(false)
            toastr.error(error?.response?.data?.message || error?.message)
        }
    }

    const handleCancel = async () => {
        try {
            const formData = {
                IPAddress: iPAddress,
                TransactionId: data?.Data?.TransactionId,
                UserId: data?.Data?.UserId,
            };
            const response = await rejectUserZesaPayment(formData)
            history.push("/orders")
            toastr.success(response.message)
            if (response.status === "success") {
                toastr.success(response.message, "Success", { timeOut: 7000, progressBar: true, closeDuration: 700, positionClass: "toast-top-right" });
            } else if (response.status === 'failed') {
                toastr.warning(response.message, "warning", { timeOut: 5000, progressBar: true, closeDuration: 500, positionClass: "toast-top-right" });
            }
        } catch (error) {
            toastr.error(error?.response?.data?.message || error?.message)
        }
    }


    const handleShowPopup = (title, content) => {
        setPopupData(content);
        setShowPopup(true);
    };
    const handleClosePopup = () => {
        setShowPopup(false);
    };

    const [copied, setCopied] = useState(false);

    const handleCopy = (text) => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000);
    };

    return (
        <React.Fragment>
            {showPopup && (
                <div className="transaction_popup_main">
                    <div className="Refund-popup-content">
                        <span className="close" onClick={handleClosePopup}>
                            &times;
                        </span>
                        <div className="offers-main-container  ">
                            <div className="offer-module-text">
                                <span>Offer Details </span>
                                <span>
                                    <img src={cancelImage} alt="cancel" onClick={() => setShowPopup(false)} />
                                </span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Offers Name</span>
                                <span>{popupData.OffersName}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Type</span>
                                <span>{popupData.Type}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Method</span>
                                <span>{popupData.Method}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Start Date</span>
                                <span>{new Date(popupData?.StartDate).toLocaleString()}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>End Date</span>
                                <span>{new Date(popupData?.EndDate).toLocaleString()}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Discount Percentage</span>
                                <span>% {popupData.DiscountPercentage} </span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Allowed Maximum Discount</span>
                                <span>{data?.Data?.Currency} {popupData.AllowedMaxDiscount}</span>
                            </div>
                            <div className="offer-module-text ps-3 pe-3">
                                <span>Create On</span>
                                <span>{new Date(popupData?.TimeStamp).toLocaleString()}</span>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            <div className="page-content" >
                <div className="container-fluid">
                    <Breadcrumbs title="GET" breadcrumbItem="Zesa Payment" />
                    <Row className="justify-content-center mb-5">
                        <Col xl="6">
                            <Card className="p-4">
                                {data && Object.keys(data).length === 0 ? (
                                    <div className="d-flex justify-content-between aligh-center">
                                        <p>No Request Available</p>
                                    </div>
                                ) : (
                                    <>
                                        <hr />
                                        <h5 className="card-title">User Details</h5>
                                        <Row className="justify-content-center scan-pay-user-details">
                                            <Col xl="4">
                                                <div style={{
                                                    width: "200px",
                                                    height: "200px",
                                                    position: "relative",
                                                }}
                                                >
                                                    <img
                                                        src={data?.Data?.ProfileImage ? `${API_BASE_URL}/${data?.Data?.ProfileImage}` : wheelLogo}
                                                        alt="profile"
                                                        className="avatar-md rounded-circle img-thumbnail fullwidth"
                                                        style={{
                                                            width: "100%",
                                                            height: "100%",
                                                            objectFit: "fit",
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                            <Col xl="4">
                                                <p>Name : <span>{data?.Data?.FirstName || ""} {data?.Data?.LastName || ""}</span></p>
                                                <p>Mobile : <span>{data?.Data?.Mobile || ""}</span></p>
                                                <p>VPA : <span>{data?.Data?.User_VPA || ""}</span></p>
                                                <p>Date : <span>{new Date(data?.Data?.TimeStamp).toLocaleString() || ""}</span></p>
                                                <p>Status : <span className={` ${data?.Data?.Status === 'Completed' ? "status-completed" : 'status-pending'}`}>{data?.Data?.Status || ""}</span></p>
                                                {data?.Data?.Status === 'In Progress' && <span className="ps-5 pt-0">(Payment on hold)</span>}
                                            </Col>
                                            <Col xl="4">
                                                <p>FullName : <span><b>{data?.Data?.FullName || ""}</b> </span></p>
                                                <p>Meter :
                                                    <span>
                                                        <b>{data?.Data?.Meter || ""}</b>{''}
                                                        <CopyToClipboard text={data?.Data?.Meter || ""} onCopy={() => handleCopy(data?.Data?.Meter || "")}>
                                                            <button title="Copy Meter" className="copy-image ms-2">
                                                                <img src={faCopy} />
                                                            </button>
                                                        </CopyToClipboard>
                                                    </span>
                                                </p>
                                                <p>Mobile : <span><b>{data?.Data?.Mobile || ""}</b>
                                                    <CopyToClipboard text={data?.Data?.Mobile || ""} onCopy={() => handleCopy(data?.Data?.Mobile || "")}>
                                                        <button title="Copy Meter" className="copy-image ms-2">
                                                            <img src={faCopy} />
                                                        </button>
                                                    </CopyToClipboard>
                                                </span></p>
                                            </Col>
                                        </Row>
                                        <hr />

                                        <Col md={{ offset: 1, size: 10, }}>
                                            <Row>
                                                <Col sm="12" xs="12" className="">
                                                    <div className="refund_payment_details mb-0">
                                                        <h2 > Transaction details</h2>
                                                        <h3> UTR : {data?.Data?.UTR}</h3>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <div className="deposit_money_secondStep_form">
                                                {Array.isArray(jsonParseData) && jsonParseData.map((item, index) => (
                                                    <div key={index}>
                                                        <div className={`tax-details-transacrtion-main-container ${item.TotalTitle === 'Net Total' || item.TotalTitle === 'Net Refund' ||
                                                            item.Title === 'Refund Net Tax' || item.Title === 'Net Tax' || item.TotalTitle === 'Refund Net Total' ? ' deposite_money_second_step_text_border_line' : ''}`}>
                                                            {item.Title ? (
                                                                <span>{item.Title}
                                                                    {item.Title === "Offer Applied" && (
                                                                        <img src={infoImage} onClick={() => handleShowPopup("Offer Details", (jsonParseData.find(item => item.OfferDetails === 'Offer Details').Value))} className="info-icon-for-offers" />
                                                                    )}
                                                                </span>
                                                            ) : (
                                                                item.TotalTitle && <span>{item.TotalTitle} </span>
                                                            )}
                                                            {(typeof item.Value !== 'object' && item.Value !== undefined) && <div className="tax-details-Amount"><span>{data?.Data?.Currency}</span> <span>{parseFloat(item.Value).toFixed(2)}</span></div>}

                                                        </div>
                                                        {Array.isArray(item.Tax) && item.Tax.map((taxItem, taxIndex) => (
                                                            <div key={taxIndex} className="transaction_details ps-4">
                                                                <div className="transaction_detailsForTaxDetals">
                                                                    <div className="transaction_detail_refund">
                                                                        <div className="d-flex gap-2">
                                                                            <label>{taxItem.Particular}</label>
                                                                            {taxItem.Percentage && <small>{taxItem.Percentage} %</small>}
                                                                        </div>
                                                                        <div>
                                                                            {taxItem.RefundRate && <small>Refund Rate ({taxItem.RefundRate} %)</small>}
                                                                        </div>
                                                                    </div>

                                                                    <div className="transaction_detail_Amount">
                                                                        <span>{data?.Data?.Currency} {parseFloat(taxItem.NetTotal).toFixed(2)}</span>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ))}
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                        <FormGroup>
                                            <div className="form_group_for_inside_in_deposit mt-4">
                                                <Label for="InvoiceId" >Invoice id</Label>
                                                <Input
                                                    type="text"
                                                    id="InvoiceId"
                                                    name="InvoiceId"
                                                    required
                                                    placeholder="Enter Invoice id"
                                                    value={sendForm.InvoiceId}
                                                    onChange={handleChanges}
                                                    invalid={!!validationErrors.InvoiceId}
                                                    disabled={isDisabled}
                                                />
                                                {validationErrors?.InvoiceId && <div className="text-danger">{validationErrors?.InvoiceId}</div>}
                                            </div>
                                        </FormGroup>
                                        <FormGroup>
                                            <div className="form_group_for_inside_in_deposit">
                                                <Label for="InvoiceId" >Token</Label>
                                                <Input
                                                    type="number"
                                                    id="Token"
                                                    name="Token"
                                                    required
                                                    placeholder="Enter Token"
                                                    value={sendForm.Token}
                                                    onChange={handleChanges}
                                                    invalid={!!validationErrors.Token}
                                                    disabled={isDisabled}
                                                />
                                                {validationErrors?.Token && <div className="text-danger">{validationErrors?.Token}</div>}
                                            </div>
                                        </FormGroup>
                                        <Button className="procces_Button" disabled={loader} onClick={() => handleSubmit()}>
                                            Complete Payment
                                        </Button>
                                        <Button className="procces_Butto mt-3" style={{ fontFamily: "Gilroy", fontSize: "18px" }} onClick={() => handleCancel()}>Reject Payment</Button>
                                    </>
                                )}
                            </Card>
                        </Col>
                    </Row>

                </div>
            </div>
        </React.Fragment>
    );
}
