import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Input,
  InputGroup,
  Modal,
} from "reactstrap";

import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
  SizePerPageDropdownStandalone,
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, {
  Search, CSVExport
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import "flatpickr/dist/themes/material_blue.css";
import Flatpickr from "react-flatpickr";
// Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { fetchmerchantPayment } from "Context/ContextApi";
import ReactToPrint from "react-to-print";

import "jspdf-autotable";
import { useData } from "Context/ContextProvider";
import arrowicon from "../../assets/images/NewImage/skip-track.png"
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";

const API_BASE_URL = process.env.REACT_APP_API_URL
const Payments = () => {
  const { userDataa, mData, socketTransaction } = useData();
  const history = useHistory();
  const [products, setProducts] = useState([])
  const [status, setStatus] = useState('')
  const [currency, setCurrency] = useState('')
  const [businesName, setBusinesName] = useState({})
  const [actionData, setActionData] = useState({})
  const [dateRange, setDateRange] = useState([]);
  const [totalAmount, setTotalAmount] = useState({});
  const [rowHighlights, setRowHighlights] = useState(false)

  useEffect(() => {
    let jsonparse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
    setActionData(jsonparse)
  }, [mData?.subResult?.Permissions])

  const handleDateChange = (selectedDates) => {
    setDateRange(selectedDates);
    if (dateRange[0] && dateRange[1]) {
      handleFetchPayment(status, currency);
    }
  };

  const statusOptions = [
    { value: '', label: 'All' },
    { value: 'completed', label: 'Successfull' },
    { value: 'pending', label: 'Pending' },
    { value: 'Rejected', label: 'Rejected' },
    { value: 'failed', label: 'Failed' },
  ];

  const currencyOptions = [
    { value: '', label: 'All' },
    { value: 'USD', label: 'USD' },
    { value: 'ZiG', label: 'ZiG' },
  ];

  const sortedProducts = [...products];
  sortedProducts.sort((a, b) => a.Status.localeCompare(b.Status));


  useEffect(() => {
    handleFetchPayment(status, currency);
  }, [userDataa])

  useEffect(() => {
    if (socketTransaction) {
      setProducts(prev => [...socketTransaction, ...prev]);
      setRowHighlights(true);
      setTimeout(() => {
        setRowHighlights(false);
      }, 3000);
      if (socketTransaction && socketTransaction[0]?.Payment_type == 'merchant transfer') {
        history.push("/payments");
      }
    }
  }, [socketTransaction])

  const handleFetchPayment = async (Status, Currency) => {
    try {
      const formData = {
        status: Status,
        currency: Currency,
        fromDate: new Date(dateRange[0]),
        toDate: new Date(dateRange[1])
      }
      const response = await fetchmerchantPayment(formData)
      setProducts(response.result)
      setBusinesName(response.userInfo)
      setTotalAmount(response.TotalValue)
    } catch (error) {
      console.log(error);
    }
  }

  const columns = [
    {
      dataField: "Transaction_date",
      text: "Date",
      sort: true,
      formatter: (cell, row) => {
        const options = {
          year: "numeric",
          month: "short",
          day: "numeric",
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: false,
        };
        return new Date(cell).toLocaleString("en-US", options);
      },
    },
    {
      dataField: "UTR",
      text: "UTR",
      sort: true,
    },
    {
      dataField: "sender_name",
      text: "Payer",
      sort: true,
    },
    {
      dataField: "Sender_vpa",
      text: "Payer VPA",
      sort: true
    },
    {
      dataField: "NetPaid",
      text: "Net Paid",
      sort: true,
    },
    {
      dataField: "Status",
      text: "Status",
      sort: true,
      formatter: (cell, row) => (
        <span
          style={{
            color: row.Status === "completed" ? "green" : "red",
            fontFamily: "Gilroy",
            fontWeight: "600",
            fontSize: "18px",
            textTransform: "uppercase",
          }}
        >
          {cell}
        </span>
      ),
    },
    {
      dataField: "details",
      text: "Details",
      sort: false,
      formatter: (cell, row) => (
        <div>
          <Link to={`/payment-details/${row?.id}`}>
            <p
              className="Details_popup"
            >
              <img src={arrowicon} alt="icon" />
            </p>
          </Link>
        </div>
      ),
    },
  ];

  const defaultSorted = [
    {
      dataField: "id",
      order: "asc",
    },
  ];

  const pageOptions = {
    sizePerPage: 10,
    totalSize: products.length,
    custom: true,
  };

  const { SearchBar } = Search;

  const componentRef = useRef();

  const handleReset = () => {
    setStatus("")
    setCurrency("")
    setDateRange([])
    handleFetchPayment(status)
  }
  // Meta title
  document.title = "GET Platform  | Payments";
  return (
    <React.Fragment>
      <div
        className="page-content"
        style={{ marginLeft: "5%", marginRight: "5%" }}
      >
        <div className="container-fluid">
          <Breadcrumbs title="GET" breadcrumbItem="Payments" />
          <Row>
            <Col md={{ offset: 3, size: 6, }} >
              <h1 className="text-center all_newPaeHeading title-color-common">PAYMENTS</h1>
              <h5 className="text-center all_sub_new_heading description-color">
                List of all payments you received from customers
              </h5>
            </Col>
          </Row>
          <Col>
            <h5>Total payment in USD <b>{totalAmount.total_usd}</b></h5>
            <h5>Total payment in ZiG <b>{totalAmount.total_zig}</b></h5>
          </Col>
          <Row className="mt-5">
            <Col>
              <div style={{ display: "flex", justifyContent: "space-between", width: "100%", }}>
                <div style={{ width: "80%", display: "flex", gap: "10px", flexWrap: "wrap" }}>
                  <div className="filtter_Date_range">
                    <InputGroup >
                      <Flatpickr
                        className="date_picker"
                        placeholder="Select date range"
                        options={{
                          mode: "range",
                          dateFormat: "Y-m-d",
                        }}
                        style={{
                          borderRadius: "7px",
                          border: "1px solid black",
                          width: "100%",
                          fontFamily: "Gilroy",
                          color: "black",
                        }}
                        value={dateRange}
                        onChange={handleDateChange}
                      />
                    </InputGroup>
                  </div>
                  <select className="filtter_select_box" name="status" value={status}
                    onChange={(e) => {
                      const selectedStatus = e.target.value;
                      setStatus(selectedStatus);
                      handleFetchPayment(selectedStatus, currency);
                    }}
                  >
                    <option defaultValue hidden>Status</option>
                    {statusOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                  <select className="filtter_select_box" name="currency" value={currency}
                    onChange={(e) => {
                      const selectedStatus = e.target.value;
                      setCurrency(selectedStatus);
                      handleFetchPayment(status, selectedStatus);
                    }}
                  >
                    <option defaultValue hidden>Currency</option>
                    {currencyOptions.map((option) => (
                      <option key={option.value} value={option.value}>
                        {option.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    gap: "20px",
                  }}
                >
                  <Button
                    type="submit"
                    className="procces_Button"
                    onClick={handleReset}
                  >
                    Reset
                  </Button>
                  <Button
                    type="submit"
                    className="procces_Button"
                    onClick={() => handleFetchPayment(status, currency)}
                  >
                    Apply filter
                  </Button>
                </div>
              </div>
              <Card className="mt-5">
                <CardBody>
                  <PaginationProvider
                    pagination={paginationFactory(pageOptions)}
                    keyField="id"
                    columns={columns}
                    data={sortedProducts}
                  >
                    {({ paginationProps, paginationTableProps }) => (
                      <ToolkitProvider
                        keyField="id"
                        columns={columns}
                        data={products}
                        search
                        exportCSV
                      >
                        {toolkitProps => (
                          <React.Fragment>
                            <Row className="mb-2">
                              <Col md="4">
                                <div className="search-box me-2 mb-2 d-inline-block">
                                  <div className="position-relative">
                                    <SearchBar {...toolkitProps.searchProps} />
                                    <i className="bx bx-search-alt search-icon" />
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xl="12">
                                <div className="table-responsive">
                                  <BootstrapTable
                                    // ref={tableRef}
                                    // id="my-table"
                                    keyField={"id"}
                                    responsive
                                    bordered={false}
                                    striped={false}
                                    defaultSorted={defaultSorted}
                                    // selectRow={selectRow}
                                    classes={` table align-middle table-nowrap  ${rowHighlights ? "custom-table" : ""}`}
                                    headerWrapperClasses={"thead-light"}
                                    {...toolkitProps.baseProps}
                                    {...paginationTableProps}
                                    style={{
                                      fontWeight: "Gilroy",
                                    }}
                                    headerClasses={"custom-header-class"}
                                    // rowClasses="custom-row-class"
                                    rowStyle={{
                                      fontSize: "15px",
                                      fontFamily: "Gilroy",
                                      fontWeight: "400",
                                    }}
                                  />
                                </div>
                              </Col>
                            </Row>

                            <Row className="align-items-md-center mt-30">
                              <Col className="inner-custom-pagination d-flex">
                                <div className="d-inline">
                                  <SizePerPageDropdownStandalone
                                    {...paginationProps}
                                  />
                                </div>
                                <div className="text-md-right ms-auto">
                                  <PaginationListStandalone
                                    {...paginationProps}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </React.Fragment>
                        )}
                      </ToolkitProvider>
                    )}
                  </PaginationProvider>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ReactToPrint content={() => componentRef.current} />
    </React.Fragment>
  );
};

export default Payments;
