import React from 'react';

const PaymentPageStatusColor = ({ data }) => {

    let statusMessage = '';
    let statusClass = '';
    switch (data.Status) {
        case 'Completed':
            statusMessage = 'Completed';
            statusClass = 'status-completed';
            break;
        case 'Cancelled':
            statusMessage = 'Cancelled';
            statusClass = 'status-cancelled';
            break;
        case 'Requested':
            statusMessage = 'Requested';
            statusClass = 'status-expired';
            break;
        case 'Retry':
            statusMessage = 'Retry';
            statusClass = 'status-pending';
            break;
        case 'Initiate':
            statusMessage = 'Initiate';
            statusClass = 'status-pending';
            break;
        case 'In Progress':
            statusMessage = 'In Progress';
            statusClass = 'status-In-Progress';
            break;
        case 'Rejected':
            statusMessage = 'Rejected';
            statusClass = 'status-rejected';
            break;
        default:
            statusMessage = data.Status;
            statusClass = 'status-unknown';
            break;
    }

    return (
        <span className={`payment_page_status ${statusClass}`}>
            {statusMessage}
        </span>
    );
};

export default PaymentPageStatusColor;