import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link, useHistory } from "react-router-dom";
import { dashboardLockApi, fetchUserProfile } from "Context/ContextApi";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import { useData } from "Context/ContextProvider";
import wheelLogo from "../../../assets/images/NewImage/Vault_Wheel.png"
import Cookies from "js-cookie";
import { io } from "socket.io-client";

const API_BASE_URL = process.env.REACT_APP_API_URL;

const getUserName = () => {
  if (localStorage.getItem("authUsers")) {
    const obj = JSON.parse(localStorage.getItem("authUsers"))
    return obj;
  }
}

const ProfileMenu = ({ t, success }) => {
  const { mData, userDataa, userInfo , socket, deviceId} = useData();
  const subJasonParse = mData?.subResult?.Permissions ? JSON.parse(mData?.subResult?.Permissions) : null;
  const [menu, setMenu] = useState(false);
  const [name, setName] = useState("Admin");

  const toggle = () => {
    setMenu(prevMenu => !prevMenu);
  };

  useEffect(() => {
    const userData = getUserName();
    if (userData) {
      setName(userData.username);
    }
  }, []);

  useEffect(() => {
    const userData = getUserName();
    if (userData && success) {
      setName(userData.username);
    }
  }, [success]);

  const handleLock = async () => {
    try {
      const formData = {
        ScreenLock: 'LOCK'
      }
      const response = await dashboardLockApi(formData)
      toastr.success(response.message)
    } catch (error) {
      console.log(error);
    }
  }

  const handleLogout = async () => {
    let data = {
      MerchantId: userDataa.id,
      SubAdminId:mData?.subResult?.id,
      Status: "INACTIVE",
      LastInactiveDateTime: new Date(),
      DateTime: '',
      DeviceId:deviceId,
      ...userInfo,
    }
    socket.emit("merchantInfo", JSON.stringify(data));
    localStorage.removeItem("paths");
    localStorage.removeItem("PublicKey");
    localStorage.removeItem("authUsers")
    window.location.reload();
  }


  // const history = useHistory();

  // useEffect(() => {
  //   const checkAuthExpiration = () => {
  //     const authUser = Cookies.get('authUser');
  //     if (!authUser) {
  //       history.push('/login');
  //       localStorage.removeItem("paths")
  //     }
  //   };
  //   const intervalId = setInterval(checkAuthExpiration, 1000);
  //   return () => clearInterval(intervalId);
  // }, [history]);

  return (
    <>
      <Dropdown
        isOpen={menu}
        toggle={toggle}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item"
          id="page-header-user-dropdown"
          tag="button"
        >
          <img
            className="rounded-circle header-profile-user"
            src={mData?.result?.ProfileImage ? `${API_BASE_URL}/${mData?.result?.id}/profile/${mData?.result?.ProfileImage}` : wheelLogo}
            alt="Header Avatar"
          />
          <span className="d-none d-xl-inline-block ms-1">
            {mData?.result?.BusinessName}
          </span>
          <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {subJasonParse === null || (subJasonParse?.MProfile?.view === true) ? (
            <DropdownItem tag="a" href="/user-profile">
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              Profile
            </DropdownItem>
          ) : null}
          {subJasonParse === null || (subJasonParse?.Profile?.view === true) ? (
            <DropdownItem tag="a" href="/sub-admin-profile">
              <i className="bx bx-user font-size-16 align-middle ms-1" />
              Profile
            </DropdownItem>
          ) : null}
          {/* <DropdownItem tag="a" href="auth-lock-screen" onClick={handleLock}>
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            Lock screen
          </DropdownItem> */}
          <div className="dropdown-divider" />
          <Link to="/logout" className="dropdown-item" onClick={handleLogout}>
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            Logout
          </Link>
        </DropdownMenu>
      </Dropdown>
    </>
  );
};

ProfileMenu.propTypes = {
  t: PropTypes.any,
  success: PropTypes.string
};

export default (ProfileMenu);